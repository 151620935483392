import React, {FunctionComponent, useEffect, useRef} from "react";
import lottie from "lottie-web";
import congratulationBadge from "../../lotties/CongratulationBadge.json";

export const CongratulationBadge: FunctionComponent = () => {

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(ref.current) {
            lottie.loadAnimation({
                animationData: congratulationBadge,
                container: ref.current,
                loop: false
            });
        }
    },[]);

    return <div ref={ref}>{}</div>
};