import React, { FunctionComponent} from "react";
import styles from "./RegistrationPageHeader.module.scss";
import {BreadcrumbGroup, BreadcrumbTabProps} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";
import {useIntl} from "react-intl";
import {useLocation, useNavigate} from "react-router-dom";
import classNames from "classnames";

export const RegistrationPageHeader: FunctionComponent = () => {

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation().pathname;

    const personalization = "/register-personalization"
    const register = "/register";

    const breadcrumbTabs: Array<BreadcrumbTabProps> = [
        {
            label: intl.formatMessage({ id: "Dashboard.name" }),
            onClick: () => navigate(ROUTES.DASHBOARD.BASE)
        },
        { label: intl.formatMessage({id: "RegistrationPageHeader.register"}) }
    ];

    return (
        <div className={styles.registrationPageHeader}>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs} />
            </div>
            <div className={styles.heading}>
                <h1>{intl.formatMessage({id: "RegistrationPageHeader.heading1"})}</h1>
                <h1>{intl.formatMessage({id: "RegistrationPageHeader.heading2"})}</h1>
            </div>
            <div className={classNames(
                location === personalization ? styles.personalizationPage : "",
                location === register ? styles.userDataPage : "",
                styles.title
            )}>
                <div className={styles.userData}><span>1</span><p>{intl.formatMessage({id: "RegistrationPageHeader.userData"})}</p></div>
                <div className={styles.personalization}><span>2</span><p>{intl.formatMessage({id: "RegistrationPageHeader.personalization"})}</p></div>
            </div>
        </div>
    );
};