import {FunctionComponent, useEffect, useState} from 'react';
import {UpcomingEvent, UpcomingEventProps} from '../../building-blocks/UpcomingEvent/UpcomingEvent';
import {PastEvent, PastEventProps} from '../../building-blocks/PastEvent/PastEvent';
import {loadJsonFile} from '../../utils/helper';
import styles from './EventsPage.module.scss';
import {useSelector} from 'react-redux';
import {selectLanguage} from '../../redux/languageSlice';
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {BreadcrumbGroup, BreadcrumbTabProps} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";

const EventsPage: FunctionComponent = () => {

    interface PageData {
        upcomingEventsTitle: string,
        pastEventsTitle: string,
        upcomingEvents: UpcomingEventProps[],
        pastEvents: PastEventProps[],
    }

    const selectedLanguage = useSelector(selectLanguage);
    const [pageContent, setPageContent] = useState<PageData>();

    const navigate = useNavigate();
    const intl = useIntl();

    useEffect(() => {
        async function loadData() {
            let data;
            switch (selectedLanguage) {
                case "DEU":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/events/page-content.json`);
                    break;
                case "ENG":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/events/page-content-eng.json`);
                    break;
                default:
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/events/page-content.json`);
                    break;
            }
            setPageContent(await data.json());
        }

        loadData().catch(console.error);
    }, [selectedLanguage]);

    const breadcrumbTabs:Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'Header.events'})}
    ];

    return (
        <div className={styles.EventsPage}>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
            </div>
            {pageContent && pageContent.upcomingEvents.length > 0 &&
            <div className={styles.upcomingEvents}>
                <div className={styles.headerUpcoming}>
                    <h1>{pageContent && pageContent?.upcomingEventsTitle}</h1>
                </div>
                <div className={styles.upcomingList}>
                    {
                        pageContent && pageContent.upcomingEvents && pageContent.upcomingEvents.length > 0 && pageContent.upcomingEvents.map((upcomingEvent) => {
                            return (
                                <div className={styles.upcomingEvent} key={upcomingEvent.title}>
                                    <UpcomingEvent image={upcomingEvent.image}
                                                   title={upcomingEvent.title}
                                                   description={upcomingEvent.description}
                                                   date={upcomingEvent.date}
                                                   place={upcomingEvent.place}
                                                   buttonLabel={upcomingEvent.buttonLabel}
                                                   link={upcomingEvent.link}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            }
            <div className={styles.pastEvents}>
                <div className={styles.headerPast}>
                    <h3>{pageContent && pageContent?.pastEventsTitle}</h3>
                </div>
                <div className={styles.pastList}>
                    {
                        pageContent && pageContent.pastEvents && pageContent.pastEvents.length > 0 && pageContent.pastEvents.map((pastEvent) => {
                            return (
                                <div className={styles.pastEvent} key={pastEvent.title}>
                                    <PastEvent title={pastEvent.title}
                                               description={pastEvent.description}
                                               date={pastEvent.date}
                                               place={pastEvent.place}
                                               image={pastEvent.image}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default EventsPage;
