import React, {FunctionComponent} from 'react';
import styles from './BlogEvent.module.scss';
import {Button, ButtonType, IconWithLabel} from "@dis/colors-components";
import {Event, Schedule, AssignmentInd} from '@mui/icons-material';

export interface BlogEventProps {
    tag: string;
    title: string;
    description: string;
    image: string;
    buttonLabel?: string;
    onButtonClick?: (event: any) => void;
    date: string;
    author?: string
    readTime: string;
}

export const BlogEvent: FunctionComponent<BlogEventProps> = ({
                                                                 tag,
                                                                 title,
                                                                 description,
                                                                 image,
                                                                 buttonLabel,
                                                                 onButtonClick,
                                                                 date,
                                                                 author,
                                                                 readTime,
                                                             }) => {

    return (
        <div className={styles.BlogEvent}>
            <div className={styles.blog}>{tag}</div>
            <div className={styles.sideBySide}>
                <div className={styles.content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}><p>{description}</p></div>
                    <div className={styles.details}>
                        { date &&
                          <IconWithLabel label={date}>
                            <Event fontSize={"small"}/>
                          </IconWithLabel>
                        }
                        { readTime &&
                          <IconWithLabel label={readTime}>
                            <Schedule fontSize={"small"}/>
                          </IconWithLabel>
                        }
                        { author &&
                          <IconWithLabel label={author}>
                            <AssignmentInd fontSize={"small"}/>
                          </IconWithLabel>
                        }
                    </div>
                    { buttonLabel && onButtonClick &&
                    <div className={styles.button}>
                        <Button label={buttonLabel} onClick={onButtonClick} type={ButtonType.PRIMARY}/>
                    </div>
                    }
                </div>
                <div className={styles.imageFrame}>
                    <img className={styles.image} src={image} alt={"blog entry"}/>
                </div>
            </div>
        </div>
    );
};
