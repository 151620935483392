import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export class DateTimeDeserializer {

    public static reviveObject(data: any) {
        for (let key in data) {
            let value = data[key];
            if (value === null) {
                continue;
            }
            switch (key) {
                case "createdAt":
                case "modifiedAt":
                    value = new Date(value as string);
                    break;
                /* //date is not used in YCG like it is for COLORS lessons. All dates in YCG are pure strings that are hardcoded in JSON files
                case 'date':
                     if (typeof value === 'string') {
                         value = dayjs.utc(value, 'DD-MM-YYYY').toDate();
                     }
                     break;
                 */
                default:
                    if (typeof value == "object") {
                        value = DateTimeDeserializer.reviveObject(value);
                    }
            }
            data[key] = value;
        }
        return data;
    }
}
