import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";

const sliceName = 'miaCustomizationUrl';

interface MiaCustomizationUrlState {
    pathname: string | undefined
}

/**
 * The initial state of the MiaCustomizationUrl state
 */
const initialState: MiaCustomizationUrlState = {
    'pathname': undefined
}

/**
 * The Redux slice handling actions on the MiaCustomizationUrl slice.
 */
const miaCustomizationUrlSlice = createSlice({
    'name': sliceName,
    'initialState': initialState,
    'reducers': {
        // set the MiaCustomizationUrl action
        miaCustomizationUrlSet(state: MiaCustomizationUrlState, action: PayloadAction<string|undefined>) {
            state.pathname = action.payload;
        }
    }
})

/**
 * To be used by the Redux storage to set up the MiaCustomizationUrl slice.
 */
export const miaCustomizationUrlReducer = miaCustomizationUrlSlice.reducer;

export const {
    miaCustomizationUrlSet
} = miaCustomizationUrlSlice.actions;

/**
 * Selector to get the pathname out of the miaCustomizationUrl state
 * @param state
 */
export const selectMiaCustomizationUrl = (state: RootState) => state.miaCustomizationUrl.pathname;
