import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./BlogView.module.scss";
import { loadJsonFile, MEDIA_MOBILE_WIDTH } from "../../../utils/helper";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { BlogProps, PageData } from "../Blog";
import { useDispatch, useSelector } from "react-redux";
import { fetchLessons } from "../../../redux/fetchActions";
import { selectLanguage } from "../../../redux/languageSlice";
import LessonLink from "../../../components/LessonLink/LessonLink";
import { useIntl } from "react-intl";
import { BreadcrumbGroup, BreadcrumbTabProps, IconWithLabel } from "@dis/colors-components";
import {Event, Schedule, AccountCircle} from '@mui/icons-material';
import { selectMediaQueries } from "../../../redux/mediaQueriesSlice";

interface ParagraphProps {
  header?: string;
  smallHeader?: string;
  description: string;
  image?: string;
  imageAlt: string;
  lesson: string;
}

interface BlogViewProps extends PageData {
  tag: string;
  user: string;
  paragraph: Array<ParagraphProps>;
  favoriteBlog: Array<BlogProps>;
}

interface StoryBookProps {
  storybook?: boolean;
}

const BLOG_ID_QUERY = "blog-id";

const BlogView: FunctionComponent<StoryBookProps> = (storybook) => {
  const search = new URLSearchParams(useLocation().search);
  const blogId = search.get(BLOG_ID_QUERY);
  const id = blogId ? blogId : "1";

  const intl = useIntl();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLessons());
  }, [dispatch]);

  const [blogViewContent, setBlogViewContent] = useState<BlogViewProps>();
  const selectedLanguage = useSelector(selectLanguage);
  const { mediaWidth } = useSelector(selectMediaQueries);

  useEffect(() => {
    async function loadData() {
      let data;
      switch (selectedLanguage) {
        case "DEU":
          data = await loadJsonFile(
            `${process.env.PUBLIC_URL}/content/blog/blog-content-${id}.json`
          );
          break;
        case "ENG":
          data = await loadJsonFile(
            `${process.env.PUBLIC_URL}/content/blog/blog-content-${id}-eng.json`
          );
          break;
        default:
          data = await loadJsonFile(
            `${process.env.PUBLIC_URL}/content/blog/blog-content-${id}.json`
          );
          break;
      }
      setBlogViewContent(await data.json());
    }

    loadData();
  }, [id, selectedLanguage]);

  // commented out since it is not used in version 1.0
  // const handleOnClickBlogView = (blogEntryId: string): void => {
  //     navigate(`${ROUTES.DASHBOARD.BLOG.BLOG_VIEW}?blog-id=${blogEntryId}`);
  //     window.scrollTo(0, 0);
  // }

  if (!search.has(BLOG_ID_QUERY)) {
    if (!storybook) {
      return <Navigate to={ROUTES.DASHBOARD.BASE} />;
    }
  }

  const breadcrumbTabs: Array<BreadcrumbTabProps> = [
    {
      label: intl.formatMessage({ id: "Dashboard.name" }),
      onClick: mediaWidth <= MEDIA_MOBILE_WIDTH ? undefined : () => navigate(ROUTES.DASHBOARD.BASE)
    },
    {
      label: intl.formatMessage({ id: "Header.blog" }),
      onClick: () => navigate(ROUTES.DASHBOARD.BLOG.BASE)
    },
    { label: blogViewContent ? blogViewContent.title : "" }
  ];

  const mobileBreadcrumbTabs = breadcrumbTabs.filter((breadcrumb) => breadcrumb.label !== intl.formatMessage({ id: "Dashboard.name" }));

  return (
    <div className={styles.BlogView}>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={mediaWidth <= MEDIA_MOBILE_WIDTH ? mobileBreadcrumbTabs : breadcrumbTabs} />
      </div>
      <div className={styles.BlogViewWrapper}>
        <div className={styles.blog}>{blogViewContent?.tag}</div>
        <div className={styles.sideBySide}>
          {blogViewContent && (
            <div className={styles.content}>
              <div className={styles.title}>{blogViewContent.title}</div>
              <div className={styles.details}>
                {blogViewContent.date &&
                  <IconWithLabel label={blogViewContent.date}>
                    <Event fontSize={"small"}/>
                  </IconWithLabel>
                }
                {blogViewContent.readTime &&
                  <IconWithLabel label={blogViewContent.readTime}>
                    <Schedule fontSize={"small"}/>
                  </IconWithLabel>
                }
                {blogViewContent.user &&
                  <IconWithLabel label={blogViewContent.user}>
                    <AccountCircle fontSize={"small"}/>
                  </IconWithLabel>
                }
              </div>
              <div className={styles.description}>
                {blogViewContent.description}
              </div>
            </div>
          )}
          {blogViewContent && (
            <div className={styles.image}>
              <img src={blogViewContent.image} alt={"blog entry"} />
            </div>
          )}
        </div>
        {blogViewContent &&
          blogViewContent.paragraph.map((item) => {
            return (
              <div
                className={styles.paragraphWrapper}
                key={item.lesson}
              >
                {item.header && (
                  <div className={styles.header}>{item.header}</div>
                )}
                {item.smallHeader && (
                  <div className={styles.smallHeader}>{item.smallHeader}</div>
                )}
                <div className={styles.paragraphDescription}>
                  <div dangerouslySetInnerHTML={{__html: item.description}} />
                  {item.lesson && <LessonLink name={item.lesson} />}
                </div>
                {item.image && (
                  <div className={styles.image}>
                    <img src={item.image} alt={item.imageAlt} />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {/*As per request, the carousel for recommended tiles shall be hidden for Release 1.0*/}

            {/*<div className={styles.favoriteBlogViewWrapper}>
                <div className={styles.heading}>{intl.formatMessage({id: "Blog.recommended"})}</div>
                {blogViewContent && blogViewContent.favoriteBlog &&
                    <Carousel>
                        <div className={styles.blogTile}>
                            {blogViewContent.favoriteBlog.map((value, index) => {
                                    return (
                                        <BlogEntry
                                            key={`favorite-blog_${index}`}
                                            title={value.title}
                                            description={value.description}
                                            date={value.date}
                                            readTime={value.readTime}
                                            image={value.image}
                                            onClick={() => {
                                                handleOnClickBlogView(value.id)
                                            }}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </Carousel>
                }
            </div>*/}
    </div>
  );
};

export default BlogView;
