import { postRequest, recommenderUrl } from "./util";

const getEndOfCourseRecommendations = async (courseId: string, userId: string, endDevice?: string) => {
  let requestUrl = `${recommenderUrl}/course/${courseId}/${userId}`;
  if (endDevice !== undefined) {
    requestUrl += `?enddevice=${endDevice}`;
  }
  const response = await postRequest(requestUrl);

  if (response.ok) {
    return response.json();
  } else {
    console.warn("Invalid response from end-of-course recommender");
  }
};

export const endOfCourseRecommenderApi = {
  getEndOfCourseRecommendations
};
