import React, {FunctionComponent} from 'react';
import styles from './LegalNoticePage.module.scss';
import {useSelector} from 'react-redux';
import {selectLanguage} from '../../redux/languageSlice';
import {LegalNoticePageDeu} from './lang/LegalNoticePageDeu';
import {LegalNoticePageEng} from './lang/LegalNoticePageEng';
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {BreadcrumbGroup, BreadcrumbTabProps} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";

const LegalNoticePage: FunctionComponent = () => {

    let selectedLanguage = useSelector(selectLanguage);
    let selectedLegalNoticePage;

    const intl = useIntl();
    const navigate = useNavigate();

    switch (selectedLanguage) {
        case "DEU": selectedLegalNoticePage = <LegalNoticePageDeu />; break;
        case "ENG": selectedLegalNoticePage = <LegalNoticePageEng />; break;
        default: selectedLegalNoticePage = <LegalNoticePageDeu />;
    }

    const breadcrumbTabs:Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'LegalPrivacyNoticeBar.legalNotice'})}
    ];

    return (
        <div className={styles.LegalNoticePage}>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
            </div>
            {selectedLegalNoticePage}
        </div>
    )
};

export default LegalNoticePage;
