import React, {FunctionComponent} from 'react';
import styles from './AgeGroupDisplay.module.scss';


export interface AgeGroups {
    [ageGroup: string]: boolean;
}

export interface AgeGroupDisplayProps {
    ageGroups: AgeGroups;
}


export const AgeGroupDisplay: FunctionComponent<AgeGroupDisplayProps> = ({ ageGroups}) => {

    return (
        <div className={styles.AgeGroup}>
            {   ageGroups &&
                Object.entries(ageGroups).map(([ageGroup, active]) => {
                    return (
                        <div className={active?styles.active:styles.inactive} key={`age-group_${ageGroup}`}>
                            <span>{ageGroup}</span>
                        </div>
                    );
                })
            }
        </div>
    );
};



