import React, { FunctionComponent } from "react";
import { ContentDescription } from "../ContentDescription/ContentDescription";
import {
    AgeGroups,
    ContentInformationBlock
} from "../ContentInformationBlock/ContentInformationBlock";
import styles from "./CourseIntro.module.scss";
import { Competence } from "@dis/types";

export interface CourseIntroProps {
    name: string;
    description: string;
    duration?: number;
    ageGroups: AgeGroups;
    difficulty: number;
    author: string;
    image: string;
    competenceIds?: string[];
    competences?: Competence[];
    location?: string;
    moment?: string;
    workshop?: boolean;
}

export const CourseIntro: FunctionComponent<CourseIntroProps> = ({
                                                                     name,
                                                                     description,
                                                                     duration,
                                                                     ageGroups,
                                                                     difficulty,
                                                                     author,
                                                                     image,
                                                                     competenceIds,
                                                                     competences,
                                                                     workshop,
                                                                     location,
                                                                     moment
                                                                 }) => {
    const convertToMinutes = (duration_ms: number): number => {
        return duration_ms / 60000;
    };

    return (
        <div className={styles.courseIntro}>
            <div className={styles.courseDescription}>
                <ContentDescription
                    name={name || ""}
                    description={description || ""}
                    competenceIds={competenceIds}
                    competences={competences}
                />
            </div>
          <div className={styles.info}>
            <div className={styles.courseInfoblock}>
              <ContentInformationBlock
                duration={convertToMinutes(duration || 0)}
                location={location}
                moment={moment}
                ageGroups={ageGroups}
                difficulty={difficulty || 0}
                author={author || ""}
                workshop={workshop}
              />
            </div>
            <div className={styles.courseImage}>
              <img src={image} alt={"lessonImage"} />
            </div>
          </div>
        </div>
    );
};
