import React, {FunctionComponent} from 'react';
import styles from './LegalPrivacyNoticeBar.module.scss'
import {MenuButton} from '@dis/colors-components';
import {useIntl} from 'react-intl';
import {ROUTES} from '../../utils/routes';
import {useLocation, useNavigate} from 'react-router-dom';
// import {LanguageSelector} from '../../components/LanguageSelector/LanguageSelector';

export const LegalPrivacyNoticeBar: FunctionComponent = () => {

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation().pathname;

    return (
        <div className={styles.LegalPrivacyNoticeBar}>
            <div className={styles.copyright}>
                <span
                    className={styles.copySymbol}>&copy;</span><span>{intl.formatMessage({id: 'LegalPrivacyNoticeBar.university'})}</span>
            </div>
            <div className={styles.privacyNotice}>
                <div className={styles.privacyNoticeLink}>
                    <MenuButton onClick={() => {
                                navigate(ROUTES.LEGAL_NOTICE_PRIVACY_BAR.LEGAL_NOTICE)
                            }}
                            label={intl.formatMessage({id: 'LegalPrivacyNoticeBar.legalNotice'})}
                            selected={location === ROUTES.LEGAL_NOTICE_PRIVACY_BAR.LEGAL_NOTICE}/>
                </div>
                <div className={styles.privacyNoticeLink}>
                    <MenuButton onClick={() => {
                                navigate(ROUTES.LEGAL_NOTICE_PRIVACY_BAR.PRIVACY_NOTICE)
                            }}
                            label={intl.formatMessage({id: 'LegalPrivacyNoticeBar.privacy'})}
                            selected={location === ROUTES.LEGAL_NOTICE_PRIVACY_BAR.PRIVACY_NOTICE}/>
                </div>
              <div className={styles.privacyNoticeLink}>
                <MenuButton onClick={() => {
                  navigate(ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT)
                }}
                            label={intl.formatMessage({id: 'LegalPrivacyNoticeBar.contact'})}
                            selected={location === ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT}/>
              </div>
{/*                 <div className={styles.privacyNoticeLink}>
                    <Button type={ButtonType.MENU}
                            onClick={() => {
                                navigate(ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT)
                            }}
                            label={intl.formatMessage({id: 'LegalPrivacyNoticeBar.contact'})}
                            selected={location === ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT}/>
                </div>*/}
            </div>
            <div className={styles.rightSpace}>
                {/*<LanguageSelector />*/}
            </div>
        </div>
    )
}
