import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import {
  BreadcrumbGroup,
  BreadcrumbTabProps,
  Button,
  ButtonType,
  TextArea,
  TextInput
} from "@dis/colors-components";
import styles from "./OrderPage.module.scss";
import { contactMailUrl, postRequest, emailValidation } from "../../api/util";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import {ConsentCheck} from "../../components/ConsentCheck/ConsentCheck";
import {HoneyPot} from "../../components/HoneyPot/HoneyPot";

const OrderPage: FunctionComponent = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [nameOrCompany1, setNameOrCompany1] = useState("");
  const [nameOrCompany2, setNameOrCompany2] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");

  const [nameOrCompany1Error, setNameOrCompany1Error] = useState(false);
  const [nameOrCompany2Error, setNameOrCompany2Error] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [address2Error, setAddress2Error] = useState(false);
  const [plzError, setPlzError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [nameOrCompany1EmptyError, setNameOrCompany1EmptyError] = useState(false);
  const [address1EmptyError, setAddress1EmptyError] = useState(false);
  const [plzEmptyError, setPlzEmptyError] = useState(false);
  const [cityEmptyError, setCityEmptyError] = useState(false);
  const [emailEmptyError, setEmailEmptyError] = useState(false);
  const [amountEmptyError, setAmountEmptyError] = useState(false);

  const [plzFormatError, setPlzFormatError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [amountFormatError, setAmountFormatError] = useState(false);

  const [orderConsentChecked, setOrderConsentChecked] = useState(false);
  const [orderHoneypotEmpty, setOrderHoneypotEmpty] = useState(true);

  const onSend = async () => {
    if (handleValidation()) {
      try {
        let postResponse = await postRequest(contactMailUrl, {
          body: JSON.stringify({
            form: "handout",
            nameOrCompany1: nameOrCompany1,
            nameOrCompany2: nameOrCompany2,
            address1: address1,
            address2: address2,
            plz: plz,
            city: city,
            email: email,
            amount: amount,
            message: message
          })
        });
        if (postResponse.ok) {
          navigate(ROUTES.HANDOUT.ORDER_SENT);
        } else {
          console.log("Error while sending contact mail: ", postResponse);
        }
      } catch (error) {
        console.error("Error while sending contact mail.", error);
      }
    }
  };

  const lettersSymbolsValidation = (value: string) => value.match(/^[\u0021-\u007E\s\u00C0-\u017F]+$/);

  const positiveNumberValidation = (value: string) => value.match(/^[1-9]\d*$/);

  const plzValidation = (value: string) => value.match(/^\d{5}$/)

  const numberValidation = (value: string) => value.match(/^\d+$/)

  /* checks before POST */
  const handleValidation = () => {
    let formIsValid = true;
    setNameOrCompany1Error(false);
    setNameOrCompany2Error(false);
    setAddress1Error(false);
    setAddress2Error(false);
    setPlzError(false);
    setCityError(false);
    setEmailError(false);
    setAmountError(false);
    setMessageError(false);

    if (!nameOrCompany1 || nameOrCompany1 === "") {
      formIsValid = false;
      setNameOrCompany1EmptyError(true);
    }

    if (!city || city === "") {
      formIsValid = false;
      setCityEmptyError(true);
    }

    if (!plz || plz === "") {
      formIsValid = false;
      setPlzEmptyError(true);
    } else if (typeof plz !== "undefined") {
      if (!plzValidation(plz)) {
        formIsValid = false;
        setPlzFormatError(true);
      }
    }

    if (!address1 || address1 === "") {
      formIsValid = false;
      setAddress1EmptyError(true);
    }

    if (!email || email === "") {
      formIsValid = false;
      setEmailEmptyError(true);
    } else if (typeof email !== "undefined") {
      if (!emailValidation(email)) {
        formIsValid = false;
        setEmailFormatError(true);
      }
    }

    if(!amount || amount === "") {
      formIsValid = false;
      setAmountEmptyError(true);
    } else if (typeof amount !== "undefined") {
      if (!positiveNumberValidation(amount)) {
        formIsValid = false;
        setAmountFormatError(true);
      }
    }

    return formIsValid;
  };

  const onOrderCheckboxClicked = () => {
    setOrderConsentChecked(!orderConsentChecked);
  };

  const handleOrderHoneypot = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setOrderHoneypotEmpty(false);
    }
  };

  const saveValue = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, input: string) => {
    e.preventDefault();
    const value = e.target.value;
    /* immediate checks! */
    switch (input) {
      case "nameOrCompany1":
        setNameOrCompany1Error(!lettersSymbolsValidation(value));
        setNameOrCompany1EmptyError(!value);
        setNameOrCompany1(value);
        break;
      case "nameOrCompany2":
        setNameOrCompany2Error(false);
        if (value !== "") {setNameOrCompany2Error(!lettersSymbolsValidation(value))}
        setNameOrCompany2(value);
        break;
      case "address1":
        setAddress1Error(!lettersSymbolsValidation(value));
        setAddress1EmptyError(!value);
        setAddress1(value);
        break;
      case "address2":
        setAddress2Error(false);
        if (value !== "") {setAddress2Error(!lettersSymbolsValidation(value))}
        setAddress2(value);
        break;
      case "plz":
        setPlzFormatError(false);
        setPlzError(!numberValidation(value));
        setPlzEmptyError(!value);
        setPlz(value);
        break;
      case "city":
        setCityError(!lettersSymbolsValidation(value));
        setCityEmptyError(!value);
        setCity(value);
        break;
      case "email":
        setEmailFormatError(false);
        setEmailEmptyError(!value);
        setEmail(value);
        break;
      case "amount":
        setAmountFormatError(false);
        setAmountError(!numberValidation(value));
        setAmountEmptyError(!value);
        setAmount(value);
        break;
      case "message":
        setMessageError(false);
        if (value !== "") {setMessageError(!lettersSymbolsValidation(value))}
        setMessage(value);
        break;
    }
  };

  const errorMessage = (error: boolean, messageId: string, empty?: boolean, formatError?: boolean, formatErrorId?: string ) => {
    if (empty) {
      return intl.formatMessage({ id: "InputField.empty" });
    }
    if (error) {
      return intl.formatMessage({ id: messageId });
    }
    if (formatError) {
      return intl.formatMessage({ id: formatErrorId });
    }
    else {
      return ""
    }
  };

  const breadcrumbTabs:Array<BreadcrumbTabProps> = [
    {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
    {label: intl.formatMessage({id: 'Header.handout'}), onClick: () => navigate(ROUTES.DASHBOARD.HANDOUT)},
    {label: intl.formatMessage({id: 'Header.order'})}
  ];

  return (
    <>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
      </div>
      <div className={styles.OrderPage}>
        <div className={styles.form}>
          <h2 className={styles.header}>{intl.formatMessage({ id: "OrderPage.heading"})}</h2>
          <p className={styles.introText}>{intl.formatMessage({ id: "OrderPage.introText"})}</p>
          <div className={styles.inputFields}>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={nameOrCompany1}
                           label={intl.formatMessage({id: "OrderPage.nameOrCompany1"})}
                           onChange={e => saveValue(e, "nameOrCompany1")}
                           requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                           errorMessage={errorMessage(nameOrCompany1Error, "Input.invalidCharacters", nameOrCompany1EmptyError)}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={nameOrCompany2}
                           label={intl.formatMessage({id: "OrderPage.nameOrCompany2"})}
                           onChange={e => saveValue(e, "nameOrCompany2")}
                           errorMessage={errorMessage(nameOrCompany2Error, "Input.invalidCharacters")}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={address1}
                           label={intl.formatMessage({id: "OrderPage.address1"})}
                           onChange={e => saveValue(e, "address1")}
                           requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                           errorMessage={errorMessage(address1Error, "Input.invalidCharacters", address1EmptyError)}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={address2}
                           label={intl.formatMessage({id: "OrderPage.address2"})}
                           onChange={e => saveValue(e, "address2")}
                           errorMessage={errorMessage(address2Error, "Input.invalidCharacters")}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={plz}
                           maxCharacterLimit={5}
                           label={intl.formatMessage({id: "OrderPage.plz"})}
                           onChange={e => saveValue(e, "plz")}
                           requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                           errorMessage={errorMessage(plzError, "Input.invalidCharacters", plzEmptyError, plzFormatError, "OrderPage.plzFormatError")}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={city}
                           label={intl.formatMessage({id: "OrderPage.city"})}
                           onChange={e => saveValue(e, "city")}
                           requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                           errorMessage={errorMessage(cityError, "Input.invalidCharacters", cityEmptyError)}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={email}
                           label={intl.formatMessage({id: "OrderPage.email"})}
                           onChange={e => saveValue(e, "email")}
                           requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                           errorMessage={errorMessage(emailError, "Input.invalidCharacters", emailEmptyError, emailFormatError, "OrderPage.emailFormatError")}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={amount}
                           label={intl.formatMessage({id: "OrderPage.amount"})}
                           onChange={e => saveValue(e, "amount")}
                           requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                           errorMessage={errorMessage(amountError, "Input.invalidCharacters", amountEmptyError, amountFormatError, "OrderPage.amountFormatError")}
                />
              </div>
            </div>
            <div className={styles.textArea}>
              <div className={styles.withError}>
                <TextArea label={intl.formatMessage({ id: "OrderPage.message" })}
                          onChange={e => saveValue(e, "message")}
                          maxCharacterLimit={1000000} showCount={false}
                          errorMessage={errorMessage(messageError, "Input.invalidCharacters")}

                />
              </div>
            </div>
            <ConsentCheck consentChecked={orderConsentChecked} handleChange={onOrderCheckboxClicked}/>
            <HoneyPot handleChange={(e) => handleOrderHoneypot(e)}/>
          </div>

          <div className={styles.buttonContainer}>
            <div className={styles.button}><Button label={intl.formatMessage({ id: "OrderPage.button" })}
                                                   onClick={onSend} type={ButtonType.PRIMARY}
                                                   disabled={!orderConsentChecked && orderHoneypotEmpty}
            /></div>
          </div>

        </div>
      </div>
    </>
  )

}

export default OrderPage;
