import React, {FunctionComponent} from 'react';
import styles from './LessonLink.module.scss';
import {ROUTES} from '../../utils/routes';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectLessonByName} from '../../redux/lessonsSlice';


export interface LessonLinkProps {
    name: string;
}

const LessonLink: FunctionComponent<LessonLinkProps> = ({name}) => {
    const navigate = useNavigate();
    const lessonId = useSelector(selectLessonByName(name))[0].id;

    return (
        <div className={styles.LessonLink}
             onClick={() => navigate(`${ROUTES.CONTENT_VIEWER.BASE}?content-id=${lessonId}`)}>
            „{name}“.
        </div>
    );
}

export default LessonLink;