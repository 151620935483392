import React, {FunctionComponent, useState} from 'react';
import styles from './ContentTile.module.scss';
import {Ratings} from "./Ratings/Ratings";
import {AgeGroupDisplay, AgeGroups} from "../AgeGroupDisplay/AgeGroupDisplay";
import {DifficultyDisplay} from "../DifficultyDisplay/DifficultyDisplay";
import {useIntl} from "react-intl";
import { CongratulationBadge } from "../CongratulationBadge/CongratulationBadge";
import classNames from "classnames";
import {IconWithLabel} from '@dis/colors-components';
import {Event, Place, Copyright} from '@mui/icons-material';
import { useSelector } from "react-redux";
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";
import {MEDIA_MOBILE_WIDTH} from "../../utils/helper";

export interface ContentTileProps {
    onClick?: (event: React.MouseEvent) => void;
    disabled?: boolean;
    image: string;
    title: string;
    description?: string;
    workshop?: boolean;
    rating?: number;
    reviews?: number;
    ageGroup?: AgeGroups;
    knowledge?: number;
    moment?: string;
    place?: string;
    href?:string;
    author?:string;
    finishedCourse?:boolean;
    selected?:boolean;
}

const CONTENT_TILE_OFFSET_WIDTH = 30;

export const ContentTile: FunctionComponent<ContentTileProps> = ({
                                                                        onClick,
                                                                        image,
                                                                        title,
                                                                        description,
                                                                        workshop,
                                                                        rating,
                                                                        reviews,
                                                                        ageGroup,
                                                                        knowledge,
                                                                        moment,
                                                                        place,
                                                                        href,
                                                                        author,
                                                                        finishedCourse,
                                                                        selected=false
                                                                    }) => {

    const intl = useIntl();

    const {mediaWidth} = useSelector(selectMediaQueries);

    let [reference, setReference] = useState<string | undefined>(undefined);

    const onMouseDown = (event:React.MouseEvent) => {
        if(event.button === 0) {
            if(event.ctrlKey) setReference(href);
            else setReference(undefined);
        } else {
            setReference(href);
        }
    };

    const style = {
      width: mediaWidth <= MEDIA_MOBILE_WIDTH ? `${mediaWidth - CONTENT_TILE_OFFSET_WIDTH}px` : ''
    }

       return (
        <a href={reference} style={style} className={classNames(styles.ContentTile, selected ? styles.selected : styles.unSelected)} onMouseDown={onMouseDown} onClick={onClick}>
            <div className={styles.top}>
              {
                finishedCourse &&
                <div className={styles.finishedBadge}>
                  <CongratulationBadge/>
                </div>
              }
                <div className={styles.imageFrame}>
                    <img className={styles.image} src={image} alt={"Kurskachel"}/>
                </div>
                { rating &&
                <div className={styles.ratings}>
                    <Ratings rating={rating} reviews={reviews}/>
                </div>
                }
                { workshop &&
                    <div className={styles.workshopBanner}>
                        Workshop
                    </div>
                }
                <div className={styles.banner}>
                    <div className={styles.title}>
                        {title}
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.targetAudience}>
                    <div className={styles.ageGroup}>
                        <div className={styles.header}>
                            {intl.formatMessage({id: "Course.Age"})}
                        </div>
                        { ageGroup &&
                            <AgeGroupDisplay ageGroups={ageGroup} />
                        }
                    </div>
                    { knowledge &&
                        <div className={styles.difficulty}>
                            <div className={styles.header}>
                                {intl.formatMessage({id: "Course.PriorKnowledge"})}
                            </div>
                            <DifficultyDisplay difficulty={knowledge} size={'12px'}/>
                        </div>
                    }
                </div>
                {workshop ?
                    <div className={styles.externalWorkshop}>
                        { moment &&
                          <IconWithLabel label={moment}>
                            <Event fontSize={"small"}/>
                          </IconWithLabel>
                        }
                        { place &&
                          <IconWithLabel label={place}>
                            <Place fontSize={"small"}/>
                          </IconWithLabel>
                        }
                        { author &&
                          <IconWithLabel label={author}>
                            <Copyright fontSize={"small"}/>
                          </IconWithLabel>
                        }
                    </div>
                    : <div className={styles.description}>
                        <p>{description}</p>
                    </div>
                }
            </div>
        </a>
    );
};
