import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

import {RootState} from "./store";
import {fetchLessons} from "./fetchActions";
import {ShallowEntity} from "../utils/types/api";
import {Subject} from "@dis/types";
import { selectLessonByThemes } from "./lessonsSlice";


const sliceName = 'subjects';

/**
 * The Entity Adapter managing {@link Subject} entities.
 *
 * Entities are sorted by their `name`.
 */
// TODO sort by order
export const subjectsAdapter = createEntityAdapter<ShallowEntity<Subject>>({
    'sortComparer': (a, b) => (a.name ?? '')?.localeCompare(b.name ?? '')
});

/**
 * The Redux slice handling actions on {@link Subject} entities.
 */
const subjectSlice = createSlice({
    'name': sliceName,
    'initialState': subjectsAdapter.getInitialState(),
    'reducers': {},
    'extraReducers': (builder) => builder
        // Add all Phase entities fetched by fetchLessons if the request succeeded.
        .addCase(fetchLessons.fulfilled, (state, action) => {
            if (action.payload.entities.Subject) {
                subjectsAdapter.upsertMany(state, action.payload.entities.Subject);
            }
        })
});

/**
 * To be used by the Redux storage to set up the {@link Subject} slice.
 */
export const subjectsReducer = subjectSlice.reducer;

/*
 * A selector dictionary giving access to
 *
 * - `selectAllPhases`: all stored Phase entities
 * - `selectPhase`: the Phase with the given id
 */
export const {
    'selectAll': selectAllSubjects,
    'selectById': selectSubject
} = subjectsAdapter.getSelectors<RootState>((state) => state.subjects);


export const selectAllSubjectsMediaSpecific = () => (state: RootState) => {
    const subjects = subjectsAdapter.getSelectors().selectAll(state.subjects);
    return subjects.filter(subject => {
        if (subject.name) {
            const lessons = selectLessonByThemes(subject.name)(state);
            return lessons.length > 0;
        }
        return true;
    })
};
