import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

import {RootState} from "./store";
import {fetchLessons} from "./fetchActions";
import {ShallowEntity} from "../utils/types/api";
import {Phase} from "@dis/types";


const sliceName = 'phases';

/**
 * The Entity Adapter managing {@link Phase} entities.
 *
 * Entities are sorted by their `name`.
 */
// TODO sort by order
export const phasesAdapter = createEntityAdapter<ShallowEntity<Phase>>({
  'sortComparer': (a, b) => (a.name ?? '')?.localeCompare(b.name ?? '')
});

/**
 * The Redux slice handling actions on {@link Lesson} entities.
 */
const phasesSlice = createSlice({
  'name': sliceName,
  'initialState': phasesAdapter.getInitialState(),
  'reducers': {},
  'extraReducers': (builder) => builder
    // Add all Phase entities fetched by fetchLessons if the request succeeded.
    .addCase(fetchLessons.fulfilled, (state, action) => {
      if (action.payload.entities.Phase) {
        phasesAdapter.upsertMany(state, action.payload.entities.Phase);
      }
    })
});

/**
 * To be used by the Redux storage to set up the {@link Lesson} slice.
 */
export const phasesReducer = phasesSlice.reducer;

/*
 * A selector dictionary giving access to
 *
 * - `selectAllPhases`: all stored Phase entities
 * - `selectPhase`: the Phase with the given id
 */
export const {
  'selectAll': selectAllPhases,
  'selectById': selectPhase
} = phasesAdapter.getSelectors<RootState>((state) => state.phases);
