import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";


const sliceName = 'codingChallengeImage';

interface CodingChallengeImageState {
    image: string | undefined
}

/**
 * The initial state of the codingChallengeImages state
 */
const initialState: CodingChallengeImageState = {
    'image': undefined
}

/**
 * The Redux slice handling actions on the codingChallengeImages slice.
 */
const codingChallengeImagesSlice = createSlice({
    'name': sliceName,
    'initialState': initialState,
    'reducers': {
        // set the codingChallengeImages action
        codingChallengeImageSet(state: CodingChallengeImageState, action: PayloadAction<string|undefined>) {
            state.image = action.payload;
        }
    }
})

/**
 * To be used by the Redux storage to set up the codingChallengeImages slice.
 */
export const codingChallengeImageReducer = codingChallengeImagesSlice.reducer;

export const {
    codingChallengeImageSet
} = codingChallengeImagesSlice.actions;

/**
 * Selector to get the images out of the codingChallengeImages state
 * @param state
 */
export const selectCodingChallengeImage = (state: RootState) => state.codingChallengeImage.image;
