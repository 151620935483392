import React, {FunctionComponent} from 'react';
import styles from './Footer.module.scss';
import FobidImage from '../../assets/images/footer/fobid.png';
import DidacticInnovationsImage from '../../assets/images/footer/didactic-innovations.png';
import DfkiImage from '../../assets/images/footer/dfki.png';
import BmfsfjImage from '../../assets/images/footer/bmfsfj.png';
import BmdvImage from '../../assets/images/footer/bmdv.png';
import FacebookIcon from '../../assets/icons/facebook.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import {Icon, IconType} from '@dis/ycg-components';
import {useIntl} from 'react-intl';

export const Footer: FunctionComponent = () => {

    const intl = useIntl();

    return (
        <div className={styles.Footer}>
            <div className={styles.sponsoredBy}>
                <p>{intl.formatMessage({id: "Footer.sponsoredBy"})}</p>
            </div>
            <div className={styles.companies}>

                <a href={'https://bmdv.bund.de/'} target="_blank" rel="noreferrer">
                    <div className={styles.Bundesministerium}>
                        <img src={BmdvImage}
                             alt={'Bundesministerium für Digitales & Verkehr'}/>
                    </div>
                </a>
                <a href={'https://www.bmfsfj.de/'} target="_blank" rel="noreferrer">
                    <div className={styles.Bundesministerium}>
                        <img src={BmfsfjImage}
                             alt={'Bundesministerium für Familie, Senioren, Frauen und Jugend'}/>
                    </div>
                </a>
                <a href={'https://didactic-innovations.de/'} target="_blank" rel="noreferrer">
                    <div className={styles.DidacticInnovations}>
                        <img src={DidacticInnovationsImage} alt={'Didactic Innovations'}/>
                    </div>
                </a>
                <a href={'https://fobid.org/'} target="_blank" rel="noreferrer">
                    <div className={styles.FoBiD}>
                        <img src={FobidImage} alt={'FoBiD'}/>
                    </div>
                </a>
                <a href={'https://www.dfki.de/'} target="_blank" rel="noreferrer">
                    <div className={styles.DFKI}>
                        <img src={DfkiImage} alt={'DFKI'}/>
                    </div>
                </a>
            </div>
            <div className={styles.links}>
                <div className={styles.link}>
                    <a href={'https://www.facebook.com/youcodegirls'} target="_blank" rel="noreferrer">
                        <div className={styles.row}>
                            <img src={FacebookIcon} alt={'facebook'}/>
                            <p>@youcodegirls</p>
                        </div>
                    </a>
                </div>
                <div className={styles.link}>
                    <a href={'https://www.instagram.com/youcodegirls'} target="_blank" rel="noreferrer">
                        <div className={styles.row}>
                            <img src={InstagramIcon} alt={'instagram'}/>
                            <p>@youcodegirls</p>
                        </div>
                    </a>
                </div>
                <div className={styles.link}>
                    <a href={'https://twitter.com/youcodegirls'} target="_blank" rel="noreferrer">
                        <div className={styles.row}>
                            <img src={TwitterIcon} alt={'twitter'}/>
                            <p>@youcodegirls</p>
                        </div>
                    </a>
                </div>
                <div className={styles.link}>
                    <a href={'mailto:mail@youcodegirls.de'}>
                        <div className={styles.row}>
                            <Icon name={'mail_outline'} size={'20px'} type={IconType.TERTIARY} opacity={0.7}/>
                            <p>mail@youcodegirls.de</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

