import Keycloak from "keycloak-js";

export const keycloak = Keycloak(`${process.env.PUBLIC_URL}/config/keycloak.json`);

export const initOptions = {
    /*
    'login-required': authenticates client if user is logged-in to Keycloak, displays login page otherwise.
    'check-sso': will redirect to the app in the latter case and remain unauthenticated
     */
    onLoad: 'check-sso'
};
