import { Store } from "redux";
import {Operation} from 'fast-json-patch';


export const rootUrl = '../';

export const formatServiceUrl = rootUrl + 'format-service';

export const contentCreationServiceUrl = rootUrl + 'content-creation-service';

export const miaServiceUrl = rootUrl + 'chat-with-mia';

export const userDataService = 'user-data-service';
export const userDataURL = rootUrl + userDataService;

export const hitCounterURL = userDataURL + '/hit-counter';

export const contactMailUrl = rootUrl + 'contactmail/contactmail';

export const recommenderUrl = rootUrl + 'ycg-recommender';

let store: Store;

/**
 * Inject the storage to avoid circular dependencies.
 * See https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 * @param _store the storage to inject
 */
export const injectStore = (_store: Store) => {
    store = _store;
}

/**
 * Get the headers for backend requests including token.
 */
export const standardHeaders = (accept="application/json", contentType="application/json"): HeadersInit => {
    let bearerToken: string = store?.getState().auth.tokens?.token;
    if (bearerToken) {
        return {
            Accept: accept,
            "Content-Type": contentType,
            Authorization: `Bearer ${bearerToken}`,
        };
    } else {
        return {
            Accept: accept,
            "Content-Type": contentType
        };
    }
}

const constructUrl = (url: string) => `${contentCreationServiceUrl}${url}`;
export const defaultFetch = (url: string, init: RequestInit) => {
    const urlWithBase = constructUrl(url);

    return fetch(urlWithBase, {
        mode: "cors",
        headers: standardHeaders(),
        ...init
    });
};

export const getRequest = (url: string, init?: RequestInit) => {
    return fetch(url, {
        method: "GET",
        headers: standardHeaders(),
        mode: "cors",
        ...init
    });
};

export const postRequest = (url: string, init?: RequestInit) => {
    return fetch(url, {
        method: "POST",
        headers: standardHeaders(),
        mode: "cors",
        ...init
    });
};

export const patchRequest = async (url: string, patchOperations: Operation[]) => {
    const body = JSON.stringify(patchOperations);
    return fetch(url, {
        method: "PATCH",
        headers: standardHeaders('application/json', 'application/json-patch+json'),
        body: body
    });
}


export const deleteRequest = (url: string, init?: RequestInit) => {
    return fetch(url, {
        method: "DELETE",
        headers: standardHeaders(),
        mode: "cors",
        ...init
    });
};

export const emailValidation = (value: string) => {
    return value.match(/^\S+@\S+\.\S+$/);
};
