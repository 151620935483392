import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
    selectFormatConfigurationFromLesson,
    selectLearningElementsFromLesson,
    selectLesson,
    selectTutorFromLesson
} from "../../redux/lessonsSlice";
import { CourseIntro } from "./CourseIntro";
import { getLessonImage } from "../../utils/helper";

export interface CourseIntroWrapperProps {
    lessonId: string;
    workshop?: boolean;
}

export const CourseIntroWrapper: FunctionComponent<CourseIntroWrapperProps> = ({
                                                                                   lessonId,
                                                                                   workshop
                                                                               }) => {
    const lesson = useSelector((state: RootState) =>
        selectLesson(state, lessonId)
    );
    const formatConfiguration = useSelector(
        selectFormatConfigurationFromLesson(lessonId)
    );
    const learningElements = useSelector(
        selectLearningElementsFromLesson(lessonId)
    );
    const tutor = useSelector(selectTutorFromLesson(lessonId));

    return (
        <CourseIntro
            ageGroups={{
                "6–9":
                    (formatConfiguration?.configuration &&
                        formatConfiguration?.configuration.age_group_1) ||
                    false,
                "10–12":
                    (formatConfiguration?.configuration &&
                        formatConfiguration?.configuration.age_group_2) ||
                    false,
                "13–15":
                    (formatConfiguration?.configuration &&
                        formatConfiguration?.configuration.age_group_3) ||
                    false,
                "16+":
                    (formatConfiguration?.configuration &&
                        formatConfiguration?.configuration.age_group_4) ||
                    false
            }}
            author={workshop ? formatConfiguration?.configuration.offered_by || "" : tutor?.username || ""}
            description={lesson?.description || ""}
            difficulty={
                (formatConfiguration?.configuration &&
                    formatConfiguration?.configuration.previous_knowledge) ||
                0
            }
            duration={learningElements?.duration || 0}
            image={getLessonImage(lesson)}
            name={lesson?.name || ""}
            competenceIds={learningElements?.competences || []}
            workshop={workshop}
            location={formatConfiguration?.configuration.location}
            moment={formatConfiguration?.configuration.date}
        />
    );
};
