import React, {FunctionComponent, useEffect} from 'react';
import styles from '../PrivacyNoticePage.module.scss';
import {domainGroupId} from '../../../App';

export const PrivacyNoticePageDeu: FunctionComponent = () => {

    useEffect(() => {
        const script = document.createElement('script');
        const src = 'https://consent.cookiebot.com/' + domainGroupId + '/cd.js';

        script.setAttribute('id', 'CookieDeclaration');
        script.setAttribute('src', src);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('data-culture', 'DE');
        script.async = true;
        document.getElementById('CookieDeclarationSection')?.appendChild(script);
    }, []);

    return (
        <>
            <h1>Datenschutzerklärung</h1>
            <div className={styles.padding}>
                <p>
                    Die Universität des Saarlandes sieht den Schutz personenbezogener Daten als essentiellen Bestandteil
                    der
                    eigenen Digitalisierungsstrategie an.
                    Der Schutz des informationellen Selbstbestimmungsrechts der Betroffenen ist daher ein hohes Anliegen
                    beim Umgang mit personenbezogenen Daten.
                    Diese Datenschutzerklärung bezieht sich auf die Webseiten, welche von der Universität des Saarlandes
                    betrieben werden.
                    Sollten auf Webseiten der Universität des Saarlandes über die im folgenden dargestellten Verfahren
                    weitere Verarbeitungen erfolgen, wird auf den entsprechenden Webseiten darüber aufgeklärt.
                </p>

                <section>
                    <h2>Name und Anschrift des Verantwortlichen</h2>
                    <p>
                        Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler
                        Datenschutzgesetze
                        der
                        Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:
                    </p>
                    <p>
                        Universität des Saarlandes<br/>
                        vertreten durch den Universitätspräsidenten<br/>
                        Campus<br/>
                        66123 Saarbrücken<br/>
                        Tel.: 0681 302-0<br/>
                        <a href={'mailto:postzentrale@uni-saarland.de'}>postzentrale(at)uni-saarland.de</a>
                    </p>
                </section>

                <section>
                    <h2>Kontaktdaten des Datenschutzbeauftragten</h2>
                    <p>
                        Meerwiesertalweg 15<br/>
                        66123 Saarbrücken<br/>
                        Tel.: 0681 302-2813<br/>
                        <a href={'mailto:datenschutz@uni-saarland.de'}>datenschutz(at)uni-saarland.de</a><br/>
                        <a
                            href={'https://www.uni-saarland.de/verwaltung/datenschutz.html'}>www.uni-saarland.de/verwaltung/datenschutz</a>
                    </p>
                </section>

                <section>
                    <h2>Allgemeines zur Datenverarbeitung</h2>
                    <section>
                        <h4>Zwecke und Umfang der Verarbeitung personenbezogener Daten</h4>
                        <p>Die Universität des Saarlandes verarbeitet personenbezogene Daten der Nutzer der Webseiten
                            grundsätzlich
                            nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie der Inhalte der
                            Webseiten
                            und
                            zum Anbieten von Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten der
                            Nutzer
                            erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen,
                            in
                            denen
                            eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und
                            die
                            Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.</p>
                    </section>
                    <section>
                        <h4>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
                        <p>Soweit die Universität des Saarlandes für Verarbeitungsvorgänge personenbezogener Daten eine
                            Einwilligung
                            der betroffenen Person einholt, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung
                            (DSGVO)
                            als
                            Rechtsgrundlage.</p>
                        <p>Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
                            Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO
                            als
                            Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
                            vorvertraglicher
                            Maßnahmen erforderlich sind.</p>
                        <p>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
                            Verpflichtung
                            erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
                            Rechtsgrundlage.</p>
                        <p>Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
                            natürlichen Person
                            eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d
                            DSGVO
                            als
                            Rechtsgrundlage.</p>
                        <p>Soweit die Universität des Saarlandes zur Erfüllung der ihr gesetzliche übertragenen Aufgaben
                            in
                            Forschung und Lehre tätig wird ergibt sich die Rechtsgrundlage der Verarbeitung aus § 3 Abs.
                            13
                            Saarländisches Hochschulgesetz.</p>
                        <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses der Universität des Saarlandes
                            oder eines
                            Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des
                            Betroffenen das
                            erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für
                            die
                            Verarbeitung.</p>
                    </section>
                    <section>
                        <h4>Datenlöschung und Speicherdauer</h4>
                        <p>Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der
                            Zweck
                            der
                            Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den
                            europäischen
                            oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
                            Vorschriften,
                            denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der
                            Daten
                            erfolgt
                            auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es
                            sei
                            denn,
                            dass
                            eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder
                            eine
                            Vertragserfüllung besteht.</p>
                    </section>
                </section>

                <section>
                    <h2>Bereitstellung der Website und Erstellung von Logfiles</h2>

                    <section>
                        <h4>Beschreibung und Umfang der Datenverarbeitung</h4>
                        <p>Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
                            Informationen
                            vom
                            Computersystem des aufrufenden Rechners, um die Webseite zur Verfügung stellen zu können.
                            Folgende
                            Daten
                            werden hierbei erhoben:</p>

                        <ul>
                            <li>
                                die IP-Adresse des Nutzers
                            </li>
                            <li>
                                Datum und Uhrzeit des Zugriffs
                            </li>
                            <li>
                                Websites, von denen das System des Nutzers auf unsere Internetseite gelangt
                            </li>
                            <li>
                                Größe der übertragenen Daten
                            </li>
                            <li>
                                http-Error-Code (Fehlerprotokoll)
                            </li>
                        </ul>
                        <p>Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Nicht hiervon
                            betroffen
                            sind
                            die
                            IP-Adressen des Nutzers oder andere Daten, die die Zuordnung der Daten zu einem Nutzer
                            ermöglichen.
                            Die
                            IP-Adressen werden um die letzten beiden Oktets gekürzt (Bsp.: 192.168.xxx.xxx). Eine
                            Speicherung
                            dieser
                            Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.</p>
                    </section>

                    <section>
                        <h4>Rechtsgrundlage für die Datenverarbeitung</h4>
                        <p>Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1 lit. f
                            DSGVO.</p>
                    </section>

                    <section>
                        <h4>Zweck der Datenverarbeitung</h4>
                        <p>Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine
                            Auslieferung
                            der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des
                            Nutzers
                            für
                            die Dauer der Sitzung gespeichert bleiben.</p>
                        <p>Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen.
                            Zudem
                            dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit
                            unserer
                            informationstechnischen Systeme. Eine Auswertung der Daten zu anderen Zwecken findet in
                            diesem
                            Zusammenhang nicht statt.</p>
                        <p>In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art.
                            6
                            Abs. 1
                            lit. f DSGVO.</p>
                    </section>

                    <section>
                        <h4>Dauer der Speicherung</h4>
                        <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
                            mehr
                            erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies
                            der
                            Fall, wenn die jeweilige Sitzung beendet ist.</p>
                        <p>Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der
                            Fall.
                            Eine
                            darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der
                            Nutzer
                            gelöscht oder um die letzten beiden Oktets gekürzt (Bsp.: 192.168.xxx.xxx), sodass eine
                            Zuordnung
                            des aufrufenden Clients nicht mehr möglich ist.</p>
                    </section>

                    <section>
                        <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
                        <p>Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in
                            Logfiles
                            ist
                            für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des
                            Nutzers
                            keine Widerspruchsmöglichkeit.</p>
                    </section>
                </section>

                <section>
                    <h2>Verwendung von Cookies</h2>
                    <section>
                        <h4>Beschreibung und Umfang der Datenverarbeitung</h4>
                        <p>Verschiedene von der Universität des Saarlandes betriebene Webseiten verwenden Cookies. Bei
                            Cookies
                            handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
                            Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein
                            Cookie
                            auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
                            charakteristische
                            Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der
                            Website
                            ermöglicht.</p>
                        <p>Cookies werden eingesetzt, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente
                            unserer
                            Internetseiten erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel bzw.
                            bei
                            der
                            Nutzung von angebotenen Diensten identifiziert werden kann. Beim Aufruf einer solchen
                            Website
                            wird
                            der Nutzer über die Verwendung von Cookies zu Analysezwecken informiert und seine
                            Einwilligung
                            zur
                            Verarbeitung der in diesem Zusammenhang verwendeten personenbezogenen Daten eingeholt. In
                            diesem
                            Zusammenhang erfolgt auch ein Hinweis auf diese Datenschutzerklärung.</p>
                    </section>

                    <section>

                        <h4>Rechtsgrundlage für die Datenverarbeitung</h4>
                        <p>Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies
                            ist
                            Art. 6 Abs. 1 lit. f DSGVO.</p>
                        <p>Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies
                            zu
                            Analysezwecken ist bei Vorliegen einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs.
                            1
                            lit. a
                            DSGVO.</p>
                    </section>

                    <section>
                        <h4>Zweck der Datenverarbeitung</h4>
                        <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die
                            Nutzer
                            zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies
                            nicht
                            angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem
                            Seitenwechsel
                            wiedererkannt wird.</p>
                        <p>Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von
                            Nutzerprofilen verwendet.</p>
                    </section>

                    <section>
                        <h4>Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</h4>
                        <p>Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite
                            übermittelt.
                            Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch
                            eine
                            Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies
                            deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht
                            werden.
                            Dies
                            kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können
                            möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.</p>
                    </section>
                    <section id={'CookieDeclarationSection'}/>
                </section>

                <section>
                    <h2>Newsletter</h2>
                    <section>
                        <h4>Beschreibung und Umfang der Datenverarbeitung</h4>
                        <p>Auf verschiedenen Webseiten der Universität des Saarlandes besteht die Möglichkeit, einen
                            kostenfreien Newsletter zu abonnieren. Dabei werden bei der Anmeldung zum Newsletter die
                            Daten
                            aus
                            der jeweiligen Eingabemaske an uns übermittelt.</p>
                        <ul>
                            <li>E-Mail-Adresse</li>
                            <li>Interessengebiet</li>
                            <li>Monatlicher Bezug</li>
                            <li>Regionaler oder überregionaler Bezug</li>
                        </ul>
                        <p>Zudem werden folgende Daten bei der Anmeldung erhoben:</p>
                        <ul>
                            <li>IP-Adresse des aufrufenden Rechners</li>
                            <li>Datum und Uhrzeit der Registrierung</li>
                        </ul>
                        <p>Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgangs Ihre Einwilligung eingeholt
                            und
                            auf
                            diese Datenschutzerklärung verwiesen.</p>
                        <p>Es erfolgt im Zusammenhang mit der Datenverarbeitung für den Versand von Newslettern keine
                            Weitergabe
                            der Daten an Dritte. Die Daten werden ausschließlich für den Versand des Newsletters
                            verwendet.</p>
                    </section>

                    <section>
                        <h4>Rechtsgrundlage für die Datenverarbeitung</h4>
                        <p>Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum Newsletters durch den
                            Nutzer
                            ist
                            bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.</p>
                    </section>

                    <section>
                        <h4>Zweck der Datenverarbeitung</h4>
                        <p>Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter zuzustellen.</p>
                    </section>

                    <section>
                        <h4>Dauer der Speicherung</h4>
                        <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
                            mehr
                            erforderlich sind. Die E-Mail-Adresse des Nutzers wird demnach solange gespeichert, wie das
                            Abonnement des Newsletters aktiv ist.</p>
                    </section>

                    <section>
                        <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
                        <p>Das Abonnement des Newsletters kann durch den betroffenen Nutzer jederzeit gekündigt werden.
                            Zu
                            diesem Zweck findet sich in jedem Newsletter ein entsprechender Link. Hierdurch wird
                            ebenfalls
                            ein
                            Widerruf der Einwilligung der Speicherung der während des Anmeldevorgangs erhobenen
                            personenbezogenen Daten ermöglicht.</p>
                    </section>
                </section>

                <section>
                    <h2>Kontaktformular und E-Mail-Kontakt</h2>
                    <section>
                        <h4>Beschreibung und Umfang der Datenverarbeitung</h4>
                        <p>Auf einigen unserer Internetseiten ist ein Kontaktformular vorhanden, welches für die
                            elektronische
                            Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die
                            in
                            der
                            Eingabemaske eingegeben Daten an uns übermittelt und gespeichert. Bitte entnehmen Sie diese
                            der
                            jeweiligen Anmeldemaske.</p>
                        <p>Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:</p>
                        <ul>
                            <li>die IP-Adresse des Nutzers</li>
                            <li>Datum und Uhrzeit der Registrierung</li>
                        </ul>
                        <p>Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre Einwilligung eingeholt
                            und
                            auf
                            diese Datenschutzerklärung verwiesen.</p>
                        <p>Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In
                            diesem
                            Fall
                            werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.</p>
                        <p>Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden
                            ausschließlich für die Verarbeitung der Konversation verwendet.</p>
                    </section>

                    <section>

                        <h4>Rechtsgrundlage für die Datenverarbeitung</h4>
                        <p>Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des
                            Nutzers
                            Art.
                            6 Abs. 1 lit. a DSGVO.</p>
                        <p>Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail
                            übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den
                            Abschluss
                            eines
                            Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
                            DSGVO.</p>
                    </section>

                    <section>
                        <h4>Zweck der Datenverarbeitung</h4>
                        <p>Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur
                            Bearbeitung
                            der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das
                            erforderliche
                            berechtigte Interesse an der Verarbeitung der Daten.</p>
                        <p>Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu,
                            einen
                            Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer
                            informationstechnischen
                            Systeme sicherzustellen.</p>
                    </section>

                    <section>
                        <h4>Dauer der Speicherung</h4>
                        <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
                            mehr
                            erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars
                            und
                            diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige
                            Konversation
                            mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen
                            entnehmen
                            lässt, dass der betroffene Sachverhalt abschließend geklärt ist.</p>
                        <p>Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden
                            spätestens
                            nach
                            einer Frist von sieben Tagen gelöscht.</p>
                    </section>

                    <section>
                        <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
                        <p>Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der
                            personenbezogenen
                            Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der
                            Speicherung
                            seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die
                            Konversation
                            nicht fortgeführt werden.</p>
                        <p>Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in
                            diesem
                            Fall gelöscht.</p>
                    </section>
                </section>

                <section>
                    {/*section will be adjusted or removed at a later point*/}
                    <h2>Webanalyse durch Matomo (ehemals PIWIK)</h2>
                    <section>
                        <h4>Umfang der Verarbeitung personenbezogener Daten</h4>
                        <p>
                            Wir nutzen auf unserer Website das Open-Source-Software-Tool Matomo (ehemals PIWIK) zur
                            Analyse
                            des
                            Surfverhaltens unserer Nutzer.
                            Die Software setzt ein Cookie auf dem Rechner der Nutzer (zu Cookies siehe oben). Werden
                            Einzelseiten unserer Website aufgerufen,
                            so werden folgende Daten gespeichert:
                        </p>
                        <div className={styles.list}>
                            <ul>
                                <li>zwei Bytes der IP-Adresse des aufrufenden Systems des Nutzers</li>
                                <li>die aufgerufene Webseite</li>
                                <li>die Website, von der der Nutzer auf die aufgerufene Webseite gelangt ist
                                    (Referrer)
                                </li>
                                <li>die Unterseiten, die von der aufgerufenen Webseite aus aufgerufen werden</li>
                                <li>die Verweildauer auf der Webseite</li>
                                <li>die Häufigkeit des Aufrufs der Webseite</li>
                                <li>Anzahl der Aktionen auf der Webseite (Seitenansichten, Downloads, Nutzung von
                                    externen
                                    Links, interne Suchanfragen)
                                </li>
                            </ul>
                        </div>
                        <p>
                            Die Software läuft dabei ausschließlich auf den Servern unserer Webseite. Eine Speicherung
                            der
                            personenbezogenen Daten der Nutzer
                            findet nur dort statt. Eine Weitergabe der Daten an Dritte erfolgt nicht.
                            findet nur dort statt. Eine Weitergabe der Daten an Dritte erfolgt nicht.
                        </p>
                        <p>
                            Die Software ist so eingestellt, dass die IP-Adressen nicht vollständig gespeichert werden,
                            sondern
                            zwei Bytes der IP-Adresse maskiert
                            werden (Bsp.: 192.168.xxx.xxx). Auf diese Weise ist eine Zuordnung der gekürzten IP-Adresse
                            zum
                            aufrufenden Rechner nicht mehr möglich.
                        </p>
                    </section>
                    <section>
                        <h4>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
                        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1
                            lit. f DSGVO.</p>
                    </section>
                    <section>
                        <h4>Zweck der Datenverarbeitung</h4>
                        <p>
                            Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht uns eine Analyse des
                            Surfverhaltens unserer Nutzer. Wir sind durch die Auswertung der gewonnen Daten in der Lage,
                            Informationen über die Nutzung der einzelnen Komponenten unserer Webseite zusammenzustellen.
                            Dies hilft uns dabei, unsere Webseite und deren Nutzerfreundlichkeit stetig zu verbessern.
                            In
                            diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der Daten nach
                            Art. 6
                            Abs. 1 lit. f DSGVO. Durch die Anonymisierung der IP-Adresse wird dem Interesse der Nutzer
                            an
                            deren Schutz personenbezogener Daten hinreichend Rechnung getragen.
                        </p>
                    </section>
                    <section>
                        <h4>Dauer der Speicherung</h4>
                        <p>Die Daten werden gelöscht, sobald sie für unsere Aufzeichnungszwecke nicht mehr benötigt
                            werden.
                            In unserem Fall ist dies nach sechs Monaten der Fall.</p>
                    </section>
                    <section>
                        <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
                        <p>
                            Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite
                            übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von
                            Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die
                            Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können
                            jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere
                            Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website
                            vollumfänglich
                            genutzt werden.
                        </p>
                        <p>
                            Wir bieten unseren Nutzern auf unserer Website die Möglichkeit eines Opt-Out aus dem
                            Analyseverfahren. Hierzu müssen Sie das entsprechende Häkchen entfernen. Auf diese Weise
                            wird
                            ein weiterer Cookie auf ihrem System gesetzt, der unserem System signalisiert, die Daten des
                            Nutzers nicht zu speichern. Löscht der Nutzer den entsprechenden Cookie zwischenzeitlich vom
                            eigenen System, so muss er den Opt-Out-Cookie erneut setzten.
                        </p>
                        <p>
                            Nähere Informationen zu den Privatsphäreeinstellungen der Matomo Software finden Sie
                            unter: <a href={'https://matomo.org/docs/privacy'}>https://matomo.org/docs/privacy</a>
                        </p>
                    </section>
                </section>

                <section>
                    <h2>Rechte der betroffenen Person</h2>
                    <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es
                        stehen
                        Ihnen folgende Rechte gegenüber der Universität des Saarlandes zu:</p>
                    <section>
                        <h4>Auskunftsrecht</h4>
                        <p>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene
                            Daten,
                            die Sie betreffen, von uns verarbeitet werden.</p>
                        <p>Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
                            Informationen
                            Auskunft verlangen:</p>
                        <ul>
                            <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden</li>
                            <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden</li>
                            <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie
                                betreffenden
                                personenbezogenen Daten offengelegt wurden oder noch offengelegt werden
                            </li>
                            <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten
                                oder,
                                falls
                                konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der
                                Speicherdauer
                            </li>
                            <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden
                                personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den
                                Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung
                            </li>
                            <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
                            <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die
                                personenbezogenen
                                Daten
                                nicht bei der betroffenen Person erhoben werden
                            </li>
                            <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
                                gemäß
                                Art.
                                22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige
                                Informationen
                                über
                                die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer
                                derartigen
                                Verarbeitung für die betroffene Person.
                            </li>
                        </ul>
                        <p>Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden
                            personenbezogenen
                            Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In
                            diesem
                            Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im
                            Zusammenhang
                            mit der Übermittlung unterrichtet zu werden.</p>
                        <p>Dieses Auskunftsrecht kann insoweit beschränkt werden, als es voraussichtlich die
                            Verwirklichung
                            der
                            Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die
                            Beschränkung
                            für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
                    </section>

                    <section>
                        <h4>Recht auf Berichtigung</h4>
                        <p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem
                            Verantwortlichen,
                            sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder
                            unvollständig
                            sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
                        <p>Ihr Recht auf Berichtigung kann insoweit beschränkt werden, als es voraussichtlich die
                            Verwirklichung
                            der Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die
                            Beschränkung für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
                    </section>

                    <section>
                        <h4>Recht auf Einschränkung der Verarbeitung</h4>
                        <p>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie
                            betreffenden personenbezogenen Daten verlangen:</p>
                        <ul>
                            <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer
                                bestreiten,
                                die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten
                                zu
                                überprüfen
                            </li>
                            <li>wenn die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen
                                Daten
                                ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
                                verlangen
                            </li>
                            <li>wenn der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung
                                nicht
                                länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen benötigen
                            </li>
                            <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt
                                haben
                                und
                                noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber
                                Ihren
                                Gründen überwiegen.
                            </li>
                        </ul>
                        <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen
                            diese
                            Daten
                            – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                            Ausübung
                            oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
                            oder
                            juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder
                            eines
                            Mitgliedstaats verarbeitet werden.</p>
                        <p>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden
                            Sie
                            von
                            dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>
                        <p>Ihr Recht auf Einschränkung der Verarbeitung kann insoweit beschränkt werden, als es
                            voraussichtlich
                            die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft
                            beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs- oder Statistikzwecke
                            notwendig
                            ist.</p>
                    </section>

                    <section>
                        <h4>Recht auf Löschung</h4>
                        <p className={styles.bold}>Löschungspflicht</p>
                        <p>Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen
                            Daten
                            unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten
                            unverzüglich
                            zu
                            löschen, sofern einer der folgenden Gründe zutrifft:</p>
                        <ul>
                            <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie
                                erhoben
                                oder
                                auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                            </li>
                            <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1
                                lit.
                                a
                                oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen
                                Rechtsgrundlage
                                für die Verarbeitung.
                            </li>
                            <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es
                                liegen
                                keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem.
                                Art.
                                21
                                Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
                            </li>
                            <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                            <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
                                rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                                erforderlich, dem der Verantwortliche unterliegt.
                            </li>
                            <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste
                                der
                                Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
                            </li>
                        </ul>
                        <p className={styles.bold}>Information an Dritte</p>
                        <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und
                            ist
                            er
                            gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter
                            Berücksichtigung
                            der
                            verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch
                            technischer
                            Art,
                            um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten,
                            darüber
                            zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen
                            personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten
                            verlangt
                            haben.</p>
                        <p className={styles.bold}>Ausnahmen</p>
                        <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
                        <ul>
                            <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information</li>
                            <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
                                der
                                Union
                                oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur
                                Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
                                öffentlicher
                                Gewalt erfolgt, die dem Verantwortlichen übertragen wurde
                            </li>
                            <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß
                                Art. 9
                                Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO
                            </li>
                            <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
                                historische
                                Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das
                                unter
                                Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser
                                Verarbeitung
                                unmöglich macht oder ernsthaft beeinträchtigt
                            </li>
                            <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen</li>
                        </ul>
                    </section>

                    <section>
                        <h4>Recht auf Unterrichtung</h4>
                        <p>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber
                            dem
                            Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie
                            betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung
                            der
                            Daten
                            oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
                            unmöglich
                            oder
                            ist mit einem unverhältnismäßigen Aufwand verbunden.</p>
                        <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
                            werden.</p>
                    </section>

                    <section>
                        <h4>Recht auf Datenübertragbarkeit</h4>
                        <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem
                            Verantwortlichen
                            bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
                            erhalten.
                            Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung
                            durch
                            den
                            Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
                            sofern</p>
                        <ul>
                            <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9
                                Abs.
                                2
                                lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
                            </li>
                            <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
                        </ul>
                        <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
                            personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen
                            übermittelt
                            werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen
                            hierdurch
                            nicht beeinträchtigt werden.</p>
                        <p>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten,
                            die
                            für
                            die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in
                            Ausübung
                            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
                    </section>

                    <section>
                        <h4>Widerspruchsrecht</h4>
                        <p>Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit
                            gegen
                            die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
                            1
                            lit. e
                            oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
                            gestütztes Profiling.</p>
                        <p>Die Universität des Saarlandes verarbeitet die Sie betreffenden personenbezogenen Daten nicht
                            mehr,
                            es sei denn, sie kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
                            Ihre
                            Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
                            Geltendmachung,
                            Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                        <p>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
                            Informationsgesellschaft
                            – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter
                            Verfahren
                            auszuüben, bei denen technische Spezifikationen verwendet werden.</p>
                        <p>Sie haben auch das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, bei
                            der
                            Verarbeitung Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder
                            historischen
                            Forschungszwecken oder zu statistischen Zwecken gem. Art. 89 Abs. 1 DSGVO erfolgt, dieser zu
                            widersprechen.</p>
                        <p>Ihr Widerspruchsrecht kann insoweit beschränkt werden, als es voraussichtlich die
                            Verwirklichung
                            der
                            Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die
                            Beschränkung
                            für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
                    </section>

                    <section>
                        <h4>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h4>
                        <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu
                            widerrufen.
                            Durch
                            den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
                            Widerruf
                            erfolgten Verarbeitung nicht berührt.</p>
                    </section>

                    <section>
                        <h4>Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
                        <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht
                            Ihnen
                            das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                            Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie
                            der
                            Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
                            DSGVO
                            verstößt.</p>
                        <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
                            Beschwerdeführer
                            über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines
                            gerichtlichen
                            Rechtsbehelfs nach Art. 78 DSGVO.</p>
                        <p>Die für die Universität des Saarlandes zuständige Aufsichtsbehörde ist das</p>
                        <p>
                            Unabhängiges Datenschutzzentrum Saarland<br/>
                            Die Landesbeauftragte für Datenschutz und Informationsfreiheit<br/>
                            Fritz-Dobisch-Straße 12<br/>
                            66111 Saarbrücken<br/>
                            Tel.: 0681 94781-0<br/>
                            <a
                                href={'mailto:poststelle@datenschutz.saarland.de'}>poststelle(at)datenschutz.saarland.de</a>
                        </p>
                    </section>
                </section>

                <section>
                    <h2>Aktualisierung der Datenschutzerklärung</h2>
                    <p>Die Universität des Saarlandes weist darauf hin, dass das Online-Angebot ständiger
                        Weiterentwicklung
                        und Veränderung unterliegt. Dieser Umstand macht es erforderlich, die Datenschutzerklärung an
                        solche
                        Veränderungen und Weiterentwicklungen anzupassen. Sie sollten daher die Datenschutzerklärung
                        regelmäßig lesen, um sich über Veränderungen hinsichtlich der Verarbeitung Ihrer
                        personenbezogenen
                        Daten zu informieren. Falls die Veränderungen eine Mitwirkungshandlung Ihrerseits erfordern,
                        informieren wir Sie darüber.</p>
                </section>
            </div>
        </>
    );
};
