import React, {FunctionComponent, useEffect} from "react";
import {IntlProvider} from "react-intl";
import {HashRouter as Router, useLocation} from "react-router-dom";
import DEU from "../lang/DEU.json";
import {LessonWithImage, ShallowEntity} from "./types/api";
import {contentCreationServiceUrl} from "../api/util";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import jsonpatch from "fast-json-patch";
import {ThemeProvider} from "@mui/material";
import {ycg} from "@dis/ui-themes";
import {getStoredState, PersistConfig, REHYDRATE} from "redux-persist";

/**
 * Fetch JSON data from a path
 * @param path
 */
export function loadJsonFile(path: RequestInfo) {
    return fetch(path, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    });
}

export function addRouter(node: React.ReactElement) {
    return <Router>{node}</Router>;
}

export function addI18n(node: React.ReactElement) {
    return <IntlProvider locale={"DEU"} messages={DEU}>{node}</IntlProvider>;
}

const keycloak = Keycloak({
    url: "http://localhost:8080/auth",
    realm: "test",
    clientId: "test-web"
});

const initOptions = {
    onLoad: "check-sso",
    silentCheckSsoRedirectUri: window.origin + "/silent-check-sso.html"
};

export function addKeycloakProvider(node: React.ReactElement) {
    return <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>{node}</ReactKeycloakProvider>;
}

export function getLessonImage(lesson: ShallowEntity<LessonWithImage> | undefined) {
    return (lesson?.coverPictureURL && `${contentCreationServiceUrl}/${lesson?.coverPictureURL}`) || "";
}

/**
 * @constructor Helper component to solve scroll to top after navigating.
 * See https://v5.reactrouter.com/web/guides/scroll-restoration
 */
export const ScrollToTop: FunctionComponent = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export function computePatchOperations<T extends object>(originalObject: T, updatedObject: T) {
    return jsonpatch.compare(originalObject, updatedObject);
}

export function wrapWithThemeProvider(child: React.ReactNode) {
    return <ThemeProvider theme={ycg.getTheme()}>
        {child}
    </ThemeProvider>;
}

export const MEDIA_TABLET_WIDTH = 1080;
export const MEDIA_MOBILE_WIDTH = 450;

/*To check if a course is a workshop and to change the content tile to a workshop tile*/

export const workshop = (id: string | undefined, externalWorkshop: ShallowEntity<LessonWithImage>[]) => {
    if (id) {
        const findExternalWorkshop = externalWorkshop.find((lesson) => {
            return lesson.id === id;
        });
        return !!findExternalWorkshop;
    }
};

export function crossBrowserListener(store: {
    dispatch: (arg0: { type: "persist/REHYDRATE"; key: any; payload: object | undefined; }) => void;
}, persistConfig: PersistConfig<any>) {
    return async function () {
        let state = await getStoredState(persistConfig);

        store.dispatch({
            type: REHYDRATE,
            key: persistConfig.key,
            payload: state
        })
    }
}
