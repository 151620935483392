import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fontsource/roboto";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {initOptions, keycloak} from "./auth/keycloak";
import {tokensSet} from "./redux/authSlice";
import {store, persistor, persistConfig} from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {injectStore} from "./api/util";
import { crossBrowserListener } from "./utils/helper";

window.addEventListener('storage', crossBrowserListener(store, persistConfig))

injectStore(store);

ReactDOM.render(
    <React.StrictMode>
        <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}
                               onTokens={(authTokens) => store.dispatch(tokensSet(authTokens))}>
            <Provider store={store}>
               <PersistGate loading={null} persistor={persistor}>
                 <App/>
               </PersistGate>
            </Provider>
        </ReactKeycloakProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
