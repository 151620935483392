import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";

const sliceName = "dashboardFilters";

export interface InputDropdownProps {
    value: string;
    label: string;
}

export interface InputKnowledgeProps {
    value: number | undefined;
    label: string;
}

export interface DashboardFiltersState {
    searchInput: string;
    inputAge: InputDropdownProps;
    inputKnowledge: InputKnowledgeProps;
    filteredSubjectId: InputDropdownProps
}
/*
* Initial state of dashboardFilter state
* */
const initialState: DashboardFiltersState = {
    'searchInput': '',
    'inputAge': {
        'value': '',
        'label': ''
    },
    'inputKnowledge': {
        'value': undefined,
        'label': ''
    },
    'filteredSubjectId': {
        'value': '',
        'label': ''
    }
}
/**
 * Redux Slice handling actions on the dashboardFilters slice
 */
const DashboardFiltersSlice = createSlice({
    'name': sliceName,
    'initialState': initialState,
    'reducers': {
        DashboardFiltersSearchInputSet(state: DashboardFiltersState, action: PayloadAction<string>) {
            state.searchInput = action.payload
        },
        DashboardFiltersInputAgeSet(state: DashboardFiltersState, action: PayloadAction<InputDropdownProps>) {
            state.inputAge = action.payload
        },
        DashboardFiltersInputKnowledgeSet(state: DashboardFiltersState, action: PayloadAction<InputKnowledgeProps>) {
            state.inputKnowledge = action.payload
        },
        DashboardFiltersFilteredSubjectIdSet(state: DashboardFiltersState, action: PayloadAction<InputDropdownProps>) {
            state.filteredSubjectId = action.payload
        }
    }
})
/**
 * To be used by the Redux storage to set up the dashboardFilters slice
 */
export const DashboardFiltersReducer = DashboardFiltersSlice.reducer;

export const {
    DashboardFiltersSearchInputSet,
    DashboardFiltersInputAgeSet,
    DashboardFiltersInputKnowledgeSet,
    DashboardFiltersFilteredSubjectIdSet
} = DashboardFiltersSlice.actions

/**
 *
 * @param state
 * */
export const selectDashboardFilters = (state: RootState) => state.dashboardFilters
