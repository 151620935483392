import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthClientTokens } from "@react-keycloak/core/lib/types";
import { RootState } from "./store";

const sliceName = 'auth';

/**
 * The interface defines the structure of auth state.
 */
interface AuthState {
    tokens: AuthClientTokens | null
}

/**
 * The initial state of the auth state
 */
const initialState: AuthState = {
    'tokens': null
}

/**
 * The Redux slice handling actions on the auth slice.
 */
const authSlice = createSlice({
    'name': sliceName,
    'initialState': initialState,
    'reducers': {
        // set the tokens action
        tokensSet(state: AuthState, action: PayloadAction<AuthClientTokens>) {
            state.tokens = action.payload;
        }
    }
})

/**
 * To be used by the Redux storage to set up the auth slice.
 */
export const authReducer = authSlice.reducer;

export const {
    tokensSet
} = authSlice.actions;

/**
 * Selector to get the tokens out of the auth state
 * @param state
 */
export const selectTokens = (state: RootState) => state.auth.tokens;
