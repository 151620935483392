import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from './store';


const sliceName = 'userAvatar';

interface UserAvatarState {
    image: string | undefined
}

/**
 * The initial state of the userAvatar state
 */
const initialState: UserAvatarState = {
    'image': undefined
}

/**
 * The Redux slice handling actions on the userAvatar slice.
 */
const userAvatarSlice = createSlice({
    'name': sliceName,
    'initialState': initialState,
    'reducers': {
        // set the codingChallengeImages action
        userAvatarSet(state: UserAvatarState, action: PayloadAction<string|undefined>) {
            state.image = action.payload;
        }
    }
})

/**
 * To be used by the Redux storage to set up the userAvatar slice.
 */
export const userAvatarReducer = userAvatarSlice.reducer;

export const {
    userAvatarSet
} = userAvatarSlice.actions;

/**
 * Selector to get the images out of the userAvatar state
 * @param state
 */
export const selectUserAvatar = (state: RootState) => state.userAvatar.image;
