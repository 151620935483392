import {getRequest, hitCounterURL, postRequest} from './util';

export interface CounterInfo {
    count?: number,
    counterName?: string
}

const getCounter = async (name: string): Promise<CounterInfo | string> => {
    const fetchData = await getRequest(`${hitCounterURL}/${name}`);

    if (fetchData.ok) {
        return fetchData.json();
    } else {
        return fetchData.statusText;
    }
};

const incrementCounter = async (name: string) => {
    const request = await postRequest(`${hitCounterURL}/${name}`);
    if(request.ok) {
        return;
    }
};

export const counterApi = {
    getCounter,
    incrementCounter,
};
