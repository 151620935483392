import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

const sliceName = "userInterests";

export interface UserInterestsState {
  areaOfInterests: string[];
  topics: string[];
  age: string;
  codingLevel: string;
}

/*
* Initial state of userInterests state
* */
const initialState: UserInterestsState = {
  areaOfInterests: [],
  topics: [],
  age: '',
  codingLevel: ''
}
/**
 * Redux Slice handling actions on the userInterests slice
 */
const UserInterestsSlice = createSlice({
  'name': sliceName,
  'initialState': initialState,
  'reducers': {
    areaOfInterestsSet(state: UserInterestsState, action: PayloadAction<Array<string>>) {
      state.areaOfInterests = action.payload
    },
    topicsSet(state: UserInterestsState, action: PayloadAction<Array<string>>) {
      state.topics = action.payload
    },
    ageSet(state: UserInterestsState, action: PayloadAction<string>) {
      state.age = action.payload
    },
    codingLevelSet(state: UserInterestsState, action: PayloadAction<string>) {
      state.codingLevel = action.payload
    }
  }
})
/**
 * To be used by the Redux storage to set up the userInterests slice
 */
export const userInterestsReducer = UserInterestsSlice.reducer;

export const {
  areaOfInterestsSet,
  topicsSet,
  ageSet,
  codingLevelSet
} = UserInterestsSlice.actions

/**
 *
 * @param state
 * */
export const selectUserInterests = (state: RootState) => state.userInterests