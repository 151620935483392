import {contentCreationServiceUrl, getRequest} from "./util";
import {store} from "../redux/store";

export interface CodingChallengeImageList {
    filenames: Array<string>
}

export const SCOPE_CODING_CHALLENGE = `coding-challenge`;
export const SCOPE_USER_AVATAR = `user-avatar`;

const getImage = async (userId: string, scope: string) => {
    let bearerToken = store.getState().auth.tokens?.token;
    try {
        const response = await fetch(`${contentCreationServiceUrl}/user-files/${scope}/${userId}/${scope}`, {
            method: "GET",
            headers: {
                Accept: "image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8",
                Authorization: `Bearer ${bearerToken}`
            }
        })
        if (response.ok) {
            const imageBlob = await response.blob();
            return URL.createObjectURL(imageBlob);
        }
    } catch (e) {
        console.error(e);
    }
}

const getCodingChallengeUploadedImageList = async () => {
    let result;
    try {
        const response = await getRequest(`${contentCreationServiceUrl}/user-files/${SCOPE_CODING_CHALLENGE}/`);
        result = await response.json() as CodingChallengeImageList;
    } catch (e) {
        console.error("Cannot load uploaded pictures from coding challenge")
    }
    return result;
}

const postImage = (formData: FormData, scope: string) => {
    let bearerToken = store.getState().auth.tokens?.token;
    return fetch(`${contentCreationServiceUrl}/user-files/${scope}`, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: `Bearer ${bearerToken}`
        },
        body: formData
    });
};

export const userFilesApi = {
    getCodingChallengeUploadedImageList: getCodingChallengeUploadedImageList,
    postImage: postImage,
    getImage: getImage
};
