import React, {FunctionComponent} from 'react';
import styles from './HomeScreenBanner.module.scss';
import classNames from 'classnames';


export interface HomeScreenBannerProps {
    image?: string;
    rightSpace?: string;
    isParticipateBanner?: boolean
}

/* provides inner structure for ParticipateBanner and SignupBanner */
export const HomeScreenBanner: FunctionComponent<HomeScreenBannerProps> = ({
                                                                               image,
                                                                               rightSpace,
                                                                               children,
                                                                               isParticipateBanner = false
                                                                           }) => {
    function computeStyle() {
        if (rightSpace) {
            return {width: rightSpace};
        } else {
            return {};
        }
    }

    return (
        <div className={styles.banner}>
            {image &&
              <div className={styles.image}>
                <img src={image} alt={'Home screen banner'}/>
              </div>
            }
            <div className={classNames(styles.content, isParticipateBanner ? styles.participateBannerContent : '')}>
                <div className={styles.contentDisplay}>
                    {children}
                </div>
            </div>
            <div style={computeStyle()}/>
        </div>
    );
};

