import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { endOfCourseRecommenderApi } from "../api/endOfcourseRecommenderApi";


const sliceName = "endOfCourseRecommendations";

interface EndOfCourseRecommendationsState {
  endOfCourseRecommendations: string[] | null
}

/**
 * The initial state of the endOfCourseRecommendations state
 */
const initialState: EndOfCourseRecommendationsState = {
  'endOfCourseRecommendations': null
}

export const fetchEndOfCourseRecommendations = createAsyncThunk<string[], { courseId: string, userId: string, device?: string, amount?: number }>("fetch/" + sliceName, async ({
                                                                                                                                      courseId,
                                                                                                                                      userId,
                                                                                                                                      amount,
                                                                                                                                      device
                                                                                                                                      }) => {
  const recommendations = await endOfCourseRecommenderApi.getEndOfCourseRecommendations(courseId, userId, device);

  if (amount !== undefined) {
    return recommendations.slice(0, amount);
  } else {
    return recommendations;
  }
})


/**
 * To be used by the Redux storage to set up the endOfCourseRecommendations slice.
 */
const endOfCourseRecommendationsSlice = createSlice({
  "name": sliceName,
  "initialState": initialState,
  "reducers": {},
  "extraReducers": (builder => builder
      .addCase(fetchEndOfCourseRecommendations.fulfilled, (state, action) => {
        if(action.payload) {
          state.endOfCourseRecommendations = action.payload;
        }
      })
  )
});

export const endOfCourseRecommendationsReducer = endOfCourseRecommendationsSlice.reducer;


/**
 * Selector to get the recommended courses out of the endOfCourseRecommendations state
 * @param state
 */
export const selectEndOfCourseRecommendations = (state: RootState) => state.endOfCourseRecommendations.endOfCourseRecommendations;