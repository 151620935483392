import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";

const sliceName = 'userLoggedIn';

export interface UserProfile {
  email: string;
  email_verified: boolean;
  nickname: string;
  preferred_username: string;
  sub: string;
}

interface UserLoggedInState {
  loggedIn: boolean | undefined,
  userProfile: UserProfile
}

/**
 * The initial state of the userLoggedIn state
 */
const initialState: UserLoggedInState = {
  'loggedIn': false,
  userProfile: {
    email: "",
    email_verified: false,
    nickname: "",
    preferred_username: "",
    sub: ""
  }
}

/**
 * The Redux slice handling actions on the userLoggedIn slice.
 */
const userLoggedInSlice = createSlice({
  'name': sliceName,
  'initialState': initialState,
  'reducers': {
    // set the userLoggedIn action
    userLoggedInSet(state: UserLoggedInState, action: PayloadAction<boolean | undefined>) {
      state.loggedIn = action.payload;
    },
    userProfileSet(state: UserLoggedInState, action: PayloadAction<UserProfile>) {
      state.userProfile = action.payload;
    }
  }
})

/**
 * To be used by the Redux storage to set up the userLoggedIn slice.
 */
export const userLoggedInReducer = userLoggedInSlice.reducer;

export const {
  userLoggedInSet,
  userProfileSet
} = userLoggedInSlice.actions;

/**
 * Selector to get the pathname out of the userLoggedIn state
 * @param state
 */
export const selectUserLoggedIn = (state: RootState) => state.userLoggedIn.loggedIn;
export const selectUserProfile = (state: RootState) => state.userLoggedIn.userProfile;
