import React, {FunctionComponent} from 'react';
import styles from '../LegalNoticePage.module.scss';

export const LegalNoticePageDeu: FunctionComponent = () => {
    return (
        <>
            <h1>Impressum</h1>
            <p>
                Anbieter dieses Internetangebots ist die Universität des Saarlandes, eine Körperschaft des
                Öffentlichen
                Rechts, vertreten durch den
                Universitätspräsidenten Prof. Dr. Manfred Schmitt.
            </p>
            <p>
                Aufsichtsbehörde: Staatskanzlei des Saarlandes
            </p>
            <p className={styles.bold}>Anschrift</p>
            <p>
                Universität des Saarlandes<br/>
                Campus<br/>
                66123 Saarbrücken<br/>
                <br/>
                Telefon: +49 681 302-0<br/>
                Telefax: +49 681 302-2500<br/>
                E-Mail: <a href={'mailto:info@uni-saarland.de'}>info(at)uni-saarland.de</a>
            </p>
            <p className={styles.bold}>Haftungsausschluss</p>
            <p className={styles.list}>
                <ol>
                    <li>
                        Für die innerhalb des Webangebots der Universität des Saarlandes zugänglichen Seiten besteht
                        keinerlei Gewährleistung.
                        Es wird auch keine Haftung für die Korrektheit, Vollständigkeit oder Anwendbarkeit der
                        enthaltenen Informationen übernommen.
                    </li>
                    <li>
                        Die kommerzielle Nutzung der Seiten, insbesondere die Verbreitung gegen Entgelt, ist nicht
                        gestattet.
                    </li>
                    <li>
                        Wir übernehmen keine Haftung für eventuelle Datenschutz- und sonstige Rechtsverletzungen in
                        anderen Webangeboten, auf die wir
                        einen Link gesetzt haben. Für die Inhalte der von uns verlinkten Fremdangebote sind die
                        jeweiligen Herausgeber verantwortlich.
                        Vor dem Einrichten von Links sind die Webseiten der anderen Anbieter mit großer Sorgfalt und
                        nach bestem Wissen und Gewissen geprüft worden.
                        Es kann jedoch keine Gewähr für die Vollständigkeit und Richtigkeit von Informationen auf
                        verlinkten Seiten übernommen werden. Falls unsere
                        Seiten auf Seiten verweisen, deren Inhalt Anlass zur Beanstandung gibt, bitten wir um eine
                        entsprechende Mitteilung an: wwwadm@uni-saarland.de.
                    </li>
                    <li>
                        Die Universität ist berechtigt, ohne vorherige Ankündigung Änderungen oder Ergänzungen der
                        bereitgestellten Informationen vorzunehmen, gegebenenfalls
                        auch Informationen zu entfernen. Die Informationen auf den Seiten der Universität stellen
                        keinen Rechtsrat dar und ersetzen keinesfalls eine rechtskundige Beratung.
                    </li>
                    <li>
                        Links aus anderen Webseiten auf offizielle Seiten der Universität sind freigestellt, sofern
                        auf den Ursprung angemessen hingewiesen wird.
                    </li>
                    <li>
                        Bei (auch auszugsweiser) Verwendung offizieller Texte ist nicht die Veröffentlichung auf der
                        Internetseite maßgeblich, sondern immer die Originalfassung.
                    </li>
                </ol>
            </p>
            <br/>
            <section>
                <p>
                    Umsatzsteueridentifikationsnummer: DE138117521<br/>
                    EORI-Nummer: DE4305841
                </p>
                <p>
                    Soweit dieser Server journalistisch-redaktionell gestaltete Angebote enthält, in denen
                    vollständig oder teilweise Inhalte periodischer Druckerzeugnisse in Text oder
                    Bild wiedergegeben oder in periodischer Folge Texte verbreitet werden, ist verantwortlich i.S.v.
                    § 18 Abs. 2 MStV: Der Präsident der Universität des Saarlandes, Campus,
                    66123 Saarbrücken.
                </p>
                <p>
                    Die Übersetzungen der Webseiten sind ein Serviceangebot und dienen dem besseren Verständnis. Im
                    Falle von Abweichungen zwischen der Übersetzung und der deutschen
                    Originalfassung hat die deutsche Seite Vorrang.
                </p>
            </section>
            <section>
                <p>
                    Alle nicht eigens produzierten Bilder, Videos und Audios sind – sofern nicht anders gekennzeichnet – durch die Lizenz von Envato Elements abgedeckt.
                </p>
            </section>
            <section>
                <p>
                    Minderjährigenschutz<br/>
                    Kinder und Personen unter 18 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten
                    keine personenbezogenen Daten an uns übermitteln.
                </p>
            </section>
        </>
    );
};
