import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './TypeformFeedback.module.scss';
import { loadJsonFile } from '../../utils/helper';
import { Competence } from '@dis/types';
import { AgeGroups } from '../../components/AgeGroupDisplay/AgeGroupDisplay';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../redux/languageSlice';
import { ROUTES } from '../../utils/routes';
import { BreadcrumbGroup, BreadcrumbTabProps, Button, ButtonType } from "@dis/colors-components";
import { CourseIntro } from '../../building-blocks/CourseIntro/CourseIntro';
import { Widget } from '@typeform/embed-react';
// import { selectMiaSettings } from '../../redux/userSettingsSlice';
// import { MiaButton } from '../../components/Mia/MiaButton';
// import { MiaDirection } from '../../components/Mia/Mia';
// import { MiaHandler } from '../../components/Mia/MiaHandler/MiaHandler';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import { selectMediaQueries } from "../../redux/mediaQueriesSlice";

interface CourseIntroProps {
    description: string;
    name: string;
    competencies: Array<Competence>;
    duration: number;
    author: string;
    image: string;
    previous_knowledge: number;
    ageGroups: AgeGroups;
}

interface TypeformFeedbackContentProps {
    courseIntro: CourseIntroProps;
}

interface TypeformFeedbackProps {
    miaPosition?: boolean;
}

// const MEDIA_TABLET_WIDTH = 1080;

const TypeformFeedback: FunctionComponent<TypeformFeedbackProps> = () => {

    const intl = useIntl();
    const navigate = useNavigate();
    const selectedLanguage = useSelector(selectLanguage);
    // const {  mediaWidth } = useSelector(selectMediaQueries);

    const [pageContent, setPageContent] = useState<TypeformFeedbackContentProps>();

    /* newly added Mia handling */
    // const [showMia, setShowMia] = useState<boolean>(false);
    // const miaSettings = useSelector(selectMiaSettings);
    /*const closeMia = () => {
        setShowMia(false);
    };
    const openMia = useCallback(() => {
        setShowMia(true);
    }, []);*/

    useEffect(() => {

        async function loadData() {
            let data;
            switch (selectedLanguage) {
                case 'DEU':
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/mitmach-angebote/typeform-feedback/page-content.json`);
                    break;
                case 'ENG':
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/mitmach-angebote/typeform-feedback/page-content-eng.json`);
                    break;
                default:
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/mitmach-angebote/typeform-feedback/page-content.json`);
                    break;
            }
            setPageContent(await data.json());
        }

        loadData().catch(console.error);
    }, [selectedLanguage]);

    const openInNewTab = (): void => {
        // setShowMia(true);
        window.open("https://form.typeform.com/to/T1AxCn88", "_blank", "noreferrer, noopener");
    };

    /*function miaStyle() {
        if(mediaWidth <= MEDIA_TABLET_WIDTH && showMia) {
            return {
                zIndex: 12
            }
        }
        return {
            bottom: miaPosition && mediaWidth <= MEDIA_TABLET_WIDTH ? "110px" : ""
        }
    }*/

    const breadcrumbTabs: Array<BreadcrumbTabProps> = [
        {
            label: intl.formatMessage({ id: 'Dashboard.name' }),
            onClick: () => navigate(ROUTES.DASHBOARD.BASE)
        },
        { label: intl.formatMessage({ id: 'TypeformFeedback.breadcrumbLabel' }) }
    ];

    return (
      <div className={styles.typeformFeedback}>
          <div className={styles.breadcrumb}>
              <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs} />
          </div>
          {pageContent && (
            <div className={styles.courseIntro}>
                <CourseIntro
                  name={pageContent?.courseIntro.name}
                  description={pageContent.courseIntro.description}
                  duration={pageContent.courseIntro.duration}
                  ageGroups={pageContent.courseIntro.ageGroups}
                  difficulty={pageContent.courseIntro.previous_knowledge}
                  author={pageContent.courseIntro.author}
                  image={pageContent.courseIntro.image}
                  competences={pageContent.courseIntro.competencies}
                />
            </div>
          )}
          <div className={styles.feedback}>
              <Widget id="T1AxCn88" opacity={100} width={1306} height={724}/>
          </div>
          <div className={styles.button}>
              <Button
                type={ButtonType.PRIMARY}
                label={intl.formatMessage({id: "Participate.buttonLabel"})}
                onClick={openInNewTab}
                icon={<OpenInNewIcon/>}
              />
          </div>
          {/*<div className={styles.miaFixed} style={miaStyle()}>
              <MiaButton
                openMia={openMia}
                visible={!showMia}
                miaSettings={miaSettings}
                direction={MiaDirection.LEFT}
              />
              <div className={styles.miaHandler}>
                  <MiaHandler
                    onClose={closeMia}
                    open={showMia}
                    onOpen={openMia}
                    miaDirection={MiaDirection.LEFT}
                    // formatIdentifier={''}
                    miaSettings={miaSettings}
                    courseName={pageContent?.courseIntro.name}
                  />
              </div>
          </div>*/}
      </div>
    );
};

export default TypeformFeedback;
