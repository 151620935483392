import { FunctionComponent } from "react";
import styles from "./FormSentPage.module.scss";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useIntl } from "react-intl";
import { Button, ButtonType } from "@dis/colors-components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

export interface FormSentPageProps {
  thanksHeading: string,
  thanksText: string
}
export const FormSentPage: FunctionComponent<FormSentPageProps> = ({thanksHeading, thanksText}) => {

  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <div className={styles.FormSentPage}>
      <div className={styles.sentMessage}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <MarkEmailReadIcon style={{ "fontSize": "48px" }} />
          </div>
          <h2 className={styles.thanksHeading}>{intl.formatMessage({ id: thanksHeading })}</h2>
          <span className={styles.thanksText}>{intl.formatMessage({ id: thanksText })}</span>
        </div>
      </div>
      <div className={styles.backButton}>
        <Button label={intl.formatMessage({id: "Button.backToDashboard"})} type={ButtonType.SECONDARY} onClick={() => navigate(ROUTES.DASHBOARD.BASE)}/>
      </div>
    </div>
  );
}
