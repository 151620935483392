import { FormSentPage } from "../../../building-blocks/FormSentPage/FormSentPage";
import { FunctionComponent } from "react";

const OrderPageSent: FunctionComponent = () => {
  return FormSentPage({
    thanksHeading: "OrderPageSent.Thanks",
    thanksText: "OrderPageSent.Received"
  });
};

export default OrderPageSent;
