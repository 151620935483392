import React, {FunctionComponent} from 'react';
import styles from './PastEvent.module.scss';
import {IconWithLabel} from "@dis/colors-components";
import {Event, Place} from '@mui/icons-material';

export interface PastEventProps {
    image: string;
    title: string;
    description: string;
    date?: string;
    place?: string;
}
export const PastEvent: FunctionComponent<PastEventProps> = ({ title, description, image, date, place }) => {

    return (
        <div className={styles.pastEvent}>
            <div className={styles.content}>
                <div className={styles.imageFrame}>
                    <img src={image} alt={"Bild zu " + title} />
                </div>
                <div className={styles.text}>
                    <div className={styles.titleLabels}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.labels}>
                            { date &&
                              <IconWithLabel label={date}>
                                  <Event fontSize={"small"}/>
                              </IconWithLabel>
                            }
                            { place &&
                              <IconWithLabel label={place}>
                                  <Place fontSize={"small"}/>
                              </IconWithLabel>
                            }
                        </div>
                    </div>
                    <div className={styles.description} dangerouslySetInnerHTML={ {__html: description}}/>
                </div>
            </div>
        </div>
    );
};
