import React, { FunctionComponent } from "react";
import { HomeScreenBanner } from "../../components/HomescreenBanner/HomeScreenBanner";
import { Button, ButtonType } from "@dis/colors-components";
import styles from "./ParticipateBanner.module.scss";

/*  static "Mitmachen" hint banner for registered users on Dashboard with the help of HomeScreenBanner
*   Also used as a single slide inside DashboardBannerCarousel */

export interface ParticipateBannerProps {
  title?: string;
  description?: string;
  buttonLabel: string;
  onButtonClick: (event: React.MouseEvent) => void;
  image?: string;
}

export const ParticipateBanner: FunctionComponent<ParticipateBannerProps> = ({
                                                                             onButtonClick,
                                                                             title,
                                                                             description,
                                                                             buttonLabel,
                                                                             image
                                                                           }) => {


  return (
    <HomeScreenBanner image={image} isParticipateBanner={true}>
      <div className={styles.content}>
        <div className={styles.header}>
          {title}
        </div>
        <div className={styles.footer}>
          <div className={styles.description}>
            <p>
              {description}
            </p>
          </div>
          <Button type={ButtonType.PRIMARY} label={buttonLabel}
                  onClick={onButtonClick}
                  width={"510px"} height={"48px"} fontSize={"14px"}
          />
        </div>
      </div>
    </HomeScreenBanner>
  );
};

