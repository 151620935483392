import {createAsyncThunk} from "@reduxjs/toolkit";
import {normalize, NormalizedSchema} from "normalizr";
import {EntityMapping} from "../utils/redux";
import {LessonEntity} from "@dis/types";
import {lessonApi} from "../api";
import {userApi, UserSettings} from "../api/userApi";

const sliceName = "fetch";

/**
 * An action to fetch all available {@link Lesson} entities from the backend.
 *
 * This action was moved from the Lessons slice. It shall simulate fetching Lesson Templates.
 * Thus, currently we need to filter "empty" {@link Lesson}s.
 */
export const fetchLessons = createAsyncThunk<NormalizedSchema<EntityMapping, string[]>, void>(
  sliceName + '/lessons',
  async () => {
      const lessons = await lessonApi.getLessons();
      return normalize<any, EntityMapping, string[]>(lessons, [LessonEntity]);
});

/**
 * An action to fetch all available {@link UserSettings} entities from the backend.
 */
export const fetchUserSettings = createAsyncThunk<UserSettings, void>(
    sliceName + '/user-settings',
    async () => {
        return userApi.getUserSettings();
});


