import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import {
    BreadcrumbGroup,
    BreadcrumbTabProps,
    Button,
    ButtonType,
    TextArea,
    TextInput
} from "@dis/colors-components";
import styles from "./ContactPage.module.scss";
import {contactMailUrl, postRequest, emailValidation} from "../../api/util";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import {ConsentCheck} from "../../components/ConsentCheck/ConsentCheck";
import {HoneyPot} from "../../components/HoneyPot/HoneyPot";

const ContactPage: FunctionComponent = () => {

    const intl = useIntl();
    const navigate = useNavigate();

    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactMessage, setContactMessage] = useState("");

    const [contactNameError, setContactNameError] = useState(false);
    const [contactNameEmptyError, setContactNameEmptyError] = useState(false);
    const [contactEmailError, setContactEmailError] = useState(false);
    const [contactEmailEmptyError, setContactEmailEmptyError] = useState(false);
    const [contactMessageError, setContactMessageError] = useState(false);
    const [contactMessageEmptyError, setContactMessageEmptyError] = useState(false);

    const [contactConsentChecked, setContactContactConsentChecked] = useState(false);
    const [contactHoneypotEmpty, setContactHoneypotEmpty] = useState(true);

    const onSend = async () => {
        if (handleValidation()) {
            try {
                let postResponse = await postRequest(contactMailUrl, {
                    body: JSON.stringify({
                        form: "contact",
                        name: contactName,
                        address: contactEmail,
                        message: contactMessage
                    })
                });
                if (postResponse.ok) {
                    navigate(ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT_SENT);
                } else {
                    console.log("Error while sending contact mail: ", postResponse);
                }
            } catch (error) {
                console.error("Error while sending contact mail.", error);
            }
        }
    };

    const lettersSymbolsValidation = (value: string) => value.match(/^[\u0021-\u007E\s\u00C0-\u017F]+$/);

    /* checks before POST */
    const handleValidation = () => {
        let contactFormIsValid = true;
        setContactEmailError(false);
        setContactNameError(false);
        setContactMessageEmptyError(false);

        // Name
        if (!contactName || contactName === "") {
            contactFormIsValid = false;
            setContactNameEmptyError(true);
        }

        // E-Mail
        if (!contactEmail || contactEmail === "") {
            contactFormIsValid = false;
            setContactEmailEmptyError(true);
        } else if (typeof contactEmail !== "undefined") {
            if (!emailValidation(contactEmail)) {
                contactFormIsValid = false;
                setContactEmailError(true);
            }
        }

        // Message
        if (!contactMessage || contactMessage === "") {
            contactFormIsValid = false;
            setContactMessageEmptyError(true);
        }

        return contactFormIsValid;
    };

    const onContactCheckboxClicked = () => {
        setContactContactConsentChecked(!contactConsentChecked);
    };

    const handleContactHoneypot = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setContactHoneypotEmpty(false);
        }
    };


    const saveValue = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, input: string) => {
        e.preventDefault();
        const value = e.target.value;
        /* immediate checks! */
        switch (input) {
            case "contactName":
                setContactNameError(!lettersSymbolsValidation(value));
                setContactNameEmptyError(!value);
                setContactName(value);
                break;
            case "contactEmail":
                setContactEmailError(false);
                setContactEmailEmptyError(!value);
                setContactEmail(value);
                break;
            case "contactMessage":
                setContactMessageError(!lettersSymbolsValidation(value));
                setContactMessageEmptyError(!value);
                setContactMessage(value);
                break;
        }
    };

    const errorMessage = (error: boolean, messageId: string, empty?: boolean) => {
        if (empty) {
            return intl.formatMessage({ id: "InputField.empty" });
        }
        if (error) {
            return intl.formatMessage({ id: messageId })
        } else {
            return ""
        }
    };

    const breadcrumbTabs:Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'Header.contact'})}
    ];

    return (
      <>
          <div className={styles.breadcrumb}>
              <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
          </div>
        <div className={styles.ContactPage}>
            <div className={styles.form}>
                <h2 className={styles.header}>{intl.formatMessage({ id: "ContactPage.heading"})}</h2>
                <p className={styles.introText}>{intl.formatMessage({ id: "ContactPage.introText"})}</p>
                <div className={styles.inputFields}>
                    <div className={styles.inputField}>
                        <div className={styles.withError}>
                            <TextInput placeholder={""}
                                       value={contactName}
                                       label={intl.formatMessage({ id: "ContactPage.name" })}
                                       onChange={e => saveValue(e, "contactName")}
                                       requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                                       errorMessage={errorMessage(contactNameError, "ContactPage.letters", contactNameEmptyError)}
                            />
                        </div>
                    </div>
                    <div className={styles.inputField}>
                        <div className={styles.withError}>
                            <TextInput placeholder={""}
                                       value={contactEmail}
                                       label={intl.formatMessage({ id: "ContactPage.mail" })}
                                       onChange={e => saveValue(e, "contactEmail")}
                                       errorMessage={errorMessage(contactEmailError, "ContactPage.validMail", contactEmailEmptyError)}
                                       requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                            />
                        </div>
                    </div>
                    <div className={styles.textArea}>
                        <div className={styles.withError}>
                            <TextArea label={intl.formatMessage({ id: "ContactPage.message" })}
                                      placeholder={intl.formatMessage({ id: "ContactPage.type" }) + "..."}
                                      onChange={e => saveValue(e, "contactMessage")}
                                      maxCharacterLimit={1000000} showCount={false}
                                      requiredMessage={intl.formatMessage({ id: "InputField.empty" })}
                                      errorMessage={errorMessage(contactMessageError, "Input.invalidCharacters", contactMessageEmptyError)}

                            />
                        </div>
                    </div>
                    <ConsentCheck consentChecked={contactConsentChecked} handleChange={onContactCheckboxClicked}/>
                    <HoneyPot handleChange={(e) => handleContactHoneypot(e)}/>
                </div>
                <div className={styles.buttonContainer}>
                    <div className={styles.button}><Button label={intl.formatMessage({ id: "ContactPage.button" })}
                                                           onClick={onSend} type={ButtonType.PRIMARY}
                                                           disabled={!contactConsentChecked && contactHoneypotEmpty}
                    /></div>
                </div>
            </div>
        </div>
      </>
    );
};

export default ContactPage;
