import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { languageReducer } from "./languageSlice";
import { authReducer } from "./authSlice";
import { lessonsReducer } from "./lessonsSlice";
import { phasesReducer } from "./phaseSlice";
import { learningElementReducer } from "./learningElementsSlice";
import { formatConfigurationReducer } from "./formatConfigurationsSlice";
import { subjectsReducer } from "./subjectSlice";
import { tutorReducer } from "./tutorSlice";
import { codingChallengeImageReducer } from "./codingImageSlice";
import { userSettingsReducer } from "./userSettingsSlice";
import { miaCustomizationUrlReducer } from "./miaCustomizationUrlSlice";
import { userAvatarReducer } from "./userAvatarSlice";
import { DashboardFiltersReducer } from "./dashboardFiltersSlice";
import { competencesReducer } from "./competenceSlice";
import {recommendationReducer} from "./recommendationSlice";
import { internalOriginalContentReducer } from "./internalOriginalContentSlice";
import { mediaQueriesReducer } from "./mediaQueriesSlice";
import { userInterestsReducer } from "./userInterestsSlice";
import { miaChatReducer } from "./miaChatSlice";
import { userLoggedInReducer} from "./userLoggedInSlice";
import { endOfCourseRecommendationsReducer} from "./endOfCourseRecommendationsSlice";

const mainReducer = combineReducers({
    auth: authReducer,
    codingChallengeImage: codingChallengeImageReducer,
    formatConfigurations: formatConfigurationReducer, // Uses an Entity Adapter to give access to format configurations.
    language: languageReducer, // Provides state for the language selected by the user.
    learningElements: learningElementReducer, // Uses an Entity Adapter to give access to learning elements.
    lessons: lessonsReducer, // Uses an Entity Adapter to give access to lessons.
    phases: phasesReducer, // Uses an Entity Adapter to give access to phases.
    subjects: subjectsReducer, // Uses an Entity Adapter to give access to subjects.
    tutor: tutorReducer,
    userSettings: userSettingsReducer,
    miaCustomizationUrl: miaCustomizationUrlReducer,
    userAvatar: userAvatarReducer,
    dashboardFilters: DashboardFiltersReducer,
    competences: competencesReducer,
    recommendations: recommendationReducer,
    internalOriginalContent: internalOriginalContentReducer,
    mediaQueries: mediaQueriesReducer,
    userInterests: userInterestsReducer,
    miaChat: miaChatReducer,
    userLoggedIn: userLoggedInReducer,
    endOfCourseRecommendations: endOfCourseRecommendationsReducer
});

export const persistConfig = {
  key: 'root',
  storage,
  whitelist:['miaChat']
};

const persistedReducer = persistReducer(persistConfig, mainReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_MODE === "development",
  middleware: [thunk]
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
