import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./CourseModalDisplay.module.scss";
import { useIntl } from "react-intl";
import { CongratulationBadge } from "../../components/CongratulationBadge/CongratulationBadge";
import { Button, ButtonType, Carousel } from "@dis/colors-components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { ContentTileWrapper} from "../../components/ContentTile/ContentTileWrapper";
import { useSelector } from "react-redux";
import { selectExternalWorkshop, selectLessonsByName } from "../../redux/lessonsSlice";
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";
import { MEDIA_MOBILE_WIDTH, workshop } from "../../utils/helper";
import { selectEndOfCourseRecommendations } from "../../redux/endOfCourseRecommendationsSlice";

export const CourseModalDisplay: FunctionComponent = () => {

  const intl = useIntl();
  const navigate = useNavigate();
  const location = window.location.href.split("#")[1];

  const [scrollSpeed, setScrollSpeed] = useState<number>(0.75);

  const endOfCourseRecommendedLessons = useSelector(selectEndOfCourseRecommendations) || [];
  const externalWorkshops = useSelector(selectExternalWorkshop());
  const lessons = useSelector(selectLessonsByName(endOfCourseRecommendedLessons))
  const recommendedLessons = [...lessons].reverse();
  const { mediaWidth } = useSelector(selectMediaQueries);

  useEffect(() => {
    //set scroll speed
    if(mediaWidth <= MEDIA_MOBILE_WIDTH) setScrollSpeed(1);
    else setScrollSpeed(0.75);
  },[scrollSpeed, mediaWidth])

  const onClick = (id?: string, name?: string) => {
    if (id && name) {
      sessionStorage.setItem("lesson_id", name);
      navigate(`${ROUTES.CONTENT_VIEWER.BASE}?content-id=${id}`);
      window.location.reload();
    }
  };

  return (
    <div className={styles.courseModalDisplay}>
      <div className={styles.badgeWithText}>
        <div className={styles.leftColumn}>
          <div className={styles.heading}>
            <h2>
              {intl.formatMessage({ id: "CourseModalDisplay.modalTitle" })}
            </h2>
          </div>
          <div>
            <p className={styles.firstText}>
              {intl.formatMessage({ id: "CourseModalDisplay.modalLineOne" })}
            </p>
          </div>
          <div>
            <p>
              {intl.formatMessage({ id: "CourseModalDisplay.modalLineTwo" })}
            </p>
          </div>
        </div>
        <div className={styles.badge}>
          <CongratulationBadge />
        </div>
      </div>
      <div className={styles.courseTileWrapper}>
        {recommendedLessons &&
          recommendedLessons.length > 0 &&
          <Carousel scrollSpeed={scrollSpeed}>
            <div className={styles.courseTile}>
              {recommendedLessons.map((lesson) => (
                  <ContentTileWrapper
                    key={lesson.name}
                    lessonId={lesson.id || ""}
                    onClick={() => onClick(lesson.id, lesson.subject)}
                    href={`${location}content-viewer?content-id=${lesson.id}`}
                    workshop={workshop(lesson.id, externalWorkshops)}
                  />
                ))}
            </div>
          </Carousel>
            }
      </div>
      <div className={styles.dashboardButton}>
        <Button
          type={ButtonType.SECONDARY}
          label={intl.formatMessage({
            id: "Button.backToDashboard"
          })}
          onClick={() => navigate(ROUTES.DASHBOARD.BASE)}
        />
      </div>
    </div>
  );
};
