import React, {FunctionComponent} from 'react';
import styles from './UpcomingEvent.module.scss';
import {Button, ButtonType, IconWithLabel} from '@dis/colors-components';
import {Event, Place} from '@mui/icons-material';

export interface UpcomingEventProps {
    image: string;
    title: string;
    description: string;
    date?: string;
    place?: string;
    buttonLabel?: string;
    link?: string;
}

export const UpcomingEvent: FunctionComponent<UpcomingEventProps> = ({
                                                                         image,
                                                                         title,
                                                                         description,
                                                                         date,
                                                                         place,
                                                                         buttonLabel,
                                                                         link,
                                                                     }) => {
    return (
        <div className={styles.UpcomingEvent}>
            <div className={styles.content}>
                <div className={styles.imageFrame}>
                    <img className={styles.image} src={image} alt={"Bild zu " + title}/>
                </div>
                <div className={styles.info}>
                  <div className={styles.titleDetails}>
                    <div className={styles.title}>
                        {title}
                    </div>
                    <div className={styles.details}>
                        { date &&
                        <IconWithLabel label={date}>
                          <Event fontSize={"small"}/>
                        </IconWithLabel>
                        }
                        { place &&
                        <IconWithLabel label={place}>
                          <Place fontSize={"small"}/>
                        </IconWithLabel>
                        }
                    </div>
                  </div>
                  <div className={styles.description} dangerouslySetInnerHTML={ {__html: description}}/>
                  {link && buttonLabel &&
                    <div className={styles.button}>
                        <Button label={buttonLabel} onClick={()=> window.open(link, "_blank", "noreferrer, noopener")} type={ButtonType.PRIMARY}/>
                    </div>
                  }
                </div>
            </div>
        </div>
    );
};

