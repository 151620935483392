import React, {FunctionComponent, useEffect} from 'react';
import styles from '../PrivacyNoticePage.module.scss';
import {domainGroupId} from '../../../App';

export const PrivacyNoticePageEng: FunctionComponent = () => {

    useEffect(() => {
        const script = document.createElement('script');
        const src = 'https://consent.cookiebot.com/' + domainGroupId + '/cd.js';

        script.setAttribute('id', 'CookieDeclaration');
        script.setAttribute('src', src);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('data-culture', 'EN');
        script.async = true;
        document.getElementById('CookieDeclarationSection')?.appendChild(script);
    }, []);

    return (
        <>
            <h1>Privacy notice</h1>
            <div className={styles.padding}>
                <p>Saarland University regards the protection of personal data as an essential part of its
                    digitalization strategy. Ensuring that individuals have the right to decide on the disclosure and
                    processing of their personal data is of utmost priority to the university. This privacy notice
                    refers to the websites that Saarland University is responsible for. If the scope of data processing
                    on a Saarland University website goes beyond the procedures described here, this will be stated
                    explicitly on the relevant website.</p>

                <section>
                    <h2>Name and address of the data controller</h2>
                    <p>
                        The data controller as defined by the General Data Protection Regulation, national data
                        protection laws of member states and other data protection regulations is:
                    </p>
                    <p>
                        Saarland University<br/>
                        represented by the University President<br/>
                        Campus<br/>
                        66123 Saarbrücken<br/>
                        Tel.: +49 681 302-0<br/>
                        <a href={'mailto:postzentrale@uni-saarland.de'}>postzentrale(at)uni-saarland.de</a>
                    </p>
                </section>

                <section>
                    <h2>Contact details for the data protection officer</h2>
                    <p>
                        Meerwiesertalweg 15<br/>
                        66123 Saarbrücken<br/>
                        Tel.: +49 681 302-2813<br/>
                        <a href={'mailto:datenschutz@uni-saarland.de'}>datenschutz(at)uni-saarland.de</a><br/>
                        <a
                            href={'https://www.uni-saarland.de/verwaltung/datenschutz.html'}>www.uni-saarland.de/verwaltung/datenschutz</a>
                    </p>
                </section>

                <section>
                    <h2>General information on data processing</h2>
                    <section>
                        <h4>Purpose and scope of processing personal data</h4>
                        <p>Saarland University processes personal data only to the extent necessary to provide a
                            functioning website, to deliver website content and to offer services. As a rule, personal
                            data are only processed after the user gives their consent. An exception applies in those
                            circumstances in which it is not possible to obtain the user's prior consent and the
                            processing of such data is permitted by law.
                        </p>
                    </section>
                    <section>
                        <h4>processing</h4>
                        <p>Art. 6(1)(a) of the EU General Data Protection Regulation (GDPR) forms the legal basis for
                            Saarland University to obtain the consent of a data subject for their personal data to be
                            processed.</p>
                        <p>Art. 6(1)(b) GDPR forms the legal basis for processing personal data required for the
                            performance of a contract to which the data subject is party. This also applies if data have
                            to be processed prior to entering into a contract.</p>
                        <p>Art. 6(1)(c) GDPR forms the legal basis if personal data have to be processed in order to
                            fulfil a legal obligation on the part of Saarland University.</p>
                        <p>Art. 6(1)(d) GDPR forms the legal basis when processing of personal data is necessary to
                            protect vital interests of the data subject or another natural person.</p>
                        <p>Insofar as Saarland University is acting to fulfil its legally assigned research and teaching
                            duties, the legal basis for processing data is Section 3(13), Saarland Higher Education
                            Institutions Act.</p>
                        <p>Art. 6(1)(f) GDPR forms the legal basis if data processing is necessary in order to protect
                            the legitimate interests of Saarland University or of a third party except where such
                            interests are overridden by the interests or fundamental rights and freedoms of the data
                            subject.</p>
                    </section>
                    <section>
                        <h4>Erasure of data and storage period</h4>
                        <p>The personal data of the data subject are erased or blocked as soon as the reason for storing
                            them ceases to exist. Storage beyond this time period may occur if provided for by European
                            or national legislators in Union regulations or national legislation and rules to which the
                            data controller is subject. Such data shall also be blocked or erased if a storage period
                            prescribed by one of the aforementioned legal standards expires, unless further storage of
                            the data is necessary for entering into or performing a contract.</p>
                    </section>
                </section>

                <section>
                    <h2>Providing content and log files</h2>

                    <section>
                        <h4>Description and scope of data processing</h4>
                        <p>Each time a Saarland University website is accessed, our system automatically collects data
                            and information from the user's computer system in order to deliver website content. The
                            following data are collected:</p>
                        <ul>
                            <li>
                                The user's IP address
                            </li>
                            <li>
                                Date and time of access
                            </li>
                            <li>
                                Referrer website
                            </li>
                            <li>
                                Data transfer size
                            </li>
                            <li>
                                HTTP error code (error log)
                            </li>
                        </ul>
                        <p>The data are stored in our system's log files. We do not store the user's IP addresses or
                            other data that make it possible to assign the data to a specific user. The IP addresses are
                            truncated by the last two octets (e.g: 192.168.xxx.xxx). These data are not stored together
                            with the user's personal data.</p>
                    </section>

                    <section>
                        <h4>Legal basis for data processing</h4>
                        <p>The legal basis for the temporary storage of data and log files is Art. 6(1)(f) GDPR.</p>
                    </section>

                    <section>
                        <h4>Purpose of data processing</h4>
                        <p>The temporary storage of the IP address by the system is necessary in order to deliver the
                            website to the user's computer. The user's IP address is stored for the duration of the
                            session.</p>
                        <p>Data are stored in log files to ensure the website's functionality. These data also help us
                            optimize the website and ensure that our IT systems are secure. Data evaluation for other
                            purposes does not take place in this context.</p>
                        <p>The purposes stated above constitute our legitimate interests in processing data in
                            accordance with Art. 6(1)(f) GDPR.</p>
                    </section>

                    <section>
                        <h4>Storage period</h4>
                        <p>Data are erased as soon as they are no longer required for the purpose for which they were
                            collected. Data that have been collected for the purpose of providing the website are erased
                            at the end of the respective session.</p>
                        <p>Data stored in log files are erased after no more than seven days. A longer storage period is
                            possible. In this case, the users' IP addresses will be erased or shortened by the last two
                            octets (e.g: 192.168.xxx.xxx), so that they cannot be assigned to specific users.</p>
                    </section>

                    <section>
                        <h4>Options for filing an objection or requesting removal</h4>
                        <p>The collection of data for the purpose of providing the website and the storage of such data
                            in log files is essential to the website's operation and the user cannot therefore object to
                            such use.</p>
                    </section>
                </section>

                <section>
                    <h2>Use of cookies</h2>
                    <section>
                        <h4>Description and scope of data processing</h4>
                        <p>Saarland University websites may use cookies. Cookies are text files that are stored by the
                            web browser on the user's computer system. When a user accesses a website, a cookie may be
                            stored in the user's operating system. This cookie contains a character string that allows
                            the unique identification of the browser when the website is accessed again.</p>
                        <p>We use cookies to make the browsing experience more user-friendly. Some elements of our
                            websites require that the requesting browser can also be identified after changing pages or
                            when using specific services. In such cases, the user is informed about the use of cookies
                            for analysis purposes, and their consent to the processing of the personal data used in this
                            context is obtained. The user will also be informed about this privacy notice.</p>
                    </section>

                    <section>

                        <h4>Legal basis for data processing</h4>
                        <p>The legal basis for the processing of personal data with the use of cookies is Art. 6(1)(f)
                            GDPR.</p>
                        <p>Once the user has granted consent, the legal basis for data processing with the use of
                            cookies for analysis purposes is Art. 6(1)(a) GDPR.</p>
                    </section>

                    <section>
                        <h4>Purpose of data processing</h4>
                        <p>Cookies that are required for technical reasons are used to enhance the user experience. Some
                            functions on Saarland University websites cannot be offered without the use of cookies. In
                            order for these functions to work correctly, it is necessary that the browser is recognized
                            when the user accesses another page.</p>
                        <p>The user data collected by cookies that are required for technical reasons are not used to
                            create user profiles.</p>
                    </section>

                    <section>
                        <h4>Storage period, options for filing an objection or requesting removal</h4>
                        <p>As cookies are stored on the user's computer and are transmitted from it to our website,
                            users have full control over the use of cookies. Users can deactivate or restrict the
                            transmission of cookies by changing the settings in their web browser. Cookies that are
                            already stored can be erased at any time. This may also be done automatically. If you
                            deactivate cookies on a Saarland University website, you may not be able to use some of the
                            website's functions in full.</p>
                    </section>
                    <section id={'CookieDeclarationSection'}/>
                </section>

                <section>
                    <h2>Newsletters</h2>
                    <section>
                        <h4>Description and scope of data processing</h4>
                        <p>Some Saarland University websites offer subscription to a free newsletter. If you register
                            for a newsletter, the data from the input form will be transmitted to us.</p>
                        <ul>
                            <li>Email address</li>
                            <li>Area of interest</li>
                            <li>Newsletter frequency</li>
                            <li>Regional or national edition</li>
                        </ul>
                        <p>In addition, the following data are collected during registration:</p>
                        <ul>
                            <li>User's IP address</li>
                            <li>Date and time of registration</li>
                        </ul>
                        <p>In the course of the registration process, we request the user's consent for processing
                            personal data and draw their attention to this privacy notice.</p>
                        <p>No data are disclosed to third parties in connection with data processing for the dispatch of
                            newsletters. Such data are used exclusively for the purpose of sending the newsletter.</p>
                    </section>

                    <section>
                        <h4>Legal basis for data processing</h4>
                        <p>Once the user has granted consent, the legal basis for data processing following the user's
                            registration for a newsletter is Art. 6(1)(a) GDPR.</p>
                    </section>

                    <section>
                        <h4>Purpose of data processing</h4>
                        <p>The user's email address is needed to deliver the newsletter.</p>
                    </section>

                    <section>
                        <h4>Storage period</h4>
                        <p>Data are erased as soon as they are no longer required for the purpose for which they were
                            collected. Users' email addresses are therefore stored for as long as they subscribe to the
                            newsletter.</p>
                    </section>

                    <section>
                        <h4>Options for filing an objection or requesting removal</h4>
                        <p>Users may unsubscribe from a newsletter at any time. Each newsletter contains a link for this
                            purpose. This also allows subscribers to revoke their consent to the storage of personal
                            data collected during the registration process.</p>
                    </section>
                </section>

                <section>
                    <h2>Contact form and contact by email</h2>
                    <section>
                        <h4>Description and scope of data processing</h4>
                        <p>Contact forms are available on some Saarland University websites and may be used to contact
                            us electronically. If a user contacts us in this way, the data they enter in the input form
                            are transmitted to us and stored. As the data collected are specific to each contact form,
                            please refer to the relevant contact form.</p>
                        <p>The following data are also stored when the message is transmitted:</p>
                        <ul>
                            <li>The user's IP address</li>
                            <li>Date and time of registration</li>
                        </ul>
                        <p>
                            Before the message is sent, we request the user's consent for processing their personal data
                            and draw their attention to this privacy notice.</p>
                        <p>Alternatively, they contact us using the email address provided. In this case, the personal
                            data of the user transmitted with the email will be stored.</p>
                        <p>No data are disclosed to third parties in connection with the data processing required for
                            this purpose. The data are used exclusively for processing the conversation (i.e. email
                            communication) with the user.</p>
                    </section>

                    <section>

                        <h4>Legal basis for data processing</h4>
                        <p>Once the user has granted consent, the legal basis for data processing is Art. 6(1)(a)
                            GDPR.</p>
                        <p>The legal basis for the processing of data transmitted by email is Art. 6(1)(f) GDPR. If the
                            purpose of the email contact is to enter into a contract, the additional legal basis for
                            data processing is Art. 6(1)(b) GDPR.</p>
                    </section>

                    <section>
                        <h4>Purpose of data processing</h4>
                        <p>The personal data from the input form are processed solely for the purpose of contacting the
                            user. If the user contacts us by email, this also constitutes our legitimate interest in
                            processing the data.</p>
                        <p>All other personal data processed during the transmission of an email prevent misuse of the
                            contact form and to ensure that our IT systems are secure.</p>
                    </section>

                    <section>
                        <h4>Storage period</h4>
                        <p>Data are erased as soon as they are no longer required for the purpose for which they were
                            collected. This is the case for the personal data from the contact form and those data sent
                            by email when the respective conversation with the user has ended. The conversation will be
                            deemed to have ended when it can be inferred from the circumstances that the subject matter
                            in question has been conclusively settled.</p>
                        <p>Any additional personal data collected during the transmission process will be erased after a
                            period of no more than seven days.</p>
                    </section>

                    <section>
                        <h4>Options for filing an objection or requesting removal</h4>
                        <p>Users can withdraw their consent for the processing of their personal data at any time. If
                            the user contacts us by email, they may withdraw their consent for the storage of their
                            personal data at any time.</p>
                        <p>In such cases, the conversation cannot be continued and all personal data stored when contact
                            was made will be erased.</p>
                    </section>
                </section>

                <section>
                    {/*section will be adjusted or removed at a later point*/}
                    <h2>Web analysis using Matomo (formerly PIWIK)</h2>
                    <section>
                        <h4>Scope of processing of personal data</h4>
                        <p>
                            Saarland University websites may use the Matamo open-source software tool (formerly PIWIK)
                            to analyse our users' browsing behaviour. The software places a cookie on the user's
                            computer (please see above for more information about cookies). If the user accesses
                            individual pages on a Saarland University website, the following data are stored:
                        </p>
                        <div className={styles.list}>
                            <ul>
                                <li>Two bytes of the user's IP address</li>
                                <li>The accessed website</li>
                                <li>The referrer website</li>
                                <li>Other pages retrieved via the accessed website</li>
                                <li>Time on site</li>
                                <li>Access frequency</li>
                                <li>Number of actions on the website (page views, downloads, use of external links,
                                    internal search queries)
                                </li>
                            </ul>
                        </div>
                        <p>The software used for such analyses runs exclusively on our servers. Users' personal data are
                            stored on these servers only. Data are not disclosed to third parties.</p>
                        <p>The software is configured so that it does not store IP addresses in full, but instead marks
                            two bytes of the IP address (e.g. 192.168.xxx.xxx). In this way, the truncated IP address
                            can no longer be traced to the accessing computer.</p>
                    </section>
                    <section>
                        <h4>Legal basis for processing personal data</h4>
                        <p>The legal basis for the processing of users' personal data is Art. 6(1)(f) GDPR.</p>
                    </section>
                    <section>
                        <h4>Purpose of data processing</h4>
                        <p>Processing our users' personal data allows us to analyse their browsing behaviour. By
                            evaluating the collected data we are able to collate information about the use of Saarland
                            University websites. This helps us to continuously improve our content and user-experience.
                            The reasons stated above constitute our legitimate interests in processing data in
                            accordance with Art. 6(1)(f) GDPR. Anonymizing the IP address takes appropriate account of
                            users' interests in the protection of their personal data.</p>
                    </section>
                    <section>
                        <h4>Storage period</h4>
                        <p>Data are erased as soon as we no longer need them for tracking purposes, which in our case
                            means after six months.</p>
                    </section>
                    <section>
                        <h4>Options for filing an objection or requesting removal</h4>
                        <p>As cookies are stored on the user's computer and are transmitted from it by a Saarland
                            University website, users have full control over the use of cookies. Users can deactivate or
                            restrict the transmission of cookies by changing the settings in their web browser. Cookies
                            that are already stored can be erased at any time. This may also be done automatically. If
                            you deactivate cookies for a Saarland University website, you may not be able to use some of
                            the website's functions in full.</p>
                        <p>Our users can opt out of the analysis process. To do this, you must uncheck the corresponding
                            check box. This places an additional cookie on the user's system that signals to our system
                            not to store the user's data. If the user subsequently deletes this cookie from their own
                            system, they must set the opt-out cookie again.</p>
                        <p>
                            You can find further information about Matomo software and its privacy settings under the
                            following link: <a
                            href={'https://matomo.org/docs/privacy'}>https://matomo.org/docs/privacy</a>
                        </p>
                    </section>
                </section>

                <section>
                    <h2>Rights of the data subject</h2>
                    <p>If any of your personal data are processed, you are considered a data subject within the meaning
                        of the GDPR and have the following rights in relation to Saarland University:</p>
                    <section>
                        <h4>Right of access</h4>
                        <p>You have the right to obtain confirmation from the data controller as to whether or not we
                            are processing your personal data.</p>
                        <p>If your data are being processed, you have the right to request the following from the data
                            controller:</p>
                        <ul>
                            <li>The purposes for which your personal data are processed</li>
                            <li>The categories of personal data processed</li>
                            <li>The recipients or categories of recipients to whom your personal data have been or
                                will be disclosed
                            </li>
                            <li>The planned storage period for your personal data or, if details cannot be provided,
                                the criteria used to determine the storage period
                            </li>
                            <li>The existence of the right to rectification or erasure of your personal data, a
                                right to limitation of processing by the data controller or a right to object to
                                such processing
                            </li>
                            <li>The right to lodge a complaint with a supervisory authority</li>
                            <li>All available information on the source of the data if the personal data are not
                                collected from the data subject
                            </li>
                            <li>Information on the existence of automated decision-making, including profiling,
                                referred to in Article 22(1) and (4) GDPR and, at least in those cases, meaningful
                                information about the logic involved, as well as the significance and the envisaged
                                consequences of such processing for the data subject.
                            </li>
                        </ul>
                        <p>You have the right to request information as to whether your personal data are transmitted to
                            a third country or to an international organization. Where personal data are transferred to
                            a third country or to an international organization, you may request that you are informed
                            of the appropriate safeguards in accordance with Art. 46 GDPR.</p>
                        <p>This right of access may be restricted if it is likely to render impossible or seriously
                            impair the research or statistical purposes for which the data are required and restricting
                            the right of access is necessary to achieve the required research or statistical
                            purposes.</p>
                    </section>

                    <section>
                        <h4>Right to rectification</h4>
                        <p>You have the right to obtain from the data controller the rectification and/or completion of
                            your personal data if the data processed are inaccurate or incomplete. The data controller
                            must rectify such data without delay.</p>
                        <p>Your right to rectification may be restricted insofar as it is likely to render impossible or
                            seriously impair the research or statistical purposes for which the data are required and
                            restricting the right to rectification is necessary for achieving the required research or
                            statistical purposes.</p>
                    </section>

                    <section>
                        <h4>Right to restriction of processing</h4>
                        <p>You may request that the processing of your personal data is restricted in the event that one
                            of the following applies:</p>
                        <ul>
                            <li>You contest the accuracy of the personal data for a period that enables the data
                                controller to verify their accuracy.
                            </li>
                            <li>The processing is unlawful and you oppose the erasure of the personal data and
                                instead request the restriction of their use.
                            </li>
                            <li>The data controller no longer requires the personal data for the purposes of
                                processing, but you need them in order to assert, exercise or defend legal claims.
                            </li>
                            <li>You have objected to processing in accordance with Art. 21(1) GDPR pending
                                verification whether the legitimate grounds of the controller override your reasons.
                            </li>
                        </ul>
                        <p>If the processing of your personal data has been restricted, the data may, with the exception
                            of storage, only be processed with your consent or for the purpose of asserting, exercising
                            or defending legal claims or for protecting the rights of another natural or legal person or
                            for reasons of important public interest of the Union or a Member State. .</p>
                        <p>If processing has been restricted in accordance with the above conditions, the data
                            controller shall inform you before the restriction is lifted.</p>
                        <p>Your right to restriction of processing may be restricted insofar as it is likely to render
                            impossible or seriously impair the research or statistical purposes for which the data are
                            required and this restriction is necessary for achieving the required research or
                            statistical purposes.</p>
                    </section>

                    <section>
                        <h4>Right to erasure</h4>
                        <p className={styles.bold}>Duty to erase</p>
                        <p>You may request that the data controller erase your personal data without delay. The data
                            controller is obliged to erase these data without delay in the event that one of the
                            following applies:</p>
                        <ul>
                            <li>Your personal data are no longer required for the purposes for which they were
                                collected or otherwise processed.
                            </li>
                            <li>You withdraw your consent on which the processing was based in accordance with Art.
                                6(1)(a) or Art. 9(2)(a) GDPR and there is no other legal basis for the processing.
                            </li>
                            <li>You object to the processing in accordance with Art. 21(1) GDPR and there are no
                                overriding legitimate reasons for the processing, or you object to the processing in
                                accordance with Art. 21(2) GDPR.
                            </li>
                            <li>Your personal data have been processed unlawfully.</li>
                            <li>The erasure of your personal data is necessary to fulfil a legal obligation under
                                Union or Member State law to which the data controller is subject.
                            </li>
                            <li>Your personal data have been collected in relation to information society services
                                in accordance with Art. 8(1) GDPR.
                            </li>
                        </ul>
                        <p className={styles.bold}>Obligation to inform third parties</p>
                        <p>If the data controller has made your personal data public and is obliged to erase them in
                            accordance with Art. 17(1) GDPR, the controller shall, taking account of available
                            technology and the cost of implementation, take reasonable steps to inform data controllers
                            responsible for processing such personal data that you as the data subject have requested
                            the erasure by such controllers of any links to, or copy or replication of, these personal
                            data.</p>
                        <p className={styles.bold}>Exceptions</p>
                        <p>The right to erasure does not apply insofar as the processing is necessary:</p>
                        <ul>
                            <li>To exercise the right to freedom of expression and information</li>
                            <li>To fulfil a legal obligation that requires processing in accordance with Union or
                                Member State law to which the data controller is subject or to perform a task in the
                                public interest or in the exercise of official authority vested in the data
                                controller
                            </li>
                            <li>For reasons of public interest in the area of public health in accordance with Art.
                                9(2)(h) and (i) and Art. 9(3) GDPR
                            </li>
                            <li>For archiving purposes in the public interest, for scientific or historical research
                                purposes or for statistical purposes in accordance with Article 89(1) GDPR insofar
                                as the right referred to in Art. 89(1)(a) is likely to render impossible or
                                seriously impair the achievement of the objectives of such processing.
                            </li>
                            <li>To assert, exercise or defend legal claims.</li>
                        </ul>
                    </section>

                    <section>
                        <h4>Right to notification</h4>
                        <p>If you have exercised your right to have the data controller rectify, erase or restrict the
                            processing of your personal data, he or she is obliged to inform all recipients to whom such
                            data have been disclosed of their rectification or erasure or of the restriction of
                            processing, unless this proves impossible or involves disproportionate effort.</p>
                        <p>You have the right to be informed of these recipients by the controller.</p>
                    </section>

                    <section>
                        <h4>Right to data portability</h4>
                        <p>You have the right to receive the personal data you made available to the data controller in
                            a structured, common and machine-readable format. You also have the right to transmit these
                            data to another data controller without hindrance from the data controller to whom they were
                            made available, provided that:</p>
                        <ul>
                            <li>The processing is based on consent in accordance with Art. 6(1)(a) GDPR or Art.
                                9(2)(a) GDPR or on a contract in accordance with Art. 6(1)(b) GDPR.
                            </li>
                            <li>The processing is carried out by automated means.</li>
                        </ul>
                        <p>In exercising this right, you also have the right to have your personal data transmitted
                            directly from one data controller to another insofar as this is technically feasible. This
                            must not compromise the freedoms and rights of other persons.</p>
                        <p>The right to data portability does not apply to the processing of personal data necessary for
                            the performance of a task in the public interest or in the exercise of official authority
                            vested in the data controller.</p>
                    </section>

                    <section>
                        <h4>Right to object</h4>
                        <p>You have the right, on grounds relating to your particular situation, to object at any time
                            to the processing of your personal data that occurs on the basis of Art. 6(1)(e) or (f)
                            GDPR; this also applies to profiling activities undertaken on the basis of these
                            provisions.</p>
                        <p>Saarland University shall no longer process your personal data, unless it produces compelling
                            legitimate reasons for such processing that outweigh your interests, rights and freedoms or
                            such processing is necessary for asserting, exercising or defending legal claims.</p>
                        <p>In connection with the use of information society services, and notwithstanding Directive
                            2002/58/EG, you may exercise your right to object by automated means using technical
                            specifications.</p>
                        <p>You also have the right, on grounds relating to your particular situation, to object to the
                            processing of your personal data that occurs for scientific or historical research purposes
                            or for statistical purposes in accordance with Art. 89(1) GDPR.</p>
                        <p>Your right to object may be restricted insofar as it is likely to render impossible or
                            seriously impair the achievement of research or statistical purposes, and this restriction
                            is necessary for achieving such research or statistical purposes.</p>
                    </section>

                    <section>
                        <h4>Right to withdraw a declaration of consent concerning data protection</h4>
                        <p>You have the right to withdraw your declaration of consent concerning data protection at any
                            time. Withdrawing your consent does not affect the lawfulness of data processing based on
                            your consent before its withdrawal.</p>
                    </section>

                    <section>
                        <h4>Right to lodge a complaint with a supervisory authority</h4>
                        <p>Without prejudice to any other administrative or judicial remedy, you have the right to lodge
                            a complaint with a supervisory authority, in particular in the Member State of your habitual
                            residence, your place of work or the place of the alleged infringement if you consider that
                            the processing of your personal data infringes the GDPR.</p>
                        <p>The supervisory authority with which the complaint has been lodged shall inform the
                            complainant of the progress and the outcome of the complaint, including the possibility of a
                            judicial remedy in accordance with Art. 78 GDPR.</p>
                        <p>The regulatory authority responsible for Saarland University is:</p>
                        <p>
                            Unabhängiges Datenschutzzentrum Saarland<br/>
                            Die Landesbeauftragte für Datenschutz und Informationsfreiheit<br/>
                            Fritz-Dobisch-Straße 12<br/>
                            66111 Saarbrücken<br/>
                            Tel.: +49 681 94781-0<br/>
                            <a href={'mailto:poststelle@datenschutz.saarland.de'}>poststelle(at)datenschutz.saarland.de</a>
                        </p>
                    </section>
                </section>

                <section>
                    <h2>Updates to this privacy notice</h2>
                    <p>Please note that Saarland University's online services are subject to constant change and
                        development and this privacy notice will be updated accordingly. You should therefore read this
                        privacy notice regularly to keep informed about changes with regard to the processing of your
                        personal data. We will notify you if these changes require your consent.</p>
                </section>
            </div>
        </>
    );
};
