import React, {FunctionComponent} from "react";
import styles from "./HoneyPot.module.scss";
import {TextInput} from "@dis/colors-components";

export interface HoneyPotProps {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}
export const HoneyPot: FunctionComponent<HoneyPotProps> = ({handleChange}) => {
    return(
        <div className={styles.verification}>
            <div className={styles.winnieThePoo}>
                <TextInput placeholder={""} label={"WinnieThePoo"}
                           value={"WinnieThePoo"}
                           onChange={(e) => handleChange(e)} />
            </div>
        </div>
    );
}
