import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {ShallowEntity} from '../utils/types/api';
import {fetchUserSettings} from './fetchActions';
import {UserSettings} from "../api/userApi";
import {RootState} from "./store";


const sliceName = 'user-settings';


export const userSettingsAdapter = createEntityAdapter<ShallowEntity<UserSettings>>();

export interface UserSettingsState {
    parameters: UserSettings;
}
/**
 * The initial state of the auth state
 */
const initialState: UserSettingsState = {
    parameters: {}
};

const userSettingsSlice = createSlice({
    'name': sliceName,
    'initialState': initialState,
    'reducers': {
        // set the tokens action
        saveUserSettings(state: UserSettingsState, action) {
            state.parameters = action.payload;
        }
    },
    'extraReducers': (builder) => {
        builder.addCase(fetchUserSettings.fulfilled, (state, action) => {
            if (action.payload) {
                state.parameters = action.payload;
            }
        })
    }
});

export const selectUserSettings = (state: RootState) => state.userSettings.parameters;

export const selectMiaSettings = (state: RootState) => {
    if (state.userSettings?.parameters?.miaSettings) {
        return state.userSettings.parameters.miaSettings
    } else {
        return undefined;
    }
};

export const userSettingsReducer = userSettingsSlice.reducer;

export const {
    saveUserSettings
} = userSettingsSlice.actions;
