import React, {FunctionComponent} from 'react';
import styles from './DifficultyDisplay.module.scss';



export interface DifficultyDisplayProps {
    difficulty: number;
    size?: string;
}


export const DifficultyDisplay: FunctionComponent<DifficultyDisplayProps> = ({ difficulty, size }) => {

    return (
        <div className={styles.Difficulty} style={{height: size}}>
            <div className={difficulty >= 1 ? styles.active : styles.inactive}/>
            <div className={difficulty >= 2 ? styles.active : styles.inactive}/>
            <div className={difficulty >= 3 ? styles.active : styles.inactive}/>
        </div>
    );
};



