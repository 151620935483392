import React, {FunctionComponent} from 'react';
import {HomeScreenBanner} from "../../components/HomescreenBanner/HomeScreenBanner";
import {Button, ButtonType} from "@dis/colors-components";
import {useSelector} from "react-redux";
import {selectMediaQueries} from "../../redux/mediaQueriesSlice";
import {MEDIA_TABLET_WIDTH} from "../../utils/helper";
import styles from "../MilestoneBanner/MilestoneBanner.module.scss";

/*  Milestones hint banner for unregistered users on Startpage with the help of HomeScreenBanner
*   Also used as a single slide inside StartpageBannerCarousel */

export interface MilestoneBannerProps {
    title: string;
    image: string;
    description: string;
    buttonLabel: string;
    onButtonClick: () => void;
}
export const MilestoneBanner: FunctionComponent<MilestoneBannerProps> = ({
                                                                             title,
                                                                             image,
                                                                             description,
                                                                             buttonLabel,
                                                                             onButtonClick
                                                                        }) => {

    const { mediaWidth } = useSelector(selectMediaQueries);



    return (
        <HomeScreenBanner image={image} rightSpace={mediaWidth <= MEDIA_TABLET_WIDTH ? undefined : '302px'}>
            <div className={styles.content}>
                <div className={styles.header}>
                    {title}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
                <Button type={ButtonType.PRIMARY} label={buttonLabel} onClick={onButtonClick} width={'356px'} height={'48px'} fontSize={"14px"}/>
            </div>
        </HomeScreenBanner>

    )
}
