import React, {FunctionComponent} from 'react';
import styles from './PrivacyNoticePage.module.scss'
import {useSelector} from 'react-redux';
import {selectLanguage} from '../../redux/languageSlice';
import {PrivacyNoticePageDeu} from './lang/PrivacyNoticePageDeu';
import {PrivacyNoticePageEng} from './lang/PrivacyNoticePageEng';
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {BreadcrumbGroup, BreadcrumbTabProps} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";

const PrivacyNoticePage: FunctionComponent = () => {

    let selectedLanguage = useSelector(selectLanguage);
    let selectedPrivacyNoticePage;

    const intl = useIntl();
    const navigate = useNavigate();

    switch (selectedLanguage) {
        case "DEU": selectedPrivacyNoticePage = <PrivacyNoticePageDeu />; break;
        case "ENG": selectedPrivacyNoticePage = <PrivacyNoticePageEng />; break;
        default: selectedPrivacyNoticePage = <PrivacyNoticePageDeu />;
    }

    const breadcrumbTabs:Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'LegalPrivacyNoticeBar.privacy'})}
    ];

    return (
        <div className={styles.PrivacyNoticePage}>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
            </div>
            {selectedPrivacyNoticePage}
        </div>
    )
};

export default PrivacyNoticePage;
