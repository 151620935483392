export const ROUTES = {
  LAUNCH_COUNTDOWN : "/launch-countdown",
  DASHBOARD: {
    BASE: "/",
    INITIATIVE: "/initiative",
    BLOG: {
      BASE: "/blogs",
      BLOG_VIEW: "/blog-viewer"
    },
    EVENTS: "/events",
    MEDIA: "/press",
    HANDOUT: "/handreichung",
    NEWSLETTER: "/newsletter",
    CONTACT: "/contact",
    USERAVATAR: {
      LEARNING_OFFERS: "/learning-offers",
      PROFILE: "/profile"
    }
  },
  REGISTER: {
    BASE: "/register",
    PERSONALIZATION: "/register-personalization"
  },
  CONTENT_VIEWER: {
    BASE: "/content-viewer",
    CODING_CHALLENGE: "/coding-challenge",
    TYPEFORM_FEEDBACK: "/typeform-feedback"
  },
  LEGAL_NOTICE_PRIVACY_BAR: {
    LEGAL_NOTICE: "/legal-notice",
    PRIVACY_NOTICE: "/privacy-notice",
    CONTACT: "/contact",
    CONTACT_SENT: "/contact-sent"
  },
  HANDOUT: {
    CLUB_DER_CODERINNEN: "/club-der-coderinnen",
    DRUNTER_UND_DRUEBER: "/drunter-und-drueber",
    EXPERTINNEN_UNTER_SICH: "/expertinnen-unter-sich",
    GEHEIME_SPRACHEN: "/geheime-sprachen",
    ICH_MAGS: "/ich-mags",
    LUEGE_ODER_WAHRHEIT: "/luege-oder-wahrheit",
    OBS_ZUSAMMENPASST: "/obs-zusammenpasst",
    OPEN_SPACE: "/open-space",
    RAKETENSTART: "/raketenstart",
    SCHWEIGEPFLICHT: "/schweigepflicht",
    UNSERE_YOUCODE_WOCHE: "/unsere-youcode-woche",
    YOU_CAN_DO_IT: "/you-can-do-it",
    WO_GIBT_ES_CODING: "/wo-gibt-es-coding",
    AUDIO: {
      IN_THE_SPACE: "/in-the-space"
    },
    ORDER: "/order",
    ORDER_SENT: "/order-sent"
  }
};
