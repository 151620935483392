import React, {FunctionComponent} from "react";
import {useSelector} from "react-redux";
import {selectLessonByThemes} from "../../redux/lessonsSlice";
import {ContentTileWrapper} from "./ContentTileWrapper";
import {ROUTES} from "../../utils/routes";
import {useNavigate} from "react-router-dom";
import {selectRecommendations} from "../../redux/recommendationSlice";

export interface ContentTileGroupProps {
    subjectId: string;
    subjectName: string;
}

export const ContentTileSubjectGroup: FunctionComponent<ContentTileGroupProps> = ({
                                                                                      subjectId,
                                                                                      subjectName
                                                                                  }) => {
    const lessons = useSelector(selectLessonByThemes(subjectName));
    const recommendations = useSelector(selectRecommendations);
    const navigate = useNavigate();
    const location = window.location.href.split("#")[0];

    const onClick = (event:React.MouseEvent, id?: string) => {
        if(event.ctrlKey) {
            sessionStorage.setItem("lesson_id", subjectId);
        } else if (id) {
            sessionStorage.setItem("lesson_id", subjectId);
            navigate(`${ROUTES.CONTENT_VIEWER.BASE}?content-id=${id}`);
        }
    };

    return (
        <>
            {
                lessons?.filter((lesson) => {
                    if (recommendations) {
                        // if recommendations are available we filter all lessons without a given recommendation order
                        return recommendations[subjectName].courseOrder[lesson.name] !== undefined
                    } else {
                        // if no recommendations are available we do not filter at all
                        return true;
                    }
                })
                .sort((lessonA, lessonB) => {
                    if (recommendations) {
                        // if recommendations are available we sort all lessons by the given recommendation order
                        return recommendations[subjectName].courseOrder[lessonA.name] - recommendations[subjectName].courseOrder[lessonB.name];
                    } else {
                        // if no recommendations are available we sort by name
                        return lessonA.name.localeCompare(lessonB.name);
                    }
                }).map((lesson) =>
                    <ContentTileWrapper
                        key={`lesson_${lesson.id}`}
                        lessonId={lesson.id || ""}
                        onClick={(event) => onClick(event, lesson.id)}
                        href={`${location}content-viewer?content-id=${lesson.id}`}
                    />)
            }
        </>
    )
}