import React, { FunctionComponent, ReactElement } from "react";
import styles from "./ContentDescription.module.scss";
import { Icon, IconType } from "@dis/ycg-components";
import { useIntl } from "react-intl";
import { Competence } from "@dis/types";
import { useSelector } from "react-redux";
import { selectCompetencesFromIds } from "../../redux/competenceSlice";
import { ShallowEntity } from "../../utils/types/api";

export interface ContentDescriptionProps {
  name: string;
  description: ReactElement | string;
  competenceIds?: string[];
  competences?: Competence[];
}

export const ContentDescription: FunctionComponent<ContentDescriptionProps> = ({
  name,
  description,
  competenceIds,
  competences
}) => {
  const intl = useIntl();

  let competencesObjects: (
    | ShallowEntity<Competence>
    | undefined
  )[] = useSelector(selectCompetencesFromIds(competenceIds || []));

  return (
    <div className={styles.ContentDescription}>
      <h1>{name}</h1>
      <div className={styles.description}>{description}</div>
      {/*Normal Competences from courses as Ids*/}
      {competencesObjects && competencesObjects.length > 0 && (
        <>
          <div className={styles.competencesHeader}>
            {intl.formatMessage({ id: "ContentDescription.competencesTitle" })}
          </div>
          <div className={styles.competences}>
            {competencesObjects?.map((competence) => {
              return (
                <div className={styles.competence} key={competence?.name}>
                  <div className={styles.icon}>
                    <Icon
                      name={"check_circle"}
                      type={IconType.PRIMARY}
                      size={"22px"}
                    />
                  </div>
                  {competence && <div>… {competence?.name}</div>}
                </div>
              );
            })}
          </div>
        </>
      )}
      {/*Competences as strings of names (As used in CodingChallenge)*/}
      {competences && (
        <>
          <div className={styles.competencesHeader}>
            {intl.formatMessage({ id: "ContentDescription.competencesTitle" })}
          </div>
          <div className={styles.competences}>
            {competences?.map((competence) => {
              return (
                <div className={styles.competence} key={competence.name}>
                  <div className={styles.icon}>
                    <Icon
                      name={"check_circle"}
                      type={IconType.PRIMARY}
                      size={"22px"}
                    />
                  </div>
                  {competence && <div>… {competence.name}</div>}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
