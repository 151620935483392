import React, { FunctionComponent, useEffect, useState } from "react";
import { BreadcrumbGroup, BreadcrumbTabProps, NotificationBanner,
  NotificationBannerType, Button, TextInput } from "@dis/colors-components";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { ROUTES } from "../../utils/routes";
import styles from "./ProfilePage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAvatar, userAvatarSet } from "../../redux/userAvatarSlice";
import { SCOPE_USER_AVATAR, userFilesApi } from "../../api/UserFilesApi";
import { useKeycloak } from "@react-keycloak/web";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { saveUserSettings, selectUserSettings } from "../../redux/userSettingsSlice";
import {
  SelectableTilesCategory,
  updatingUserSettings
} from "../../building-blocks/SelectableTilesCategory/SelectableTilesCategory";
import {
  ageSet,
  areaOfInterestsSet,
  codingLevelSet,
  selectUserInterests,
  topicsSet, UserInterestsState
} from "../../redux/userInterestsSlice";

interface ProfilePageProps {
  selectThreeInterestsOrTopics: (userInterests: UserInterestsState) => string;
}

const ProfilePage: FunctionComponent<ProfilePageProps> = ({selectThreeInterestsOrTopics}) => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const uploadedImage = useSelector(selectUserAvatar);
  const userSettings = useSelector(selectUserSettings);
  const userInterests = useSelector(selectUserInterests);
  const { areaOfInterests, topics, age, codingLevel } = userSettings.personalizationPageSettings || {};

  const [nickname, setNickname] = useState(userSettings.userName || "");
  const [nicknameSet, setNicknameSet] = useState(userSettings.userName !== undefined);
  const [profileUpdateBanner, setProfileUpdateBanner] = useState<boolean>(false);
  const [selectThreeBanner, setSelectThreeBanner] = useState<boolean>(false);

  const breadcrumbTabs: Array<BreadcrumbTabProps> = [
    { label: intl.formatMessage({ id: "Dashboard.name" }), onClick: () => navigate(ROUTES.DASHBOARD.BASE) },
    { label: intl.formatMessage({ id: "Profile.name" }) }
  ];

  useEffect(() => {
    async function loadFiles() {
      let userId = null;
      if (initialized && keycloak.authenticated) {
        await keycloak.loadUserInfo().then((profile: any) => {
          userId = profile.sub;
        });
      }

      if (userId) {
        const receivedImage = await userFilesApi.getImage(userId, SCOPE_USER_AVATAR);
        if (receivedImage !== undefined) {
          dispatch(userAvatarSet(receivedImage));
        }
      }
    }

    loadFiles();
  }, [dispatch, initialized, keycloak]);

  const onUploadImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const contentType = e.target.files[0].type.toLowerCase();
      if (contentType.startsWith("image")) {
        const formData = new FormData();
        formData.append("file", e.target.files[0], SCOPE_USER_AVATAR);

        const response = await userFilesApi.postImage(formData, SCOPE_USER_AVATAR);

        if (response.ok) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            dispatch(userAvatarSet(event.target.result));
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(areaOfInterestsSet(areaOfInterests || []));
    dispatch(topicsSet(topics || []));
    dispatch(codingLevelSet(codingLevel || ''));
    dispatch(ageSet(age || ''));
  },[dispatch, age, areaOfInterests, topics, codingLevel]);

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      keycloak.loadUserInfo().then((profile: any) => {
        if(!nicknameSet) {
          setNickname(profile.nickname);
          setNicknameSet(true);
        }
      });
    }
  });

  const onClick = async () => {
    const updatedSettings = await updatingUserSettings(userSettings, userInterests, nickname);
      if (updatedSettings) {
        dispatch(saveUserSettings(updatedSettings));
        if(userInterests.areaOfInterests.length >= 3 && userInterests.topics.length >= 3) {
          setSelectThreeBanner(false);
          setProfileUpdateBanner(true);
        } else {
          setProfileUpdateBanner(false);
          setSelectThreeBanner(true);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
  };

  return (
    <div className={styles.Profile}>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs} />
      </div>
      {profileUpdateBanner &&
        <div className={styles.notificationBanner}>
          <NotificationBanner type={NotificationBannerType.INFO} message={intl.formatMessage({id: "Profile.update"})} />
        </div>
      }
      {selectThreeBanner &&
        <div className={styles.notificationBanner}>
          <NotificationBanner type={NotificationBannerType.INFO} message={intl.formatMessage({id:selectThreeInterestsOrTopics(userInterests)})} />
        </div>
      }
        <div className={styles.userInfo}>
          <div className={styles.userAvatar}>
            {uploadedImage &&
              <img src={uploadedImage}
                   alt={intl.formatMessage({ id: "Profile.avatarAlt" })} />
            }
            {!uploadedImage &&
              <AccountCircleIcon style={{width: "182px", height: "182px", marginLeft: "-10px", marginTop: "-10px"}}/>
            }
            <div className={styles.cameraIcon}>
              <label className={styles.inputFieldWrapper}>
                <input className={styles.inputField} type={"file"} onChange={e => onUploadImg(e)} />
                <PhotoCameraIcon/>
              </label>
            </div>
          </div>
          <div className={styles.textInput}>
            <TextInput label={intl.formatMessage({id: "Profile.nickname"})}
                       value={nickname}
                       onChange={(e) => setNickname(e.target.value)}/>
          </div>
        </div>
      <div className={styles.headers}>
        <h2>{intl.formatMessage({ id: "Profile.personalization"})}</h2>
        <p className={styles.description}>{intl.formatMessage({id: "Profile.description"})}</p>
        <div className={styles.pageContent}>
          <SelectableTilesCategory profilePage={true} />
        </div>
        <h2>Newsletter</h2>
        <p className={styles.description}>{intl.formatMessage({id: "Profile.Newsletter.subscribe"})}</p>
        <p className={styles.description}>{intl.formatMessage({id: "Profile.Newsletter.unsubscribe"})}</p>
      </div>
      <div className={styles.saveButton}>
        <Button label={intl.formatMessage({id: "Profile.save"})} onClick={onClick} />
      </div>

    </div>
  );
};

export default ProfilePage;
