import React, {FunctionComponent} from "react";
import {DropDownContainer} from "@dis/colors-components";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from "./MenuDropdown.module.scss";

export interface OptionProps {
  name: string;
  onClick: (e: React.MouseEvent) => void;
}

type Point = {
    x: number,
    y: number
}

export interface MenuDropdownProps {
  options: OptionProps[];
  relativePosition?: Point;
  zIndex?: number;
}

export const MenuDropdown: FunctionComponent<MenuDropdownProps> = ({
                                                                     options,
                                                                     relativePosition,
                                                                     zIndex = 0
                                                                  }) => {

    return (
        <div className={styles.menuDropdown}>
            <DropDownContainer relativePosition={relativePosition} zIndex={zIndex} icon = {<ExpandMoreIcon fontSize={"large"}/>}>
                {options.map((option: OptionProps) =>
                    <button
                        key={option.name}
                        className={styles.dropdownButton}
                        aria-label={option.name}
                        onClick={option.onClick}
                    >
                        <div key={option.name} className={styles.option}>
                            <span>{option.name}</span>
                        </div>
                    </button>
                )}
            </DropDownContainer>
        </div>
    );
};
