import React, {FunctionComponent} from 'react';
import styles from './NewsletterPage.module.scss';
import {BreadcrumbGroup, BreadcrumbTabProps, Button, ButtonType} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

const NewsletterPage: FunctionComponent = () => {

    const intl = useIntl();
    const navigate = useNavigate();

    const breadcrumbTabs: Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'Header.newsletter'})}
    ];
    return (
        <>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
            </div>
            <div className={styles.newsletter}>
                <iframe
                    title={"newsletter"}
                    src="https://dd932048.sibforms.com/serve/MUIFAHoPobmjOp6UTE6_basFvX7UUyMnRZ9_Gi4VvhxxbBgIuW86SHh4_XHm8zTzH-o4mBK6qA4ba1Oo3ITt72RqL5IGceObZrpy3b2vU4KnmrLdAivgwIi0pcJjdkLi-awekQp3ZMxX0EjI8HJnKzj_RTG2vwRT_I2i7xrj2yiyaZql0fEYG579CuUY94o22gtZH5xdAJNogOAC"
                    seamless={true}
                    allowTransparency={true}
                    // allowFullScreen={false}
                />
            </div>
            <div className={styles.button}>
                <Button label={intl.formatMessage({id: "Newsletter.GetStartedNowButton"})}
                        onClick={() => navigate(ROUTES.DASHBOARD.BASE)}
                        type={ButtonType.PRIMARY}
                />
            </div>
        </>
    );
}

export default NewsletterPage;
