import React, { FunctionComponent } from "react";
import styles from "./ContentInformationBlock.module.scss";
import { Icon, Tag, TagType } from "@dis/ycg-components";
import { AgeGroupDisplay } from "../../components/AgeGroupDisplay/AgeGroupDisplay";
import { DifficultyDisplay } from "../../components/DifficultyDisplay/DifficultyDisplay";
import { useIntl } from "react-intl";


export interface AgeGroups {
    [ageGroup: string]: boolean;
}

interface TagData {
    name: string;
    type: TagType;
}

export interface ContentInformationBlockProps {
    duration?: number;
    ageGroups: AgeGroups;
    difficulty: number;
    author: string;
    tags?: TagData[];
    location?: string;
    moment?: string;
    workshop?: boolean;
}


export const ContentInformationBlock: FunctionComponent<ContentInformationBlockProps> = ({
                                                                                             duration,
                                                                                             ageGroups,
                                                                                             difficulty,
                                                                                             author,
                                                                                             tags,
                                                                                             location,
                                                                                             moment,
                                                                                             workshop
                                                                                         }) => {

    const intl = useIntl();

    return (
        <div className={styles.ContentInformationBlock}>
            <div className={styles.content}>
                <div className={styles.table}>
                    {!workshop && duration &&
                      <div className={styles.row}>
                        <div className={styles.cellLeft}>{intl.formatMessage({ id: "ContentInformation.duration" })}</div>
                        <div className={styles.cell}>{duration} Min.</div>
                      </div>
                    }
                    {workshop && location && location.length > 0 &&
                      <div className={styles.row}>
                        <div className={styles.cellLeft}>{intl.formatMessage({ id: "ContentInformation.location" })}</div>
                        <div className={styles.cell}>{location}</div>
                      </div>
                    }
                    {workshop && moment && moment.length > 0 &&
                      <div className={styles.row}>
                        <div className={styles.cellLeft}>{intl.formatMessage({ id: "ContentInformation.moment" })}</div>
                        <div className={styles.cell}>{moment}</div>
                      </div>
                    }
                    <div className={styles.row}>
                        <div className={styles.cellLeft}>{intl.formatMessage({ id: "ContentInformation.age" })}</div>
                        <div className={styles.cell}><AgeGroupDisplay ageGroups={ageGroups} /></div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cellLeft}>{intl.formatMessage({ id: "Course.PriorKnowledge" })}</div>
                        <div className={styles.cell}><DifficultyDisplay difficulty={difficulty} size={"15px"} /></div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.authorCell}>{intl.formatMessage({ id: "ContentInformation.author" })}</div>
                      <div className={styles.authorCell}>
                        <div className={styles.author}>
                          <div className={styles.icon}>
                            <Icon name={"copyright"} size={"17px"}/>
                          </div>
                          <div className={styles.name}>
                            {author}
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                {tags && tags.length > 0 &&
                  <div className={styles.tags}>
                      {
                          tags.map((tag) => {
                              return <div className={styles.tag} key={tag.name}>
                                <Tag closeAble={false} label={tag.name} type={tag.type} />
                              </div>;
                          })
                      }
                  </div>
                }
            </div>
        </div>
    );
};



