import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './InitiativePage.module.scss';
import { loadJsonFile } from "../../utils/helper";
import {useSelector} from 'react-redux';
import {selectLanguage} from '../../redux/languageSlice';
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {BreadcrumbGroup, BreadcrumbTabProps} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";

const INITIATIVE_BREAKPOINT = 800;

const InitiativePage: FunctionComponent = () => {

    interface PageData {
        title: string,
        paragraphs: Array<string>,
        image: string,
        imageAlt: string
    }

    const selectedLanguage = useSelector(selectLanguage);
    const [pageContent, setPageContent] = useState<PageData>();
    const { mediaWidth } = useSelector(selectMediaQueries);

    const intl = useIntl();
    const navigate = useNavigate();

    useEffect(() => {
        async function loadData() {
            let data;
            switch (selectedLanguage) {
                case "DEU":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/initiative/page-content.json`);
                    break;
                case "ENG":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/initiative/page-content-eng.json`);
                    break;
                default:
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/initiative/page-content.json`);
                    break;
            }
            setPageContent(await data.json());
        }

        loadData().catch(console.error);
    }, [selectedLanguage]);

    const breadcrumbTabs:Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'Header.initiative'})}
    ];

    return (
        <div className={styles.InitiativePage}>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
            </div>
            <h1>{pageContent && pageContent?.title}</h1>
            <div className={styles.content}>
                <div className={styles.left}>
                    {pageContent && pageContent.paragraphs &&
                      (
                        mediaWidth <= INITIATIVE_BREAKPOINT ? <p>{pageContent.paragraphs[0]}</p>
                          : pageContent.paragraphs.map((p) => <p key={p}>{p}</p>)
                      )
                    }
                </div>
                <div className={styles.right}>
                    <img src={pageContent?.image} alt={pageContent?.imageAlt}/>
                </div>
                {mediaWidth <= INITIATIVE_BREAKPOINT &&
                    <div className={styles.left}>
                        {pageContent && pageContent.paragraphs &&
                          <p>{pageContent.paragraphs[1]}</p>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default InitiativePage;
