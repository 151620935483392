import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ShallowEntity } from "../utils/types/api";
import { Competence } from "@dis/types";
import { fetchLessons } from "./fetchActions";
import { RootState } from "./store";

const sliceName = "competences";

/**
 * The Entity Adapter managing {@link Competence} entities.
 *
 * Entities are sorted by their `name`.
 */
export const competencesAdapter = createEntityAdapter<
  ShallowEntity<Competence>
>({
  sortComparer: (a, b) => (a.name ?? "")?.localeCompare(b.name ?? "")
});

/**
 * The Redux slice handling actions on {@link Competence} entities.
 */
const competenceSlice = createSlice({
  name: sliceName,
  initialState: competencesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(fetchLessons.fulfilled, (state, action) => {
      if (action.payload.entities.Competence) {
        competencesAdapter.upsertMany(
          state,
          action.payload.entities.Competence
        );
      }
    })
});

/**
 * To be used by the Redux storage to set up the {@link Competence} slice.
 */
export const competencesReducer = competenceSlice.reducer;

/**
 * A selector dictionary giving access to
 *
 * - `selectAllCompetences`: all stored Competence entities
 */
export const {
  selectAll: selectAllCompetences,
  selectById: selectCompetences
} = competencesAdapter.getSelectors<RootState>((state) => state.competences);

export const selectCompetencesFromIds = (competenceIds: string[]) => (
  state: RootState
) => {
  function getCompetence(competenceId: string) {
    return competencesAdapter
      .getSelectors()
      .selectById(state.competences, competenceId);
  }

  const allCompetences: (ShallowEntity<Competence> | undefined)[] = [];
  competenceIds.forEach((competenceId) => {
    let nextCompetence = getCompetence(competenceId);
    allCompetences.push(nextCompetence);

    while (nextCompetence?.parent) {
      nextCompetence = getCompetence(nextCompetence.parent);
      allCompetences.push(nextCompetence);
    }
  });

  return allCompetences.reverse();
};
