import React, {FunctionComponent} from "react";
import styles from './ConsentCheck.module.scss'
import {Checkbox} from "@dis/colors-components";
import { useIntl } from "react-intl";

export interface ConsentCheckProps {
    consentChecked: boolean
    handleChange: () => void
}
export const ConsentCheck : FunctionComponent<ConsentCheckProps> = ({consentChecked, handleChange}) => {
    const intl = useIntl();

    return(
        <div className={styles.consent}>
            <div className={styles.consentText}>
                {intl.formatMessage({ id: "PrivacyConsent.title" }) + " *"}
            </div>
            <Checkbox id={"consentCheck"}
                      checked={consentChecked}
                      onChange={handleChange}
                      label={intl.formatMessage({ id: "PrivacyConsent.text" })}/>
        </div>
    );
};
