import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {RecommendationOrderMapping, recommenderApi} from "../api/recommenderApi";


const sliceName = "recommendations";

/**
 * The interface defines the structure of recommendations state.
 */
interface RecommendationState {
    recommendations: RecommendationOrderMapping | null
}

/**
 * The initial state of the recommendations state
 */
const initialState: RecommendationState = {
    'recommendations': null
}

export const fetchRecommendations = createAsyncThunk<RecommendationOrderMapping, { userId: string, device?: string }>("fetch/" + sliceName, async ({
                                                                                                                                                       userId,
                                                                                                                                                       device
                                                                                                                                                   }) => {
    return recommenderApi.getRecommendations(userId, device);
})

/**
 * The Redux slice handling actions on the recommendation slice.
 */
const recommendationSlice = createSlice({
    "name": sliceName,
    "initialState": initialState,
    "reducers": {},
    "extraReducers": (builder => builder
        .addCase(fetchRecommendations.fulfilled, (state, action) => {
            state.recommendations = action.payload;
        })
    )
});

/**
 * To be used by the Redux storage to set up the recommendation slice.
 */
export const recommendationReducer = recommendationSlice.reducer;

/**
 * Selector to get the recommendations out of the recommendation state
 * @param state
 */
export const selectRecommendations = (state: RootState) => state.recommendations.recommendations;
