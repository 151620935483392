import {FormatConfiguration} from "@dis/types";
import {formatServiceUrl} from "../api/util";
import {ShallowEntity} from "./types/api";


type ShallowFormatConfiguration = FormatConfiguration | ShallowEntity<FormatConfiguration>;

export function getFormatBaseUrl(formatConfiguration: ShallowFormatConfiguration | null) {
    return `${formatServiceUrl}/formats/${formatConfiguration?.formatIdentifier}/run/`;
}

function getOwnFormatUrl(formatConfiguration: ShallowFormatConfiguration | null) {
    return `${getFormatBaseUrl(formatConfiguration)}?formatId=${formatConfiguration?.id}`;
}

function isH5Pformat(formatConfiguration: ShallowFormatConfiguration | null) {
    return formatConfiguration?.formatIdentifier === "H5P";
}

function getH5PformatUrl(formatConfiguration: ShallowFormatConfiguration | null) {
    return formatConfiguration?.previewPictureURL;
}

export const getFormatPreviewSource = (formatConfiguration: ShallowFormatConfiguration | null) => {
    if (isH5Pformat(formatConfiguration)) {
        return getH5PformatUrl(formatConfiguration);
    } else {
        return getOwnFormatUrl(formatConfiguration);
    }
};
