import React, {FunctionComponent} from "react";
import styles from "./Ratings.module.scss";
import {Icon} from "@dis/ycg-components";

export interface RatingsProps {
    rating: number;
    reviews?: number;
}

export const Ratings: FunctionComponent<RatingsProps> = ({
                                                             rating,
                                                             reviews,
                                                         }) => {

    return (
        <div className={styles.ratingsBanner}>
            <div className={styles.stars}>
                {rating >= 1 &&
                    <Icon name={"star"} size={"15px"}/>
                }
                {rating < 1 &&
                    <Icon name={"star_border"} size={"15px"}/>
                }
                {rating >= 2 &&
                    <Icon name={"star"} size={"15px"}/>
                }
                {rating < 2 &&
                    <Icon name={"star_border"} size={"15px"}/>
                }
                <div className={styles.star}>
                    {rating >= 3 &&
                        <Icon name={"star"} size={"15px"}/>
                    }
                    {rating < 3 &&
                        <Icon name={"star_border"} size={"15px"}/>
                    }
                </div>
                <div className={styles.star}>
                    {rating >= 4 &&
                        <Icon name={"star"} size={"15px"}/>
                    }
                    {rating < 4 &&
                        <Icon name={"star_border"} size={"15px"}/>
                    }
                </div>
                <div className={styles.star}>
                    {rating >= 5 &&
                        <Icon name={"star"} size={"15px"}/>
                    }
                    {rating < 5 &&
                        <Icon name={"star_border"} size={"15px"}/>
                    }
                </div>
            </div>
            { reviews &&
            <div className={styles.reviews}>
                <div className={styles.text}>
                    {reviews}
                </div>
            </div>
            }
        </div>
    );
};
