import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";

const sliceName = "mediaQueries";

export enum DeviceNames {
  computer = "computer",
  tablet = "tablet",
  smartphone = "smartphone"
}

export interface MediaQueriesState {
  mediaWidth: number;
  device: DeviceNames
}

/*
* Initial state of mediaQueries state
* */
const initialState: MediaQueriesState = {
  mediaWidth: 0,
  device: DeviceNames.computer
}
/**
 * Redux Slice handling actions on the mediaQueries slice
 */
const MediaQueriesSlice = createSlice({
  'name': sliceName,
  'initialState': initialState,
  'reducers': {
    MediaWidthSet(state: MediaQueriesState, action: PayloadAction<number>) {
      state.mediaWidth = action.payload
    },
    DeviceSet(state: MediaQueriesState, action: PayloadAction<DeviceNames>) {
      state.device = action.payload
    }
  }
})
/**
 * To be used by the Redux storage to set up the mediaQueries slice
 */
export const mediaQueriesReducer = MediaQueriesSlice.reducer;

export const {
  MediaWidthSet,
  DeviceSet
} = MediaQueriesSlice.actions

/**
 *
 * @param state
 * */
export const selectMediaQueries = (state: RootState) => state.mediaQueries