import React, { FunctionComponent } from "react";
import styles from "./HandoutPage.module.scss";
import { BreadcrumbGroup, BreadcrumbTabProps, Button, ButtonType, IconPosition } from "@dis/colors-components";
import { ROUTES } from "../../utils/routes";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ShoppingCartOutlined } from "@mui/icons-material";

const HandoutPage: FunctionComponent = () => {

  const intl = useIntl();
  const navigate = useNavigate();

  const breadcrumbTabs:Array<BreadcrumbTabProps> = [
    {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
    {label: intl.formatMessage({id: 'Header.handout'})}
  ];

  return (
    <>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
      </div>
      <div className={styles.button}>
        <Button label={intl.formatMessage({ id: "Handout.OrderButton" })}
                onClick={() => navigate(ROUTES.HANDOUT.ORDER)}
                type={ButtonType.PRIMARY}
                icon={<ShoppingCartOutlined/>}
                iconPosition={IconPosition.LEFT}
        />
      </div>
      <div className={styles.handout}>
        <iframe
          title={"handout"}
          src="https://online.fliphtml5.com/sbfzq/tfhf/"
          seamless={true}
          allowTransparency={true}
          allowFullScreen={true}
        />
      </div>
    </>
  )
}

export default HandoutPage;
