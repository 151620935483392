import {Lesson} from "@dis/types";
import {DeJsoggedEntity} from "../utils/types/api";
import {JsogService} from "jsog-typescript";
import {DateTimeDeserializer} from "../utils/JSONUtilities";
import {contentCreationServiceUrl, getRequest} from "./util";

const jsogService = new JsogService();

const getLessons = async () => {
  const response = await getRequest(`${contentCreationServiceUrl}/lessons/content-portal`);
  // Now we deserialize again to have `Date` objects instead of `string`s for all matching properties (createdAt, date, …).
  const revivedResponse = DateTimeDeserializer.reviveObject(await response.json());
  return jsogService.deserialize<DeJsoggedEntity<Lesson>>(revivedResponse);
};

export const lessonApi = {
  getLessons
};
