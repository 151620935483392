import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './MediaPage.module.scss';
import {loadJsonFile} from "../../utils/helper";
import {PressEntry} from "../../components/PressEntry/PressEntry";
import {useSelector} from 'react-redux';
import {selectLanguage} from '../../redux/languageSlice';
import {useNavigate} from 'react-router-dom';
import {useIntl} from "react-intl";
import {BreadcrumbGroup, BreadcrumbTabProps} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";

const MediaPage: FunctionComponent = () => {

    interface Post {
        title: string,
        date: string,
        readTime: string,
        description: string,
        link: string,
    }

    interface PageData {
        title: string,
        posts: Post[]
    }

    let selectedLanguage = useSelector(selectLanguage);
    const [pageContent, setPageContent] = useState<PageData>();

    const navigate = useNavigate();
    const intl = useIntl();

    useEffect(() => {
        async function loadData() {
            let data;
            switch (selectedLanguage) {
                case "DEU":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/media/page-content.json`);
                    break;
                case "ENG":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/media/page-content-eng.json`);
                    break;
                default:
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/media/page-content.json`);
                    break;
            }
            setPageContent(await data.json());
        }

        loadData().catch(console.error);
    }, [selectedLanguage]);

    const breadcrumbTabs:Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'Header.media'})}
    ];

    return (
        <div className={styles.MediaPage}>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
            </div>
            <div className={styles.header}>
                <h1>{pageContent && pageContent?.title}</h1>
            </div>
            <div className={styles.content}>
                <div className={styles.postList}>
                    {
                        pageContent && pageContent.posts && pageContent.posts.length > 0 && pageContent.posts.map((post) => {
                            return (
                                <PressEntry
                                    key={post.date+post.title}
                                    title={post.title}
                                    onClick={()=> post.link && window.open(post.link, "_blank", "noreferrer, noopener")}
                                    date={post.date}
                                    readTime={post.readTime}
                                    description={post.description}
                                />
                        );
                    })
                }
                </div>
            </div>
        </div>
    );
}

export default MediaPage;
