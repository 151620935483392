import React, {FunctionComponent} from 'react';
import styles from './BlogEntry.module.scss';
import {IconWithLabel} from '@dis/colors-components';
import {Event, Schedule} from '@mui/icons-material';


export interface BlogEntryProps {
    image: string;
    title: string;
    description: string;
    onClick?: () => void;
    date?: string;
    readTime?: string;
}


export const BlogEntry: FunctionComponent<BlogEntryProps> = ({image, title, description, date, readTime, onClick}) => {


    return (
        <div className={styles.BlogEntry} onClick={onClick}>
            <div className={styles.imageFrame}>
                <img src={image} alt={"blog"}/>
            </div>
            <div className={styles.bottom}>
                <div className={styles.content}>
                    <div className={styles.blog}>
                        Blog
                    </div>
                    <div className={styles.title}>
                        {title}
                    </div>
                    <div className={styles.details}>
                        {date &&
                          <IconWithLabel label={date}>
                              <Event fontSize={"small"}/>
                          </IconWithLabel>
                        }
                        {readTime &&
                          <IconWithLabel label={readTime}>
                              <Schedule fontSize={"small"}/>
                          </IconWithLabel>
                        }
                    </div>
                    <div className={styles.description}>
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

