import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {LearningElement} from "@dis/types";
import {RootState} from "./store";
import {ShallowEntity} from "../utils/types/api";
import {fetchLessons} from "./fetchActions";


const sliceName = 'learningElement';

/**
 * The Entity Adapter managing {@link LearningElement} entities.
 *
 * Entities are sorted by their `name`.
 */
export const learningElementAdapter = createEntityAdapter<ShallowEntity<LearningElement>>({
  'sortComparer': (a, b) => a.name.localeCompare(b.name)
});

/**
 * The Redux slice handling actions on {@link LearningElement} entities.
 */
const learningElementSlice = createSlice({
  'name': sliceName,
  'initialState': learningElementAdapter.getInitialState(),
  'reducers': {},
  'extraReducers': (builder) => {
    builder.addCase(fetchLessons.fulfilled, (state, action) => {
      if (action.payload.entities.LearningElement) {
        learningElementAdapter.upsertMany(state, action.payload.entities.LearningElement);
      }
    })
  }
});

/**
 * To be used by the Redux storage to set up the {@link LearningElement} slice.
 */
export const learningElementReducer = learningElementSlice.reducer;

/**
 * A selector dictionary giving access to
 *
 * - `selectAllLearningElements`: all stored LearningElement entities
 */
export const {
  'selectAll': selectAllLearningElements,
  'selectById': selectLearningElement
} = learningElementAdapter.getSelectors<RootState>((state) => state.learningElements);
