import {postRequest, recommenderUrl} from "./util";


type CourseTitle = string;

interface RecommenderResponse {
    Empfehlung: {[order: string]: {Kurs: string, score: number}},
    Kategorien: {Kurse:  {[subject: string]: {titel: {[order: string]: CourseTitle}, Kategorienprioritaet: number}}}
}

export interface RecommendationOrderMapping {
    [subject: string] : {courseOrder: {[title: string]: number}, subjectOrder: number}
}

export const USER_RECOMMENDATION = "USER_RECOMMENDATION";

const getRecommendations = async (userId: string, endDevice?: string) => {
    let requestUrl = `${recommenderUrl}/rec/${userId}`;
    if (endDevice) {
        requestUrl += `?enddevice=${endDevice}`;
    }
    const response = await postRequest(requestUrl);
    const result = await response.json() as RecommenderResponse;
    const recommendationOrderMapping: RecommendationOrderMapping = {};

    // remap orderings for group user recommendations
    const userRecommendationsToOrderingMapping = result.Empfehlung;
    const userRecommendationOrders = Object.keys(userRecommendationsToOrderingMapping);
    userRecommendationOrders.forEach((order) => {
        if (!recommendationOrderMapping[USER_RECOMMENDATION]) {
            recommendationOrderMapping[USER_RECOMMENDATION] = {courseOrder: {}, subjectOrder: -1};
        }
        recommendationOrderMapping[USER_RECOMMENDATION].courseOrder[userRecommendationsToOrderingMapping[order].Kurs] = Number.parseInt(order);
    });

    // remap orderings for courses by subjects
    const subjectToOrderingMapping = result.Kategorien.Kurse || {};
    const subjects = Object.keys(subjectToOrderingMapping);
    subjects.forEach((subject) => {
        const orderToCourseTitleMapping = subjectToOrderingMapping[subject].titel;
        const orders = Object.keys(orderToCourseTitleMapping);
        orders.forEach((order) => {
            if (!recommendationOrderMapping[subject]) {
                recommendationOrderMapping[subject] = {
                    courseOrder: {},
                    /* We have to transform the priority into an order:
                       default is the smaller the order the higher it is
                       the category priority is the other way around the bigger it is the higher it is
                       so we swap simply the sign: */
                    subjectOrder: -subjectToOrderingMapping[subject].Kategorienprioritaet
                };
            }
            recommendationOrderMapping[subject].courseOrder[orderToCourseTitleMapping[order]] = Number.parseInt(order);
        });
    });
    return recommendationOrderMapping;
}

export const recommenderApi = {
    getRecommendations
};