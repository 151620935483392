import {getRequest, patchRequest, userDataURL} from "./util";
import {computePatchOperations} from "../utils/helper";
import {EyesColorCustomizationsIds} from "../components/Mia/MiaCustomization/Customizations/EyesColorCustomizations";
import {SkinToneCustomizationsIds} from "../components/Mia/MiaCustomization/Customizations/SkinToneCustomizations";
import {HairColorCustomizationsIds} from "../components/Mia/MiaCustomization/Customizations/HairColorCustomizations";
import {JacketCustomizationsIds} from "../components/Mia/MiaCustomization/Customizations/JacketCustomizations";
import {PantsCustomizationsIds} from "../components/Mia/MiaCustomization/Customizations/PantsCustomizations";
import {AccessoriesCustomizationsIds} from "../components/Mia/MiaCustomization/Customizations/AccessoriesCustomizations";


export interface MiaSettings {
    customEyes?: EyesColorCustomizationsIds;
    customSkin?: SkinToneCustomizationsIds;
    customHair?: HairColorCustomizationsIds;
    customJacket?: JacketCustomizationsIds;
    customPants?: PantsCustomizationsIds;
    customAccessories?: AccessoriesCustomizationsIds;
}

export interface CourseSettings {
    startedCourses: Array<string>;
    finishedCourses: Array<string>;
}

export interface PersonalizationPageSettings {
    areaOfInterests: Array<string>;
    age: string;
    codingLevel: string;
    topics: Array<string>;
}

export interface UserSettings {
    miaSettings?: MiaSettings,
    courseSettings?: CourseSettings,
    personalizationPageSettings?: PersonalizationPageSettings,
    userName?: string
}

const getUserSettings = async (): Promise<UserSettings> => {
    const fetchData = await getRequest(`${userDataURL}/user-data`);

    if (fetchData.ok) {
        return fetchData.json();
    } else {
        return {};
    }
};

const patchUserSettings = async (originalSettings: UserSettings, updatedSettings: UserSettings): Promise<UserSettings | void> => {
    const updates = computePatchOperations<UserSettings>(originalSettings, updatedSettings);
    const request = await patchRequest(`${userDataURL}/user-data`, updates);
    if (request.ok) {
        return request.json();
    }
}

export const userApi = {
    getUserSettings,
    patchUserSettings,
};
