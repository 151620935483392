import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { BreadcrumbGroup, BreadcrumbTabProps, Carousel, Modal, Button, ButtonType } from "@dis/colors-components";
import { ROUTES } from "../../utils/routes";
import styles from "./LearningOffers.module.scss";
import { ContentTileWrapper } from "../../components/ContentTile/ContentTileWrapper";
import { useSelector, useDispatch } from "react-redux";
import { saveUserSettings, selectUserSettings } from "../../redux/userSettingsSlice";
import { selectExternalWorkshop, selectLessonsByName } from "../../redux/lessonsSlice";
import { LessonWithImage, ShallowEntity } from "../../utils/types/api";
import jsonpatch from "fast-json-patch";
import { userApi } from "../../api/userApi";
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";
import {MEDIA_TABLET_WIDTH, MEDIA_MOBILE_WIDTH, workshop} from "../../utils/helper";

const LearningOffers: FunctionComponent = () => {

  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { mediaWidth } = useSelector(selectMediaQueries);

  const userSettings = useSelector(selectUserSettings);
  const startedCourses: ShallowEntity<LessonWithImage>[] = useSelector(selectLessonsByName(userSettings.courseSettings?.startedCourses));
  const finishedCourses: ShallowEntity<LessonWithImage>[] = useSelector(selectLessonsByName(userSettings.courseSettings?.finishedCourses));
  const externalWorkshop = useSelector(selectExternalWorkshop());

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState<boolean>(false);
  const [showUpdatedTooltip, setShowUpdatedTooltip] = useState<boolean>(false);
  const [removeCourses, setRemoveCourses] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [scrollSpeed, setScrollSpeed] = useState<number>(0.75);

  useEffect(() => {
    //set scroll speed
    if(mediaWidth <= MEDIA_MOBILE_WIDTH) setScrollSpeed(1);
    else setScrollSpeed(0.75);
  },[scrollSpeed, mediaWidth])

  const updateRemoveCourses = (removedCourses: Array<string>) => {
    setRemoveCourses(removedCourses);
    setDisabled(removedCourses.length <= 0);
  };

  const onRemoveButtonClick = () => {
    setIsOpen(true);
  };

  const onMenuIconClick = () => {
    updateRemoveCourses([]);
    if(showUpdatedTooltip) {
      setShowInfoTooltip(false);
      setShowUpdatedTooltip(false);
    } else {
      setShowInfoTooltip(!showInfoTooltip);
    }
  };

  const onModalClose = () => {
    setIsOpen(false);
    updateRemoveCourses([]);
  };

  const onUpdateButtonClick = async () => {
    if(removeCourses.length > 0) {
      const updatedUserSettings = jsonpatch.deepClone(userSettings);
      updatedUserSettings.courseSettings.startedCourses = updatedUserSettings.courseSettings.startedCourses.filter((lesson:string)=>
        !removeCourses.includes(lesson));
      const updatedSettings = await userApi.patchUserSettings(userSettings, updatedUserSettings);
      if(updatedSettings) {
        dispatch(saveUserSettings(updatedSettings));
        setShowInfoTooltip(false);
        setShowUpdatedTooltip(true);
        setIsOpen(false);
        updateRemoveCourses([]);
      }
    }
  };

  const onClick = (lessonId:string | undefined, lessonName: string | undefined) => {
    if(showInfoTooltip) {
      if(lessonName) {
        if(removeCourses.includes(lessonName)) {
          const filteredCourse = removeCourses.filter((course) => course !== lessonName);
          updateRemoveCourses(filteredCourse);
        } else {
          updateRemoveCourses([...removeCourses, lessonName]);
        }
      }
    } else {
      lessonId && navigate(`${ROUTES.CONTENT_VIEWER.BASE}?content-id=${lessonId}`);
    }
  };

  function fontSize () {
    if(mediaWidth <= MEDIA_MOBILE_WIDTH) {
      return "24px";
    } else if(mediaWidth > MEDIA_MOBILE_WIDTH && mediaWidth <= MEDIA_TABLET_WIDTH) {
      return "28px";
    } else {
      return ""
    }
  }

  const headerStyle = {
    fontSize: fontSize()
  }

  const windowStyle = mediaWidth <= MEDIA_MOBILE_WIDTH ? {
    padding: "10px 20px 20px",
    margin: "0 10px"
  } : {
    margin: "0 10px"
  }

  const breadcrumbTabs: Array<BreadcrumbTabProps> = [
    { label: intl.formatMessage({ id: "Dashboard.name" }), onClick: () => navigate(ROUTES.DASHBOARD.BASE) },
    { label: intl.formatMessage({ id: "LearningOffers.name" }) }
  ];

  return (
    <div className={styles.LearningOffers}>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs} />
      </div>
      <div className={styles.courses}>
        { startedCourses.length <= 0 && finishedCourses.length <= 0 &&
          <div className={styles.empty}>
            {intl.formatMessage({id: "LearningOffers.empty"})}
          </div>
        }
        <div className={styles.carousels}>
          {startedCourses && startedCourses.length > 0 &&
              <div>
                <Modal styleWindow={windowStyle} isOpen={isOpen} backgroundColorWindow={styles.modal}>
                  <div className={styles.modal}>
                    <div className={styles.modalHeaders}>
                      <h4>{intl.formatMessage({id: "LearningOffers.modalTitle"})}</h4>
                      <p>{intl.formatMessage({id: "LearningOffers.modalText"})}</p>
                    </div>
                    <div className={styles.modalButtons}>
                      <Button type={ButtonType.SECONDARY}
                              width={"143px"}
                              height={"48px"}
                              label={intl.formatMessage({id: "LearningOffers.abort"})}
                              onClick={onModalClose}/>
                      <Button type={ButtonType.PRIMARY}
                              width={"143px"}
                              height={"48px"}
                              label={intl.formatMessage({id: "LearningOffers.remove"})}
                              onClick={onUpdateButtonClick}/>
                    </div>
                  </div>
                </Modal>
                <Carousel startedCourse={true}
                          hoverTooltipText={intl.formatMessage({id: "LearningOffers.hoverTooltip"})}
                          infoTooltipText={intl.formatMessage({id: "LearningOffers.infoTooltip"})}
                          updatedTooltipText={intl.formatMessage({id: "LearningOffers.updatedTooltip"})}
                          header={intl.formatMessage({id: "LearningOffers.continue"})}
                          buttonLabel={intl.formatMessage({id: "LearningOffers.remove"})}
                          onButtonClick={onRemoveButtonClick}
                          onMenuIconClick={onMenuIconClick}
                          showInfoTooltip={showInfoTooltip}
                          showUpdatedTooltip={showUpdatedTooltip}
                          disableButton={disabled}
                          scrollSpeed={scrollSpeed}
                          headerStyle={headerStyle}
                >
                  <div className={styles.lessonWrapper}>
                    {
                      startedCourses.map((lesson) =>
                          <ContentTileWrapper
                              key={lesson.name}
                              lessonId={lesson.id || ""}
                              onClick={() => onClick(lesson.id, lesson.name)}
                              selected={removeCourses.includes(lesson.name)}
                              workshop={workshop(lesson.id, externalWorkshop)}
                          />)
                    }
                  </div>
                </Carousel>
              </div>
          }
          {finishedCourses && finishedCourses.length > 0 &&
            <Carousel headerStyle={headerStyle} scrollSpeed={scrollSpeed} header={intl.formatMessage({ id: "LearningOffers.finished" })}>
              <div className={styles.lessonWrapper}>
                {
                  finishedCourses.map((lesson) =>
                    <ContentTileWrapper
                      key={lesson.name}
                      lessonId={lesson.id || ""}
                      onClick={() => navigate(`${ROUTES.CONTENT_VIEWER.BASE}?content-id=${lesson.id}`)}
                      finishedCourse={true}
                      workshop={workshop(lesson.id, externalWorkshop)}
                    />)
                }
              </div>
            </Carousel>
          }
        </div>
      </div>
    </div>
  );
};

export default LearningOffers;
