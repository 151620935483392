import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { useKeycloak } from "@react-keycloak/web";

const RegistrationPage: FunctionComponent = () => {

  const navigate = useNavigate();
  const {keycloak, initialized} = useKeycloak();
  const loggedIn = initialized && keycloak.authenticated;
  const src = keycloak.createRegisterUrl();

  useEffect(() => {
    if(loggedIn) {
      navigate(ROUTES.REGISTER.PERSONALIZATION)
    } else {
      window.location.assign(src);
    }
  },[loggedIn, navigate, src])

  return (<></>)
};

export default RegistrationPage;
