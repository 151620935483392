import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import { AgeGroups } from "../components/AgeGroupDisplay/AgeGroupDisplay";

const sliceName = 'internalOriginalContent';

export interface InternalOriginalProps {
  image: string;
  description: string;
  name: string;
  route: string;
  age: AgeGroups;
  knowledge: number;
}

interface InternalOriginalContentState {
  internalOriginalLessons: InternalOriginalProps[];
}

/**
 * The initial state of the InternalOriginalContent state
 */
const initialState: InternalOriginalContentState = {
  'internalOriginalLessons': []
}

/**
 * The Redux slice handling actions on the InternalOriginalContent slice.
 */
const InternalOriginalContentSlice = createSlice({
  'name': sliceName,
  'initialState': initialState,
  'reducers': {
    // set the InternalOriginalContent action
    internalOriginalLessonsSet(state: InternalOriginalContentState, action: PayloadAction<Array<InternalOriginalProps>>) {
      state.internalOriginalLessons = action.payload;
    }
  }
})

/**
 * To be used by the Redux storage to set up the InternalOriginalContent slice.
 */
export const internalOriginalContentReducer = InternalOriginalContentSlice.reducer;

export const { internalOriginalLessonsSet } = InternalOriginalContentSlice.actions;

/**
 * Selector to get the lessons out of the InternalOriginalContent state
 * @param state
 */
export const selectInternalOriginalContent = (state: RootState) => state.internalOriginalContent.internalOriginalLessons;
