import React, {FunctionComponent} from 'react';
import styles from '../LegalNoticePage.module.scss';

export const LegalNoticePageEng: FunctionComponent = () => {
    return (
        <>
            <h1>Legal notice</h1>
            <p>
                Note: This legal notice (Impressum) is a requirement of the German Act for Telemedia Services
                (Telemediengesetz).
                This website is provided by Saarland University as a public corporation. The university is represented
                by its president, Manfred Schmitt.
            </p>
            <p>
                Regulatory authority: Saarland State Chancellery
            </p>
            <h4>Address</h4>
            <p>
                    Saarland University<br/>
                    Saarbrücken Campus<br/>
                    66123 Saarbrücken<br/>
                    Germany<br/>
                    <br/>
                    Phone: +49 681 302-0<br/>
                    Fax: +49 681 302-2500<br/>
                    E-Mail: <a href={'mailto:info@uni-saarland.de'}>info(at)uni-saarland.de</a>
            </p>
            <h4>Disclaimer</h4>
            <div className={styles.list}>
                <ol>
                    <li>
                        No assurances are made regarding pages that can be accessed from within the Saarland University
                        website.
                        Furthermore, no liability is accepted for the accuracy, completeness or usefulness of any of the
                        information contained therein.
                    </li>
                    <li>
                        The commercial use of this information, in particular its distribution or dissemination for
                        commercial gain, is prohibited.
                    </li>
                    <li>
                        We accept no liability for any possible violation of privacy or breach of law in other web
                        content linked to from this website.
                        Responsibility for the content of external web pages lies with the respective editor/publisher.
                        Web content from external providers
                        is examined with all due care and attention before any link is included on our site.
                        Nevertheless, no liability can be assumed for the
                        completeness and accuracy of information accessed via external links. Please notify us at:
                        wwwadm@uni-saarland.de about any web pages
                        that can be accessed from our site and whose content gives grounds for complaint.
                    </li>
                    <li>
                        Saarland University reserves the right to make changes or additions to the information provided
                        on this website without prior notice and
                        to remove such information when necessary. The information on the university's website does not
                        constitute and is certainly no substitute
                        for legal advice.
                    </li>
                    <li>
                        Links on other websites to the official university pages are permitted, provided that such links
                        are appropriately marked as such.
                    </li>
                    <li>
                        Should any official text, or excerpts therefrom, appear on our web pages, the original version
                        of said text shall take precedence over the
                        version published on this website.
                    </li>
                </ol>
            </div>
            <div>
                <p>
                    VAT Reg. No.: DE138117521<br/>
                    EORI No.: DE4305841
                </p>
                <p>
                    Insofar as this server contains journalistic or edited content in which textual or pictorial
                    material from periodical printed matter is reproduced
                    either entirely or in part, or in which texts are disseminated periodically, responsibility for such
                    content, within the meaning of Paragraph 18 (2)
                    of the German Interstate Broadcasting Agreement (MStV), lies with the president of Saarland
                    University, Campus, 66123 Saarbrücken, Germany.
                </p>
                <p>
                    The translations of the web pages are provided for information purposes only. In the event of any
                    discrepancies between the translation and the original
                    German page, the latter shall take precedence.
                </p>
            </div>
            <section>
                <p>
                    All images, videos and audios that are not self-created are covered by the Envato Elements license, if not specifically noted otherwise.
                </p>
            </section>
          <section>
            <p>
              Protection of minors<br/>
              Children and persons under the age of 18 should not submit any personal data to us without the consent of
              their parents or legal guardians.
            </p>
          </section>
        </>
    );
};
