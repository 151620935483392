import React, { FunctionComponent } from "react";
import { ContentTile } from "./ContentTile";
import { useSelector } from "react-redux";
import { selectFormatConfigurationFromLesson, selectLesson } from "../../redux/lessonsSlice";
import { RootState } from "../../redux/store";
import { getLessonImage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import {InternalOriginalProps} from "../../redux/internalOriginalContentSlice";

export interface ContentTileWrapperProps {
  lessonId: string;
  onClick?: (event: React.MouseEvent) => void;
  href?: string;
  workshop?: boolean;
  finishedCourse?: boolean;
  selected?: boolean;
  internalOriginal?: InternalOriginalProps;

}

export const ContentTileWrapper: FunctionComponent<ContentTileWrapperProps> = ({
                                                                                 lessonId,
                                                                                 onClick,
                                                                                 href,
                                                                                 workshop,
                                                                                 finishedCourse,
                                                                                 selected,
                                                                                 internalOriginal
                                                                               }) => {

  const lesson = useSelector((state: RootState) => selectLesson(state, lessonId));
  const formatConfiguration = useSelector(selectFormatConfigurationFromLesson(lessonId));


  const navigate = useNavigate();

  const onClickRoute = (event: React.MouseEvent, name: string) => {
    sessionStorage.setItem("lesson_id", name);
    if(!event.ctrlKey) {
      internalOriginal && navigate(internalOriginal?.route);
    }
  };

  return (
    <>
      {lessonId !== "" &&
        <ContentTile image={getLessonImage(lesson)}
                     title={lesson?.name || ""}
                     description={lesson?.description || ""}
                     onClick={onClick}
                     ageGroup={{
                       "6–9": (formatConfiguration?.configuration && formatConfiguration?.configuration.age_group_1) || false,
                       "10–12": (formatConfiguration?.configuration && formatConfiguration?.configuration.age_group_2) || false,
                       "13–15": (formatConfiguration?.configuration && formatConfiguration?.configuration.age_group_3) || false,
                       "16+": (formatConfiguration?.configuration && formatConfiguration?.configuration.age_group_4) || false
                     }}
                     knowledge={formatConfiguration?.configuration && formatConfiguration?.configuration.previous_knowledge}
                     href={href}
                     workshop={workshop}
                     author={formatConfiguration?.configuration.offered_by}
                     place={formatConfiguration?.configuration.location}
                     moment={formatConfiguration?.configuration.date.toString()}
                     finishedCourse={finishedCourse}
                     selected={selected}

        />
      }
      {lessonId === "" && internalOriginal &&
        <ContentTile image={internalOriginal.image}
                     title={internalOriginal.name}
                     description={internalOriginal.description}
                     onClick={(event) => onClickRoute(event, "original_lessons")}
                     ageGroup={internalOriginal.age}
                     knowledge={internalOriginal.knowledge}
                     href={internalOriginal.route}
        />
      }
    </>
  );
};
