import React, {FunctionComponent} from 'react';
import styles from './PressEntry.module.scss';
import {IconWithLabel} from '@dis/colors-components';
import {Event, Schedule} from '@mui/icons-material';


export interface PressEntryProps {
    title: string;
    onClick: (event: React.MouseEvent) => void;
    date: string;
    readTime: string;
    description: string;
}


export const PressEntry: FunctionComponent<PressEntryProps> = ({
                                                                   title,
                                                                   onClick,
                                                                   date,
                                                                   readTime,
                                                                   description,
                                                               }) => {

    return (
        <div className={styles.pressEntry} onClick={onClick}>
            <div className={styles.title}>
               <span>
                   {title}
               </span>
            </div>

            <div className={styles.details}>
                { date &&
                  <IconWithLabel label={date}>
                    <Event fontSize={"small"}/>
                  </IconWithLabel>
                }
                { readTime &&
                  <IconWithLabel label={readTime}>
                    <Schedule fontSize={"small"}/>
                  </IconWithLabel>
                }
            </div>

            <div className={styles.description}>
               <span>
                    {description}
               </span>
            </div>
        </div>
    );
};



