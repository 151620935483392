import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './Blog.module.scss';
import {loadJsonFile} from "../../utils/helper";
import {BlogEvent} from "../../building-blocks/BlogEvent/BlogEvent";
import {BlogEntry} from "../../components/BlogEntry/BlogEntry";
import {useIntl} from "react-intl";
import {ROUTES} from "../../utils/routes";
import {useNavigate} from "react-router-dom";
import {useSelector} from 'react-redux';
import {selectLanguage} from '../../redux/languageSlice';
import {BreadcrumbGroup, BreadcrumbTabProps} from '@dis/colors-components';
import {selectMediaQueries} from "../../redux/mediaQueriesSlice";

export interface BlogProps {
    id: string,
    title: string,
    description: string,
    date: string,
    readTime: string,
    image: string,
    imageAlt: string,
}

export interface SendingBlogProps {
    title: string,
    description: string,
}

export interface PageData {
    id: string,
    tag: string,
    title: string,
    description: string,
    date: string,
    readTime: string,
    image: string,
    mobileImage: string,
    imageAlt: string,
    sendingBlog: SendingBlogProps,
    favoriteBlog: Array<BlogProps>,
}

const BLOG_IMG_BREAKPOINT = 560;

const Blog: FunctionComponent = () => {

    const intl = useIntl();
    const navigate = useNavigate();
    const [pageContent, setPageContent] = useState<PageData>();

    const selectedLanguage = useSelector(selectLanguage);
    const {mediaWidth} = useSelector(selectMediaQueries);

    useEffect(() => {
        async function loadData() {
            let data;
            switch (selectedLanguage) {
                case "DEU":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/blog/page-content.json`);
                    break;
                case "ENG":
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/blog/page-content-eng.json`);
                    break;
                default:
                    data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/blog/page-content.json`);
                    break;
            }
            setPageContent(await data.json());
        }

        loadData().catch(console.error);
    }, [selectedLanguage]);


    const handleOnClickBlogView = (id: string): void => {
        navigate(`${ROUTES.DASHBOARD.BLOG.BLOG_VIEW}?blog-id=${id}`);
    }

    const breadcrumbTabs: Array<BreadcrumbTabProps> = [
        {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
        {label: intl.formatMessage({id: 'Header.blog'})}
    ];

    const sortedFavouriteBlogs = pageContent?.favoriteBlog;
    sortedFavouriteBlogs?.sort((a: BlogProps, b: BlogProps) => {
        const dateA: any = new Date(a.date);
        const dateB: any = new Date(b.date);
        return dateB - dateA;
    });

    const localeDate = (date: string) => {
        if (selectedLanguage === "DEU") {
            const month = date.substring(0, 3);
            switch (month) {
                case "Mar":
                    return date.replace(month, "Mär");
                case "May":
                    return date.replace(month, "Mai");
                case "Oct":
                    return date.replace(month, "Okt");
                case "Dec":
                    return date.replace(month, "Dez");
                default:
                    return date;
            }
        } else {
            return date;
        }
    }


    return (
        <div className={styles.Blog}>
            <div className={styles.breadcrumb}>
                <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
            </div>

            {/*Top Blog preview*/}
            {pageContent &&
              <BlogEvent
                tag={pageContent.tag}
                title={pageContent.title}
                description={pageContent.description}
                image={mediaWidth <= BLOG_IMG_BREAKPOINT ? pageContent.mobileImage : pageContent.image}
                date={pageContent.date}
                readTime={pageContent.readTime}
                onButtonClick={() => {
                    handleOnClickBlogView(pageContent.id)
                }}
                buttonLabel={intl.formatMessage({id: "Blog.readMore"})}
              />
            }

            {/*full list of blog entries*/}
            {pageContent && pageContent.favoriteBlog &&
              <div className={styles.favoriteBlogWrapper}>
                  {/* As per request, the box title shall be hidden for Release 1.0 because
                    there are only 3 existing blog entries so far.  */}
                  {/*<div className={styles.heading}>{intl.formatMessage({id: "Blog.favorites"})}</div>*/}
                  {sortedFavouriteBlogs && sortedFavouriteBlogs.length > 0 &&
                    <div className={styles.blogTile}>
                        {sortedFavouriteBlogs.map((value) => {
                            return (
                                <div className={styles.favoriteBlogEntry} key={value.id}>
                                    <BlogEntry
                                        title={value.title}
                                        description={value.description}
                                        date={localeDate(value.date)}
                                        readTime={value.readTime}
                                        image={value.image}
                                        onClick={() => {
                                            handleOnClickBlogView(value.id)
                                        }}
                                    />
                                </div>
                            )
                        })
                        }
                    </div>
                  }
              </div>
            }

            {/*call for blog entries*/}
            {pageContent && pageContent.sendingBlog &&
              <div className={styles.sendingBlog}>
                <h3>{pageContent.sendingBlog.title}</h3>
                <div className={styles.sendingBlogDescription}>
                  <a href={'mailto:mail@youcodegirls.de'}
                     dangerouslySetInnerHTML={{__html: pageContent.sendingBlog.description}}>
                  </a>
                </div>
              </div>
            }
        </div>
    );
}

export default Blog;
