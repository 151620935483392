import { FormSentPage } from "../../../building-blocks/FormSentPage/FormSentPage";
import { FunctionComponent } from "react";

const ContactPageSent: FunctionComponent = () => {
  return FormSentPage({
    thanksHeading: "ContactPageSent.Thanks",
    thanksText: "ContactPageSent.Received"
  });
};

export default ContactPageSent;
