import React, { FunctionComponent, ChangeEvent } from "react";
import { DebounceInput } from "react-debounce-input";
import styles from "./DebouncedTextInput.module.scss";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';


export interface DebouncedTextInputProps {
  minLength?: number;
  debounceTimeout?: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  clear?: () => void;
}

export const DebouncedTextInput: FunctionComponent<DebouncedTextInputProps> = ({
                                                                                 minLength = 1,
                                                                                 debounceTimeout = 100,
                                                                                 value,
                                                                                 onChange,
                                                                                 placeholder,
                                                                                 clear
                                                                               }) => {
  return (
    <div className={styles.debouncedTextInput}>
      <SearchOutlinedIcon className={styles.icon}/>
      <DebounceInput
        minLength={minLength}
        debounceTimeout={debounceTimeout}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        className={styles.input}
      />
      {clear && value !== '' && value !== undefined &&
        <ClearOutlinedIcon className={styles.icon} onClick={clear} titleAccess={"clear search"}/>
      }
    </div>
  )
}

