import React, { FunctionComponent, useState } from "react";
import {RegistrationPageHeader} from "../../building-blocks/RegistrationPageHeader/RegistrationPageHeader";
import styles from "./RegistrationPersonalizationPage.module.scss";
import {useDispatch, useSelector} from "react-redux";
import { Button, ButtonType, NotificationBanner, NotificationBannerType } from "@dis/colors-components";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/routes";
import {saveUserSettings, selectUserSettings} from "../../redux/userSettingsSlice";
import {
    SelectableTilesCategory,
    updatingUserSettings
} from "../../building-blocks/SelectableTilesCategory/SelectableTilesCategory";
import { selectUserInterests, UserInterestsState } from "../../redux/userInterestsSlice";

interface RegistrationPersonalizationPageProps {
    selectThreeInterestsOrTopics: (userInterests: UserInterestsState) => string;
}

const RegistrationPersonalizationPage: FunctionComponent<RegistrationPersonalizationPageProps> = ({selectThreeInterestsOrTopics}) => {

    const userSettings = useSelector(selectUserSettings);
    const dispatch = useDispatch();

    const userInterests = useSelector(selectUserInterests);

    const [selectThreeBanner, setSelectThreeBanner] = useState<boolean>(false);

    const intl = useIntl();
    const navigate = useNavigate();

    const onPersonalizationFinishedClick = async () => {
        const updatedSettings = await updatingUserSettings(userSettings, userInterests);
        if (updatedSettings) {
            dispatch(saveUserSettings(updatedSettings));
            if(userInterests.areaOfInterests.length >= 3 && userInterests.topics.length >= 3) {
                setSelectThreeBanner(false);
                navigate(ROUTES.DASHBOARD.NEWSLETTER);
            } else {
                setSelectThreeBanner(true);
                window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
            }
        }
    };

    return (
        <div className={styles.registrationPersonalizationPage}>
            <RegistrationPageHeader/>
            {selectThreeBanner &&
              <div className={styles.notificationBanner}>
                  <NotificationBanner type={NotificationBannerType.INFO} message={intl.formatMessage({id: selectThreeInterestsOrTopics(userInterests)})} />
              </div>
            }
            <div className={styles.pageContent}>
                <SelectableTilesCategory />
            </div>
              <div className={styles.buttons}>
                  <Button
                    label={intl.formatMessage({id: "RegistrationPersonalizationPage.return"})}
                    type={ButtonType.SECONDARY}
                    onClick={() => navigate(-1)}
                  />
                  <Button
                    label={intl.formatMessage({id: "RegistrationPersonalizationPage.complete"})}
                    onClick={onPersonalizationFinishedClick}
                  />
              </div>
        </div>
    );
};

export default RegistrationPersonalizationPage;
