import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {ShallowEntity} from '../utils/types/api';
import {User} from '@dis/types';
import {fetchLessons} from './fetchActions';


const sliceName = 'tutor';

export const tutorAdapter = createEntityAdapter<ShallowEntity<User>>({
    'sortComparer': (a, b) => (a.username ?? '')?.localeCompare(b.username ?? '')
});

const tutorSlice = createSlice({
    'name': sliceName,
    'initialState': tutorAdapter.getInitialState(),
    'reducers': {},
    'extraReducers': (builder) => {
        builder.addCase(fetchLessons.fulfilled, (state, action) => {
            if (action.payload.entities.User) {
                tutorAdapter.upsertMany(state, action.payload.entities.User);
            }
        })
    }
});

export const tutorReducer = tutorSlice.reducer;
