import React, {FunctionComponent, useEffect, useState} from "react";
import styles from "./DashboardBannerCarousel.module.scss";
import {useSelector} from "react-redux";
import { getLessonImage } from "../../utils/helper";
import {ParticipateBanner} from "../PaticipateBanner/ParticipateBanner";
import {useIntl} from "react-intl";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/routes";
import {selectLessonsByName} from "../../redux/lessonsSlice";
import {LessonWithImage, ShallowEntity} from "../../utils/types/api";
import {InternalOriginalProps, selectInternalOriginalContent} from "../../redux/internalOriginalContentSlice";

/* maps mitmachLessons into a carousel. Uses ParticipateBanner for presentation */
export const DashboardBannerCarousel: FunctionComponent = () => {

  const MITMACH_COURSES = ["Dein Coding-Quiz", "Mach mit beim Coding-ABC!"];

  const intl = useIntl();
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);
  const lessons = useSelector(selectLessonsByName(MITMACH_COURSES));
  const internalOriginalContent = useSelector(selectInternalOriginalContent);
  const mitmachLessons: (ShallowEntity<LessonWithImage> | InternalOriginalProps)[] = ([] as (ShallowEntity<LessonWithImage> | InternalOriginalProps)[]).concat(internalOriginalContent, lessons);

  const lastIndex = mitmachLessons?.length - 1;

  useEffect(() => {

    const interval = setInterval(() => {
      setCurrentIndex(currentIndex === lastIndex ? 0 : currentIndex + 1);
    },5000);

    return () => clearInterval(interval);

  },[currentIndex, lastIndex]);

  const onClick = (route?:string, id?:string) => {
    if(id !== undefined) {
      navigate(`${ROUTES.CONTENT_VIEWER.BASE}?content-id=${id}`);
    }
    if(route !== undefined) {
      navigate(route);
    }
  };

  const image = (lessonImage?: string, lesson?:ShallowEntity<LessonWithImage>) => {
    if(lessonImage !== undefined) {
      return `${process.env.PUBLIC_URL}/${lessonImage}`;
    }
    if(lesson !== undefined) {
      return getLessonImage(lesson);
    }
  }

  return (
    <div className={styles.dashboardBannerCarousel}>
      {mitmachLessons &&
          <div className={styles.carouselContent}>
            {mitmachLessons?.map((pageContent, index) =>
              <div key={pageContent.name}
                     className={index === currentIndex ? styles.activeSlide : styles.inactiveSlide}
                >
                  {index === currentIndex &&
                      <ParticipateBanner
                          buttonLabel={intl.formatMessage({id: "Dashboard.participate"})}
                          onButtonClick={() => onClick("route" in pageContent ? pageContent.route : undefined, "id" in pageContent ? pageContent.id: undefined)}
                          title={pageContent.name}
                          description={pageContent.description}
                          image={image("image" in pageContent ? pageContent.image : undefined, "coverPictureURL" in pageContent ? pageContent : undefined)}
                      />
                  }
                </div>
            )}
          </div>
      }
      <div className={styles.indicators}>
        {mitmachLessons?.map((pageContent, index) =>
            <span
                key={pageContent.name}
                className={classNames(styles.indicator, currentIndex === index ? styles.activeIndicator : styles.inactiveIndicator)}
                onClick={() => setCurrentIndex(index)}
            >
            </span>
        )}
      </div>
    </div>
  );
};
