import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

import {RootState} from "./store";
import {FormatConfigurationWithMetaData, ShallowEntity} from "../utils/types/api";
import {fetchLessons} from "./fetchActions";

const sliceName = 'formatConfigurations';

/**
 * The Entity Adapter managing {@link FormatConfiguration} entities.
 *
 * Entities are sorted by their `name`.
 *
 * Note:  Currently the {@link FormatConfiguration} entities provided by the backend have no valid id, thus we use the
 *        `name` as id.
 */
export const formatConfigurationAdapter = createEntityAdapter<ShallowEntity<FormatConfigurationWithMetaData>>({
  'sortComparer': (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
  'selectId': (formatConfiguration) => formatConfiguration.id ?? ''
});

/**
 * The Redux slice handling actions on {@link FormatConfiguration} entities.
 */
const formatConfigurationSlice = createSlice({
  'name': sliceName,
  'initialState': formatConfigurationAdapter.getInitialState(),
  'reducers': {},
  'extraReducers': (builder) => builder
    .addCase(fetchLessons.fulfilled, (state, action) => {
      formatConfigurationAdapter.upsertMany(state, action.payload.entities.FormatConfiguration);
    })
});

/**
 * To be used by the Redux storage to set up the {@link FormatConfiguration} slice.
 */
export const formatConfigurationReducer = formatConfigurationSlice.reducer;

/**
 * A selector dictionary giving access to
 *
 * - `selectAllFormatConfigurations`: all stored FormatConfiguration entities
 */
export const {
  'selectAll': selectAllFormatConfigurations,
  'selectById': selectFormatConfiguration
} = formatConfigurationAdapter.getSelectors<RootState>((state) => state.formatConfigurations);
